import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router-dom'

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
}

const pageTitle = path => {
  if (path === '/app') return 'Dashboard'
  if (/\/app\/search/.test(path))
    return (
      'Search - ' +
      path
        .split('/')
        .splice(-1)[0]
        .toUpperCase()
    )
  return 'MP Database'
}

class NavBar extends React.Component {
  render() {
    const { classes, location } = this.props
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {pageTitle(location.pathname)}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  location: PropTypes.object,
}

export default withStyles(styles)(withRouter(NavBar))
