import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { forms } from 'constants/styles'

class ContactBlockForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initState = {
      contact_block: {
        contactable_id: this.props.contactable.id,
        contactable_type: this.props.contactable.type,
        start_date: moment().format(),
        end_date: null,
        reason: '',
      },
      valid: false,
    }
    this.state = {
      ...this.initState,
      contact_block: {
        ...this.initState.contact_block,
        ...this.props.contact_block,
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contact_block !== this.props.contact_block) this.reset()
  }

  reset = () =>
    this.setState({
      ...this.initState,
      contact_block: {
        ...this.initState.contact_block,
        ...this.props.contact_block,
      },
    })

  onUpdate = event => {
    let contact_block = {
      ...this.state.contact_block,
    }
    contact_block[event.target.name] = event.target.value
    this.setState({
      contact_block,
      valid: this.validate(contact_block),
    })
  }

  onDateChange = (date, prop) => {
    let contact_block = {
      ...this.state.contact_block,
    }
    contact_block[prop] = date.format()
    this.setState({
      contact_block,
      valid: this.validate(contact_block),
    })
  }

  validate = contact_block => {
    if (!contact_block.contactable_type) return false
    if (!contact_block.contactable_id) return false
    if (!contact_block.start_date) return false
    if (
      !!contact_block.end_date &&
      moment(contact_block.start_date).isSameOrAfter(
        moment(contact_block.end_date)
      )
    )
      return false
    return !!contact_block.reason
  }

  render() {
    const { classes } = this.props
    const { valid, contact_block } = this.state
    const update = !!this.props.contact_block

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Dialog
            open={this.props.open}
            onExit={this.reset}
            onClose={this.props.onClickClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              id="form-dialog-title"
              aria-label="add new contact block form"
            >
              Add a new contact block
            </DialogTitle>
            <DialogContent className={classes.root}>
              <TextField
                autoFocus
                margin="dense"
                id="reason"
                label="Reason for Block"
                type="text"
                value={contact_block.reason}
                onChange={this.onUpdate}
                inputProps={{
                  name: 'reason',
                  id: 'contact_block-reason',
                }}
                fullWidth
                style={{ marginBottom: 30 }}
              />
              <Grid container direction="column">
                <Grid item>
                  <DatePicker
                    keyboard
                    clearable
                    label="Start date"
                    name="start_date"
                    value={contact_block.start_date}
                    onChange={date =>
                      this.onDateChange(date.startOf('day'), 'start_date')
                    }
                    animateYearScrolling={true}
                    style={{ marginBottom: 20 }}
                    minDate={moment().format()}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    clearable
                    keyboard
                    label="End date"
                    name="end_date"
                    value={contact_block.end_date}
                    onChange={date =>
                      this.onDateChange(date.endOf('day'), 'end_date')
                    }
                    animateYearScrolling={true}
                    minDate={contact_block.start_date}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onClickClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={this.props.onSubmit(contact_block, update)}
                color="primary"
                disabled={!valid}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}

ContactBlockForm.propTypes = {
  contactable: PropTypes.object,
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
  contact_block: PropTypes.object,
}

export default withStyles(forms)(ContactBlockForm)
