import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class UploadStepBeforeStarting extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, onNext } = this.props

    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={10}>
          <Typography className={classes.intro}>
            In order to add data to local areas, you need a Comma Separated
            Value (CSV) file containing the data you want for each area.
            <br />
            <br />
            The CSV must contain a header row, naming each of the columns in the
            CSV.
            <br />
            <br />
            Each row in the CSV must contain the unique code for the area, as
            well as one or more statistics or other data about that local area.
            <br />
            <br />
            The unique codes for each area are known as 'ONS Codes' (Office for
            National Statistics) or 'GSS Codes' (Governmet Statistical Service).
            Example CSVs containing these codes for different types of areas can
            be found here: TODO.
          </Typography>
          <Button variant="contained" color="primary" onClick={onNext}>
            Get Started
          </Button>
        </Grid>
      </Grid>
    )
  }
}

UploadStepBeforeStarting.propTypes = {
  classes: PropTypes.object,
  onNext: PropTypes.func.isRequired,
}

export default withStyles(styles)(UploadStepBeforeStarting)
