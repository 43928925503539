const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100% !important',
      margin: '0 !important',
    },
  },
  loading: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
})

export default styles
