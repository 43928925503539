import * as queryString from 'query-string'
import { flatten, flattenDeep, snakeCase } from 'lodash'
import { titleCase } from 'helpers'
import pluralize from 'pluralize'

export const urlParams = (settings, search_group) => {
  const defaultParams =
    settings.group_config[search_group].default_visible_ui_filters
  const urlParams = location.search

  if (!!urlParams) {
    return queryString.parse(urlParams, { arrayFormat: 'index' })
  } else if (!!defaultParams) {
    return queryString.parse(defaultParams, { arrayFormat: 'index' })
  } else {
    return null
  }
}

export const updateUrlParams = filters => {
  const params = {
    filters: filters.map(f => f.name),
    selected: filters.map(f =>
      JSON.stringify(
        ['number', 'percentage', 'id'].includes(f.type)
          ? f.selected
          : f.selected.map(o => o.value)
      )
    ),
    modifiers: filters.map(f => JSON.stringify(!!f.modifiers && f.modifiers)),
  }

  if (history.pushState) {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${queryString.stringify(params, { arrayFormat: 'index' })}`
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export const getAvailableSearchFilters = options => {
  const { settings, search_group, tagCategories, areaDataTypes } = options

  /** STANDARD FILTERS */
  const search_type = settings.group_config[search_group].group_type
  let available = settings.filters.common
    .concat(settings.filters[search_type] || [])
    .concat(settings.filters[search_group] || [])

  /** LIST FILTER */
  const inListFilter = {
    name: 'in_list',
    label: 'In list',
    path: {
      objects_array_path: 'membership.in_list',
      each_value_path: 'id',
    },
    type: 'id',
    search_type: 'Membership',
    extended: 'list',
  }
  available = available.concat([inListFilter])

  /** TAG FILTERS */
  const tagFilters = flatten(
    tagCategories.map(tagCategory =>
      tagCategory.options.validate_taggable_types.map(taggable => ({
        name: `tag-${taggable.toLowerCase()}_${tagCategory.name}`,
        label: `Tag | ${taggable} - ${tagCategory.name}`,
        path: {
          value_path: `${settings.filters.tags[taggable].path}.${tagCategory.name}.value`,
        },
        type: tagCategory.options.validate_tag_values.is_numeric
          ? 'number'
          : 'string',
        tag_name: tagCategory.name,
        taggable_type: taggable,
        extended: 'tag',
      }))
    )
  )
  available = available.concat(tagFilters)

  /** AREA DATA FILTERS */
  const groupAreaClassification =
    settings.group_config[search_group]['area_classification']

  if (groupAreaClassification) {
    const availableAreaData = flattenDeep(
      areaDataTypes
        .filter(type => {
          const areaClassNames = flatten(
            type.area_data_sources.map(s => s.area_classifications)
          ).map(c => c.area_class_name)
          return areaClassNames.includes(groupAreaClassification)
        })
        .map(adt => {
          return adt['area_data_sources'].map(ads => {
            return ads['area_classifications']
              .filter(
                ac => ac['area_class_name'] === groupAreaClassification
              )[0]
              ['area_data_qualifiers'].map(adq => {
                const res = {
                  ...adt,
                  source: ads['source_url'],
                  source_id: ads['id'],
                  qualifier: adq['qualifier_name'],
                  group: {
                    name: adt.name,
                    label: `Area Data | ${adt.description}`,
                  },
                }
                delete res['area_data_sources']
                return res
              })
          })
        })
    )

    const areaDataFilters = flatten(
      availableAreaData.map(ad => {
        return {
          name: `area-data_${ad.name}_${snakeCase(ad.qualifier)}`,
          label: `Area Data | ${ad.qualifier} [source: ${ad.source}]`,
          path: {
            value_path: `${settings.filters['area_data'][search_type].path}.${
              ad.name
            }.${snakeCase(ad.qualifier)}.value`,
          },
          type: ad.data_type,
          search_type: search_type,
          extended: 'area_data',
          area_classification: groupAreaClassification,
          area_data: {
            id: ad.id,
            source_id: ad.source_id,
            qualifier: ad.qualifier,
          },
          area_data_id: ad.id,
          area_data_source_id: ad.source_id,
          area_data_qualifier: ad.qualifier,
          group: ad.group,
        }
      })
    )
    available = available.concat(areaDataFilters)
  }

  return available
}

export const getExtendedDataRequest = (filter, settings) => {
  let request
  switch (filter.extended) {
    case 'tag':
      request = {
        type: filter.extended,
        root: settings.filters.tags[filter.taggable_type].root,
        path: settings.filters.tags[filter.taggable_type].path,
        params: {
          name: filter.tag_name,
          taggable_type: filter.taggable_type,
        },
      }
      break
    case 'area_data':
      request = {
        type: filter.extended,
        root: settings.filters['area_data'][filter.search_type].root,
        path: settings.filters['area_data'][filter.search_type].path,
        params: {
          name: filter.name,
          area_data_id: filter.area_data_id,
          area_data_source_id: filter.area_data_source_id,
        },
      }
      break
    case 'list':
      request = {
        type: filter.extended,
        root: settings.filters['list'][filter.search_type].root,
        path: settings.filters['list'][filter.search_type].path,
        params: {
          name: filter.name,
          searchType: filter.search_type,
        },
      }
  }
  return request
}
