import http from 'helpers/http'

export const getData = async () => {
  const statuses = await http.get(`/relationship_statuses.json`)
  const users = await http.get(`/accounts.json`)
  return {
    statuses: statuses.data,
    users: users.data,
  }
}
