import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { forms } from 'constants/styles'
import { getData } from './index.actions'

const DEFAULT_STATUS = 'default'

class EditRelationshipForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initState = {
      valid: false,
      statuses: null,
    }
    this.state = this.initState
  }

  componentDidMount() {
    this.reset()
    getData().then(data => {
      const { statuses, users } = data
      this.initState.statuses = statuses
      this.setState({ statuses, users })
    })
  }

  reset = () =>
    this.setState({
      ...this.initState,
      person_relationship: this.props.personRelationship || {
        relationship_status_id: null,
      },
    })

  onSelectStatus = event => {
    const person_relationship = {
      ...this.state.person_relationship,
      [event.target.name]: event.target.value,
    }
    this.setState({
      person_relationship,
      valid: this.validate(person_relationship),
    })
  }

  onSelectHolder = event => {
    const id = event.target.value
    const person_relationship = {
      ...this.state.person_relationship,
      user_id: id,
      user_email: !!id
        ? this.state.users.filter(u => u.id === id)[0].email
        : 'no one',
    }
    this.setState({
      person_relationship,
      valid: this.validate(person_relationship),
    })
  }

  validate = person_relationship => {
    return (
      !!person_relationship.relationship_status_id ||
      !!person_relationship.user_id
    )
  }

  render() {
    const { classes } = this.props
    const { person_relationship, statuses, users } = this.state
    const exists = this.props.personRelationship
    if (!statuses || !person_relationship) return null
    if (!person_relationship.user_id) person_relationship.user_id = 0
    const valid = this.validate(person_relationship)

    return (
      <div>
        <Dialog
          open={this.props.open}
          onExit={this.reset}
          onClose={this.props.onClickClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            aria-label="edit relationship form"
          >
            Add / Edit relationship
          </DialogTitle>
          <DialogContent className={classes.root}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="contact-type">Status</InputLabel>
              <Select
                fullWidth
                value={
                  person_relationship.relationship_status_id || DEFAULT_STATUS
                }
                placeholder={'placeholder'}
                onChange={this.onSelectStatus}
                inputProps={{
                  name: 'relationship_status_id',
                }}
              >
                <MenuItem disabled value={DEFAULT_STATUS}>
                  Select a status...
                </MenuItem>
                {statuses.map(status => (
                  <MenuItem value={status.id} key={status.name}>
                    {status.name}
                  </MenuItem>
                ))}
                <MenuItem value={''}>Undefined</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="contact-type">
                Relationship holder
              </InputLabel>
              <Select
                fullWidth
                value={person_relationship.user_id}
                placeholder={'placeholder'}
                onChange={this.onSelectHolder}
                inputProps={{
                  name: 'user_id',
                }}
              >
                <MenuItem disabled value={null}>
                  Select a user...
                </MenuItem>
                {users.map(user => (
                  <MenuItem value={user.id} key={user.id}>
                    {user.email}
                  </MenuItem>
                ))}
                <MenuItem value={null}>No one</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClickClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.props.onSubmit(person_relationship, exists)}
              color="primary"
              disabled={!valid}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

EditRelationshipForm.propTypes = {
  statuses: PropTypes.array,
  personRelationship: PropTypes.object,
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
}

export default withStyles(forms)(EditRelationshipForm)
