export const filterByTextSearch = (memberships, text) => {
  const t = text && text.toLowerCase()
  return memberships.filter(m => {
    const match = [
      m.area && m.area.name.toLowerCase().includes(t),
      m.person && m.person.name.toLowerCase().includes(t),
    ]
    return match.some(el => el)
  })
}
