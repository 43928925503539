import React, { Component } from 'react'
import * as PropTypes from 'prop-types'

class FilterGeo extends Component {
  render() {
    const { title } = this.props
    return <div>{title}</div>
  }
}

FilterGeo.propTypes = {
  title: PropTypes.string,
}

export default FilterGeo
