import ReactOnRails from 'react-on-rails'
import axios from 'axios'

const http = {}

const config = {
  headers: ReactOnRails.authenticityHeaders({
    'Content-Type': 'application/json',
  }),
}

http.get = url => {
  return axios.get(url, config)
}

http.delete = url => {
  return axios.delete(url, config)
}

http.post = (url, body) => {
  return axios.post(url, JSON.stringify(body), config)
}

http.put = (url, body) => {
  return axios.put(url, JSON.stringify(body), config)
}

http.getDataWithRetry = async url => {
  var attempts = 0
  while (attempts < 10) {
    var res = await http.get(url).catch(err => {
      throw err
    })
    if (res.status === 200) {
      return res.data
    } else {
      attempts++
      console.log(
        'Got ' +
          res.status +
          ' - incrementing attempts to ' +
          attempts +
          ' and retrying in 10s'
      )
      await sleep(10000)
    }
  }
  return null
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default http
