import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  container: {
    height: '100%',
  },
  card: {
    height: '100%',
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  actions: {
    backgroundColor: '#f8f9fa',
  },
  inactive: {
    borderLeftColor: grey[300],
  },
  noMargin: {
    marginBottom: 0,
  },
  chip: {
    marginRight: theme.spacing(0.5),
  },
})

export default styles
