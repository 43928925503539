import http from 'helpers/http'

const getList = async id => {
  return await http.get(`/lists/${id}.json`).then(res => res.data)
}

const getMemberships = async () => {
  const data = await http.getDataWithRetry('/search/data/memberships.json')
  return data.items
}

export default { getMemberships, getList }
