import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/es/Button/Button'
import styles from './styles'

class TextSearch extends Component {
  state = { searchText: '' }

  onUpdate = event => {
    const searchText = event.target.value
    this.props.onUpdate(searchText)
    this.setState({ searchText })
  }

  onClear = () => {
    this.setState({ searchText: '' })
    this.props.onUpdate('')
  }

  render() {
    const { searchText } = this.state
    const { classes, mega } = this.props
    const inputProps = mega
      ? {
          classes: { input: classes.inputMega },
          endAdornment: searchText.length > 0 && (
            <Button onClick={this.onClear}>clear</Button>
          ),
        }
      : null
    return (
      <div className={classes.container}>
        <TextField
          label={this.props.label}
          placeholder={this.props.placeholder}
          id="text-search"
          value={this.state.searchText}
          disabled={this.props.disabled}
          onChange={this.onUpdate}
          className={classes[mega ? 'textFieldMega' : 'textField']}
          InputProps={inputProps}
          InputLabelProps={
            mega
              ? {
                  classes: {
                    root: classes.inputLabelMega,
                  },
                }
              : null
          }
          helperText="Search by name or constituency"
        />
      </div>
    )
  }
}

TextSearch.propTypes = {
  classes: PropTypes.object,
  mega: PropTypes.bool,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
}

export default withStyles(styles)(TextSearch)
