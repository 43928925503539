import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

import styles from './styles'
import { isEqual } from 'lodash'

class FilterSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = { buttons: this.initButtons() }
  }

  componentDidUpdate = prevProps => {
    if (!isEqual(prevProps.filter, this.props.filter)) {
      this.setState({ buttons: this.initButtons() })
    }
  }

  initButtons = () => {
    const { selected } = this.props.filter
    let options
    if (this.props.filter.type === 'boolean')
      options = [
        { value: true, id: 0 },
        { value: false, id: 1 },
      ]
    else options = this.props.filter.options
    return options.map(option => ({
      selected: !!selected.length ? option.value === selected[0].value : false,
      value: option.value,
      id: option.id,
    }))
  }

  getClassName = status => {
    const { classes } = this.props
    return classnames(
      classes.button,
      status ? classes.selected : classes.unselected
    )
  }

  onClickButton = clicked => {
    const { buttons } = this.state
    const newButtons = buttons.map(button => {
      button.selected = button.id === clicked.id
      return button
    })
    this.props.onChange({
      remove: false,
      option: buttons.filter(b => b.id === clicked.id)[0],
      filter: this.props.filter,
    })
    this.props.onChange({
      remove: true,
      option: buttons.filter(b => b.id !== clicked.id)[0],
      filter: this.props.filter,
    })
    this.setState({ buttons: newButtons })
  }

  render() {
    const { classes, compact } = this.props
    const { buttons } = this.state
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.container}
        spacing={compact && 1}
      >
        {compact && (
          <Grid item xs={6}>
            <Typography>{this.props.filter.label}</Typography>
          </Grid>
        )}
        {buttons.map(button => (
          <Grid
            item
            xs={compact ? 3 : 6}
            style={{ maxWidth: '48%' }}
            key={button.id}
          >
            <ButtonBase
              onClick={() => this.onClickButton(button)}
              className={classnames(
                this.getClassName(button.selected),
                compact ? classes.compact : null
              )}
              disabled={button.selected}
            >
              <Typography
                variant="button"
                className={
                  classes[button.selected ? 'selectedText' : 'unselectedText']
                }
              >
                {button.value.toString()}
              </Typography>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    )
  }
}

FilterSwitch.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
}

export default withStyles(styles)(FilterSwitch)
