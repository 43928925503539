import React, { Component } from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { deleteList } from './index.actions'
import { base } from 'constants/styles'
import { combineStyles } from 'helpers'
import styles from './styles'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const rows = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'List Name',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Last Updated',
  },
  {
    id: 'user_email',
    numeric: false,
    disablePadding: false,
    label: 'Created By',
  },
  { id: 'entry_count', numeric: true, disablePadding: false, label: 'Count' },
]

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props

    // noinspection HtmlDeprecatedAttribute
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              aria-label="select all lists"
            />
          </TableCell>
          {rows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              padding={row.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
})

class EnhancedTableToolbar extends Component {
  state = {
    searchText: '',
  }

  onUpdateSearchText = event => {
    const searchText = event.target.value
    this.props.onUpdateSearchText(searchText)
    this.setState({ searchText })
  }

  onClear = () => {
    this.props.onUpdateSearchText('')
    this.setState({ searchText: '' })
  }

  render() {
    const { numSelected, classes, onClickDelete } = this.props
    const { searchText } = this.state

    return (
      <div>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="h6" id="tableTitle">
                Lists
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {numSelected > 0 && (
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onClickDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Toolbar>
        <Toolbar className={classNames(classes.searchBar)}>
          <TextField
            label={null}
            placeholder="Search within table data"
            id="text-search"
            value={searchText}
            disabled={null}
            onChange={this.onUpdateSearchText}
            className={classes['textField']}
            InputProps={{
              endAdornment: searchText.length > 0 && (
                <Button onClick={this.onClear}>clear</Button>
              ),
            }}
          />
        </Toolbar>
      </div>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onClickDelete: PropTypes.func,
  onUpdateSearchText: PropTypes.func,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

class EnhancedTable extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'updated_at',
    selected: [],
    page: 0,
    rowsPerPage: this.props.data.length > 25 ? 25 : this.props.data.length,
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return
    }
    this.setState({ selected: [] })
  }

  handleClick = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleDeleteRequest = () => {
    const listIdsToDelete = this.state.selected
    listIdsToDelete.forEach(id => {
      deleteList(id).then(() => console.log(`list with id: ${id} deleted`))
    })
    this.props.onUpdateLists(
      this.props.data.filter(list => !listIdsToDelete.includes(list.id))
    )
    this.setState({ selected: [] })
  }

  handleClickList = id => {
    window.location = `/app/lists/${id}`
  }

  onUpdateSearchText = searchText => {
    this.setState({ searchText })
  }

  filterByTextSearch = (data, searchText) => {
    const text = searchText.toLowerCase()
    return data.filter(entry => {
      const match = [entry.name.toLowerCase().includes(text)]
      return match.some(el => el)
    })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  render() {
    const { classes } = this.props
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      searchText,
    } = this.state
    let data
    if (searchText) data = this.filterByTextSearch(this.props.data, searchText)
    else data = this.props.data
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)

    return (
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onClickDelete={this.handleDeleteRequest}
          onUpdateSearchText={this.onUpdateSearchText}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id)
                  // noinspection HtmlDeprecatedAttribute
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onClick={event => this.handleClick(event, n.id)}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => this.handleClickList(n.id)}
                        className={classNames(
                          classes.clickable,
                          classes.tableCell
                        )}
                      >
                        {n.name}
                      </TableCell>
                      <TableCell align="right">
                        {moment(n.updated_at).fromNow()}
                      </TableCell>
                      <TableCell align="right">{n.user_email}</TableCell>
                      <TableCell align="right">{n.entry_count}</TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    )
  }
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  onUpdateLists: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

export default withStyles(combineStyles(base, styles))(EnhancedTable)
