import React from 'react'
import * as PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  fetchAreaInfo,
  fetchAreaTags,
  fetchAreaData,
  fetchAreaGeo,
  fetchSubareas,
  fetchSubareasGeo,
} from 'state/actions/geo'
import Loading from 'components/Loading'
import { base } from 'constants/styles'
import AreaMap from './AreaMap'
import { formatAreaData, formatGeometry } from './index.helpers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

function mapStateToProps(state) {
  return {
    geo: state.geo,
    settings: state.app.settings,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAreaInfo,
      fetchAreaTags,
      fetchAreaData,
      fetchAreaGeo,
      fetchSubareasGeo,
      fetchSubareas,
    },
    dispatch
  )
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />
}

class AreaDetail extends React.PureComponent {
  componentDidMount() {
    // Maybe refactor to fetch this all in one go?
    this.props.fetchAreaInfo(this.props.match.params.area_id)
    this.props.fetchAreaTags(this.props.match.params.area_id)
    this.props.fetchAreaData(this.props.match.params.area_id)
    this.props.fetchAreaGeo(this.props.match.params.area_id)
    this.props.fetchSubareas(this.props.match.params.area_id)
    this.props.fetchSubareasGeo(this.props.match.params.area_id)
  }

  render() {
    const { geo } = this.props
    let areaName
    let areaClassificationName
    let areaTags
    let areaData
    let areaGeo
    let subareas
    let subareasGeo

    if (
      !geo.areaInfo ||
      !geo.areaTags ||
      !geo.areaData ||
      !geo.areaGeo ||
      !geo.subareas ||
      !geo.subareasGeo
    ) {
      return <Loading />
    } else {
      areaName = geo.areaInfo.name
      areaClassificationName = geo.areaInfo.area_classification_name
      areaTags = geo.areaTags
      areaData = formatAreaData(geo.areaData)
      areaGeo = formatGeometry(geo.areaGeo[0])
      subareasGeo = geo.subareasGeo.map(subareaGeo =>
        formatGeometry(subareaGeo)
      )
      subareas = geo.subareas
      // TODO: what if any of these truly fails - do we still want to render something?
    }

    return (
      <Grid container direction="row" style={{ padding: 30 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={10}
              >
                <Grid item xs={12}>
                  <Typography variant="h3">{areaName}</Typography>
                  <Typography variant="h6">{areaClassificationName}</Typography>
                </Grid>
              </Grid>
              {/* end grid row */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={10}
              >
                <Grid item xs={12}>
                  <Accordion disabled={!geo.areaTags.length ? true : false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">Area Tags</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {areaTags.map(tag => (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={10}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="button"
                              display="block"
                              gutterBottom
                              key={tag.name}
                            >
                              {tag.name}
                            </Typography>
                            <Typography
                              variant="body1"
                              display="block"
                              gutterBottom
                              key={tag.id}
                            >
                              {tag.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion disabled={!geo.areaData.length ? true : false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">Area Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {areaData.map(dataSet => (
                        <div key={dataSet.id}>
                          <Typography variant="h6">
                            {dataSet.description}
                          </Typography>
                          {dataSet.sources.map(dataSource => (
                            <div key={dataSource.id}>
                              <Typography>
                                source: {dataSource.url}, collected:{' '}
                                {dataSource.date}
                              </Typography>
                              {dataSource.data.map(datum => (
                                <div key={datum.qualifier}>
                                  <Typography>
                                    {datum.qualifier}: {datum.value}
                                  </Typography>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion disabled={!subareas.length ? true : false}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">Subareas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={10}
                      >
                        <Grid item xs={6}>
                          <List>
                            {subareas.map(subarea => (
                              <ListItemLink href={subarea.id} key={subarea.id}>
                                <ListItemText primary={subarea.name} />
                              </ListItemLink>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                      {/* end grid row */}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              {/* end grid row */}
            </Grid>
          </Grid>
          {/* end grid column */}
        </Grid>
        <Grid item xs={12} md={6}>
          <AreaMap areaGeo={areaGeo} subareasGeo={subareasGeo} />
        </Grid>
      </Grid>
    )
  }
}

AreaDetail.propTypes = {
  classes: PropTypes.object,
  geo: PropTypes.object,
  settings: PropTypes.object,
  fetchAreaInfo: PropTypes.func,
  fetchAreaTags: PropTypes.func,
  fetchAreaData: PropTypes.func,
  fetchAreaGeo: PropTypes.func,
  fetchSubareas: PropTypes.func,
  fetchSubareasGeo: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(base)(AreaDetail))
