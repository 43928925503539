import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import grey from '@material-ui/core/colors/grey'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'

const styles = {
  width: '100%',
  height: '100%',
  minHeight: 80,
  outline: 'none',
  paddingLeft: 20,
  paddingRight: 20,
  backgroundColor: 'transparent',
  borderStyle: 'dotted',
  borderWidth: 2,
  borderRadius: 15,
  borderColor: grey[500],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#e7eaff',
  },
}

class AddButton extends Component {
  render() {
    return (
      <ButtonBase style={styles} onClick={this.props.onClick}>
        <Typography variant="h6" style={{ color: grey[500] }}>
          {this.props.title}
        </Typography>
      </ButtonBase>
    )
  }
}

AddButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export default AddButton
