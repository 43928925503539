import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { clone, intersectionBy } from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import AddFilterForm from 'components/Filters/AddFilterForm'
import FilterSwitch from 'components/Filters/FilterSwitch'
import FilterNumeric from 'components/Filters/FilterNumeric'
import { getAvailableAreaFilters } from './index.helpers'
import { snackbarNotify } from 'state/actions/app'
import { getExtendedData } from 'state/actions/search'
import {
  getItemValues,
  populateFilterOptions,
  updateFilter,
} from 'components/Filters/index.helpers'
import FilterByList from 'components/Filters/FilterByList'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ snackbarNotify, getExtendedData }, dispatch)
}

class AreaFilters extends Component {
  state = {
    addFilterForm: false,
    filters: [],
    availableFilters: null,
  }

  componentDidMount() {
    const {
      settings,
      tagCategories,
      areaDataTypes,
      areaClassification,
      areas,
    } = this.props
    this.setState({
      availableFilters: getAvailableAreaFilters({
        settings: settings.areas,
        tagCategories,
        areaDataTypes,
        areaClassification,
        areas,
      }),
    })
  }

  onSubmitAddFilter = async filterName => {
    const { filters } = this.state
    const newFilter = await this.buildFilter(filterName)
    if (!newFilter || newFilter.status === 'error') {
      this.setState({
        addFilterForm: false,
      })
      return false
    }
    this.setState({
      filters: filters.concat([newFilter]),
      addFilterForm: false,
    })
  }

  buildFilter = async filterName => {
    let { areas, settings } = this.props
    let newFilter = this.state.availableFilters.filter(
      t => t.name === filterName
    )[0]
    if (!newFilter) return null

    /** If filter is extended (e.g. tags) get extended data */
    if (newFilter.extended) {
      let extended
      let request = {
        type: newFilter.extended,
      }
      switch (newFilter.extended) {
        case 'area_data':
          request = {
            ...request,
            path: settings.areas.area_data.path,
            params: {
              name: newFilter.name,
              area_data_id: newFilter.area_data_id,
              area_data_source_id: newFilter.area_data_source_id,
            },
          }
          break
        case 'tag':
          request = {
            ...request,
            path: settings.areas.tags.path,
            params: {
              name: newFilter.tag_name,
              taggable_type: 'Area',
            },
          }
          break
        case 'list':
          request = {
            ...request,
            path: 'in_list',
            params: {
              name: newFilter.name,
              searchType: newFilter.search_type,
            },
          }
          break
      }
      extended = await this.props.getExtendedData(request, areas)
      if (extended.status !== 'error') areas = extended
      else return extended // If extended data is not available
    }

    newFilter = populateFilterOptions(areas, newFilter, this.props.lists)
    newFilter.modifiers = {
      not: false,
    }

    if (!newFilter.options && !newFilter.range) {
      this.props.snackbarNotify(
        'Sorry - data is not available for this area type'
      )
      return null
    }
    if (newFilter.type === 'string' && newFilter.options.length > 2) {
      newFilter.modifiers = {
        not: false,
        and: false,
      }
    }
    return newFilter
  }

  // TODO: This code seems to be largely duplicated across this class and
  // app/frontend/containers/Search/SearchFilters/SearchFilters.js
  // Pull out code to a common place to reduce duplication
  getFilter = filter => {
    let filterType = filter.type
    if (filter.type === 'tag') filterType = 'string'
    if (filter.options && filter.options.length <= 2 && filterType !== 'geo')
      filterType = 'boolean'
    if (filter.extended === 'list') filterType = 'list'
    switch (filterType) {
      case 'boolean':
        return (
          <FilterSwitch filter={filter} onChange={this.filterUpdated} compact />
        )
      case 'percentage':
      case 'number':
        const itemValues = this.props.areas
          .map(item => getItemValues(item, filter.path))
          .flat()
        return (
          <FilterNumeric
            filter={filter}
            onChange={this.filterUpdated}
            itemValues={itemValues}
            compact
          />
        )
      case 'list':
        return (
          <FilterByList
            lists={this.props.lists}
            filter={filter}
            onChange={this.filterUpdated}
            compact
          />
        )
    }
  }

  filterUpdated = update => {
    const filters = updateFilter(clone(this.state.filters), update)
    this.setState({ filters })
    this.props.onFiltersUpdated(filters)
    // updateUrlParams(filters)
  }

  deleteFilter = filter => () => {
    const { filters: oldFilters } = this.state
    const filters = oldFilters.filter(item => item.name !== filter.name)
    this.setState({ filters })
    this.props.onFiltersUpdated(filters)
  }

  render() {
    const { addFilterForm, filters, availableFilters } = this.state
    if (!availableFilters || (availableFilters && !availableFilters.length))
      return null
    const allFiltersDisplayed =
      intersectionBy(filters, availableFilters, 'name').length ===
      availableFilters.length

    // TODO: Define available tags/datasets for each area classification in config YAML?
    // Or map/reduce tags & area data tables to spit out availability for each area classification

    return (
      <Grid item>
        <AddFilterForm
          open={addFilterForm}
          onSubmit={this.onSubmitAddFilter}
          onClickClose={() => this.setState({ addFilterForm: false })}
          filters={availableFilters}
          selectedFilters={filters}
        />

        <Paper style={{ padding: 20 }}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Typography variant="h5" style={{ paddingBottom: 15 }}>
                Filter by tag/area data
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ addFilterForm: true })}
                disabled={allFiltersDisplayed}
              >
                + add filter
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingBottom: 20 }}
          >
            {filters.map(filter => (
              <Grid item xs={12} key={filter.id}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={11}>
                    {this.getFilter(filter)}
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: 'right' }}>
                    <Fab
                      aria-label="delete"
                      color="secondary"
                      size="small"
                      onClick={this.deleteFilter(filter)}
                    >
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

AreaFilters.propTypes = {
  areas: PropTypes.array,
  settings: PropTypes.object,
  tagCategories: PropTypes.array,
  snackbarNotify: PropTypes.func,
  onFiltersUpdated: PropTypes.func,
  areaDataTypes: PropTypes.array,
  areaClassification: PropTypes.object,
  getExtendedData: PropTypes.func,
  lists: PropTypes.array,
}

export default connect(null, mapDispatchToProps)(AreaFilters)
