import {
  AREAS_CLEARED,
  AREAS_FETCHED,
  AREAS_PUSHED_TO_LIST,
  SEARCH_DATA_LOADED,
  SEARCH_DATA_LOADING,
  SEARCH_DATA_UPDATED,
  TAG_CATEGORIES_LOADED,
} from '../actionTypes'

const appState = {
  data: null,
  loading: false,
  tagCategories: null,
}

export default (state = appState, action) => {
  switch (action.type) {
    case SEARCH_DATA_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SEARCH_DATA_LOADED:
    case SEARCH_DATA_UPDATED:
      return {
        ...state,
        data: action.data,
        loading: false,
      }
    case TAG_CATEGORIES_LOADED:
      return {
        ...state,
        tagCategories: action.tagCategories,
      }
    case AREAS_FETCHED:
      return {
        ...state,
        data: action.areas,
      }
    case AREAS_CLEARED:
    case AREAS_PUSHED_TO_LIST:
      return {
        ...state,
        data: [],
      }
    default:
      return state
  }
}
