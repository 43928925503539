/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// React on Rails
import Rails from 'rails-ujs'
import ReactOnRails from 'react-on-rails'

// Legacy JS
import 'bootstrap/dist/js/bootstrap'
import 'popper.js'
import '../legacy'

// React components
import App from '../App'
import AreaDataUpload from '../containers/AreaDataUpload'
import AreaDataViewer from '../containers/AreaDataViewer'
import People from '../containers/Person'
import Drafts from '../containers/Drafts'
import Home from '../containers/Home'
import List from '../containers/List'
import Lists from '../containers/Lists'
import Search from '../containers/Search'

// Init
Rails.start()
ReactOnRails.register({
  App,
  AreaDataUpload,
  AreaDataViewer,
  People,
  Drafts,
  Home,
  List,
  Lists,
  Search,
})
