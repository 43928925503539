import React from 'react'
import * as PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { forms } from 'constants/styles'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

class RelationshipLogForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initState
    this.initState = {
      log: {
        description: '',
        occurred_at: moment().format(),
      },
      valid: false,
    }
    this.state = {
      ...this.initState,
      log: {
        ...this.initState.log,
        ...this.props.log,
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.log !== prevProps.log) this.reset()
  }

  reset = () =>
    this.setState({
      ...this.initState,
      log: {
        ...this.initState.log,
        ...this.props.log,
      },
    })

  onDateChange = date => {
    const { log } = this.state
    log.occurred_at = date.format()
    this.setState({ log, valid: this.validate(log) })
  }

  onUpdate = event => {
    const { log } = this.state
    log[event.target.name] = event.target.value
    this.setState({
      log,
      valid: this.validate(log),
    })
  }

  validate = log => {
    if (!log.description) return false
    if (!log.occurred_at) return false
    return !!log.occurred_at && moment(log.occurred_at).isSameOrBefore(moment())
  }

  render() {
    const { open, onClickClose, onSubmit, classes, onClickDelete } = this.props
    const { log, valid } = this.state
    const update = !!this.props.log

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <Dialog
            open={open}
            onExit={this.reset}
            onClose={onClickClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {`${!!log ? 'Edit existing' : 'Add a new'} log entry`}
            </DialogTitle>
            <DialogContent className={classes.root}>
              <TextField
                autoFocus
                margin="dense"
                id="entry"
                label="Describe the communication / interaction"
                type="text"
                multiline
                rowsMax="4"
                value={log.description}
                onChange={this.onUpdate}
                inputProps={{
                  name: 'description',
                  id: 'log-description',
                }}
                fullWidth
                style={{ marginBottom: 30 }}
              />
              <Grid container direction="column">
                <Grid item>
                  <DateTimePicker
                    keyboard
                    clearable
                    label="When did it happen"
                    name="occurred_at"
                    value={log.occurred_at}
                    onChange={date => this.onDateChange(date)}
                    animateYearScrolling={true}
                    style={{ marginBottom: 20 }}
                    maxDate={moment().format()}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClickClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => onSubmit(log, update)}
                color="primary"
                disabled={!valid}
              >
                {`${update ? 'Update' : 'Submit'}`}
              </Button>
              {update && (
                <Button
                  onClick={() => onClickDelete(log, null, true)}
                  color="secondary"
                >
                  Delete
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </MuiPickersUtilsProvider>
    )
  }
}

RelationshipLogForm.propTypes = {
  log: PropTypes.object,
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickDelete: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
}

export default withStyles(forms)(RelationshipLogForm)
