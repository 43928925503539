import React from 'react'
import * as PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel'

export default class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  generateHeadings = () => {
    return this.props.columns.map(c => ({
      id: c.name,
      numeric: ['number', 'percentage'].includes(c.type),
      disablePadding: false,
      label: c.label,
    }))
  }

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      passive,
    } = this.props

    const { generateHeadings } = this

    return (
      <TableHead>
        <TableRow>
          {!passive && (
            <TableCell padding="checkbox">
              {rowCount > 0 && (
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={numSelected === rowCount}
                  onChange={onSelectAllClick}
                />
              )}
            </TableCell>
          )}
          {generateHeadings().map(heading => {
            return (
              <TableCell
                key={heading.id}
                padding={heading.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === heading.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={heading.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === heading.id}
                    direction={order}
                    onClick={this.createSortHandler(heading.id)}
                  >
                    {heading.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  passive: PropTypes.bool,
  columns: PropTypes.array,
}
