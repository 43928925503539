import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import FriendlyDiff from './FriendlyDiff'

const diffValue = diffValueObj => {
  // Either print a raw value or an object diff
  if (Object.keys(diffValueObj).includes('object_diff')) {
    return <FriendlyDiff friendlyDiff={diffValueObj.object_diff} />
  } else {
    return <span>{diffValueObj.value || '(Nothing)'}</span>
  }
}

class FriendlyAttributeDiff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attributeName: this.props.attributeName,
      attributeOld: this.props.attributeOld,
      attributeNew: this.props.attributeNew,
      changeType: this.props.changeType,
    }
  }

  render() {
    const { classes } = this.props
    const { attributeName, attributeOld, attributeNew, changeType } = this.state

    if (changeType === 'New') {
      // No old values for a new record
      return (
        <Grid container direction="row" alignItems="center" spacing={8}>
          <Grid item xs={2} className={classes.attributeName}>
            <span>{attributeName}:</span>
          </Grid>
          <Grid item xs={10} className={classes.attributeNew}>
            {diffValue(attributeNew)}
          </Grid>
        </Grid>
      )
    } else if (changeType === 'Deleted') {
      // No new values for a deleted record
      return (
        <Grid container direction="row" alignItems="center" spacing={8}>
          <Grid item xs={2} className={classes.attributeName}>
            <span>{attributeName}:</span>
          </Grid>
          <Grid item xs={10} className={classes.attributeNew}>
            {diffValue(attributeOld)}
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container direction="row" alignItems="center" spacing={8}>
          <Grid item xs={2} className={classes.attributeName}>
            <span>{attributeName}:</span>
          </Grid>
          <Grid item xs={5} className={classes.attributeOld}>
            {diffValue(attributeOld)}
          </Grid>
          <Grid item xs={5} className={classes.attributeNew}>
            {diffValue(attributeNew)}
          </Grid>
        </Grid>
      )
    }
  }
}

FriendlyAttributeDiff.propTypes = {
  attributeName: PropTypes.string,
  attributeOld: PropTypes.object,
  attributeNew: PropTypes.object,
  changeType: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(styles)(FriendlyAttributeDiff)
