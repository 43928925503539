import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { base } from 'constants/styles'
import styles from './styles'
import { combineStyles } from 'helpers'

const ColumnSelect = props => {
  const {
    open,
    onClose,
    classes,
    columns,
    loading,
    toggleColumn,
    onSelectAllColumns,
  } = props

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose(false)}>
      <div className={classes.columnsSelect} tabIndex={0}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography variant="h5" color="inherit" gutterBottom>
              Select table columns
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.columnsSelectClose}
              aria-label="Close"
              onClick={onClose(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <FormGroup row>
          <Grid container direction="row">
            {columns.available.map(column => (
              <Grid key={column.name} item xs={6} sm={4} md={3} lg={2}>
                <FormControlLabel
                  key={column.name}
                  control={
                    loading === column.name ? (
                      <CircularProgress
                        size={48}
                        classes={{ root: classes.circularProgress }}
                      />
                    ) : (
                      <Checkbox
                        checked={
                          columns.selected.filter(
                            col => column.name === col.name
                          ).length > 0
                        }
                        onChange={toggleColumn(column)}
                        value={column.name}
                        color="primary"
                      />
                    )
                  }
                  label={column.label}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        <Grid container>
          <Grid item>
            <Button variant="contained" onClick={onSelectAllColumns}>
              Select all
            </Button>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  )
}

ColumnSelect.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  columns: PropTypes.object,
  classes: PropTypes.object,
  loading: PropTypes.string,
  toggleColumn: PropTypes.func,
  onSelectAllColumns: PropTypes.func,
}

export default withStyles(combineStyles(base, styles))(ColumnSelect)
