import React from 'react'
import * as PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'

class FilterContainer extends React.PureComponent {
  state = {
    show: false,
  }

  componentDidMount() {
    this.setState({ show: true })
  }

  render() {
    const { filter, index, children } = this.props
    const title = filter.label
    const subtitle = filter.subtitle
      ? `${
          filter.subtitle[
            filter.modifiers && filter.modifiers.not ? 'not' : 'default'
          ]
        } ${
          filter.modifiers && filter.modifiers.and
            ? filter.modifiers.not
              ? 'one of'
              : 'all of'
            : 'any of'
        }`
      : null

    return (
      <Collapse in={this.state.show} mountOnEnter unmountOnExit>
        <Card>
          <CardContent style={{ paddingBottom: 0 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              style={{ fontSize: Math.floor(title.length > 20 ? 16 : 22) }}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography gutterBottom variant="subtitle2" color="primary">
                {subtitle}
              </Typography>
            )}
            {children}
          </CardContent>
          <CardActions style={{ justifyContent: 'space-between' }}>
            <Button
              size="small"
              color="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => this.props.onClickMoveUp(filter)}
              disabled={index === 0}
            >
              &laquo; Move Up
            </Button>
            <Button
              size="small"
              color="secondary"
              style={{ fontWeight: 'bold' }}
              onClick={() => this.props.onClickDelete(filter)}
            >
              Remove
            </Button>
          </CardActions>
        </Card>
      </Collapse>
    )
  }
}

FilterContainer.propTypes = {
  items: PropTypes.array,
  onClickMoveUp: PropTypes.func,
  onClickDelete: PropTypes.func,
  filter: PropTypes.object,
  children: PropTypes.node,
  index: PropTypes.number,
}

export default FilterContainer
