import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { forms } from 'constants/styles'
import { CONTACT_FORM_LABELS } from 'constants/index'

class AddContactForm extends React.PureComponent {
  constructor(props) {
    super(props)
    let contact_purpose_types = {}
    this.props.contactPurposeTypes.forEach(cpt => {
      contact_purpose_types[cpt.name] = false
    })
    this.state = {
      contact: {
        contactable_id: this.props.contactable.id,
        contactable_type: this.props.contactable.type,
        contact_type_id: '',
        label: '',
        value: '',
        note: '',
        contact_purpose_types,
      },
      contactType: {},
      reveal: false,
      valid: false,
      editOnly: false,
    }
  }

  componentDidMount() {
    if (this.props.contact) {
      const { contact, contactTypes, contactPurposeTypes } = this.props
      const { contact_type } = contact
      const newState = {
        reveal: true,
        valid: true,
        editOnly: true,
        contact: {
          ...this.state.contact,
          ...this.props.contact,
          contact_type_id: contactTypes.filter(t => t.name === contact_type)[0]
            .id,
        },
      }
      Object.keys(newState.contact.contact_purpose_types).forEach(key => {
        newState.contact.contact_purpose_types[key] = false
      })
      newState.contact.contact_purposes.forEach(cp => {
        const name = contactPurposeTypes.filter(
          t => t.id === cp.contact_purpose_type_id
        )[0].name
        newState.contact.contact_purpose_types[name] = true
      })
      this.setState(newState)
    }
  }

  onSelectType = event => {
    this.setState({
      contact: {
        ...this.state.contact,
        [event.target.name]: event.target.value,
      },
      contactType: this.props.contactTypes.filter(
        t => t.id === event.target.value
      )[0],
      reveal: true,
    })
  }

  onUpdate = event => {
    let contact = {
      ...this.state.contact,
    }
    if (event.target.id.includes('purpose')) {
      contact.contact_purpose_types[event.target.name] = event.target.checked
    } else {
      contact[event.target.name] = event.target.value
    }
    this.setState({
      contact,
      valid: this.validate(contact),
    })
  }

  validate = contact => {
    if (!contact.contactable_type) return false
    if (!contact.contact_type_id) return false
    return !!contact.value
  }

  render() {
    const { classes, contactable, contactTypes } = this.props
    const { contact, contactType, reveal, valid, editOnly } = this.state

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClickClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {editOnly
              ? 'Edit Contact Purposes'
              : `Add a new ${contactable.type} contact`}
          </DialogTitle>
          <DialogContent className={classes.root}>
            {/*<DialogContentText></DialogContentText>*/}
            {!editOnly && (
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="contact-type">Contact Type</InputLabel>
                <Select
                  fullWidth
                  value={contact.contact_type_id}
                  onChange={this.onSelectType}
                  inputProps={{
                    name: 'contact_type_id',
                    id: 'contact-type',
                  }}
                >
                  {contactTypes.map(type => (
                    <MenuItem value={type.id} key={type.name}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {reveal && (
              <div>
                {!editOnly && (
                  <div>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        autoFocus
                        value={contact.label}
                        label="Name"
                        type="text"
                        onChange={this.onUpdate}
                        inputProps={{
                          name: 'label',
                          id: 'contact-name',
                        }}
                        fullWidth
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        value={contact.value}
                        label={CONTACT_FORM_LABELS[contactType.name]}
                        type="text"
                        onChange={this.onUpdate}
                        inputProps={{
                          name: 'value',
                          id: 'contact-value',
                        }}
                        fullWidth
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                      <TextField
                        value={contact.note}
                        label="Note"
                        type="text"
                        onChange={this.onUpdate}
                        inputProps={{
                          name: 'note',
                          id: 'contact-note',
                        }}
                        fullWidth
                      />
                    </FormControl>
                  </div>
                )}
                <div className={classes.divider} />
                <FormControl fullWidth className={classes.formControl}>
                  <FormLabel component="legend">Contact Purposes</FormLabel>
                  <FormGroup>
                    {Object.entries(contact.contact_purpose_types).map(cpt => (
                      <FormControlLabel
                        key={cpt[0]}
                        control={
                          <Checkbox
                            checked={contact.contact_purpose_types[cpt[0]]}
                            value={cpt[0]}
                            inputProps={{
                              name: cpt[0],
                              id: 'purposes-' + cpt[0],
                            }}
                            onChange={this.onUpdate}
                          />
                        }
                        label={cpt[0]}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClickClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.props.onSubmit(contact)}
              color="primary"
              disabled={!valid}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

AddContactForm.propTypes = {
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  contactable: PropTypes.object,
  contactTypes: PropTypes.array,
  classes: PropTypes.object,
  contactPurposeTypes: PropTypes.array,
  contact: PropTypes.object,
}

export default withStyles(forms)(AddContactForm)
