import { PERSON_FETCHED, PERSON_UPDATED, LISTS_FETCHED } from '../actionTypes'

const cacheState = {
  person: null,
  areas: [],
}

export default (state = cacheState, action) => {
  switch (action.type) {
    case PERSON_FETCHED:
    case PERSON_UPDATED:
      return {
        ...state,
        person: action.person,
      }
    case LISTS_FETCHED:
      return {
        ...state,
        lists: action.lists || [],
      }
    default:
      return state
  }
}
