import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import { forms } from 'constants/styles'

class TagForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.initState
    this.initState = {
      tag: {
        id: null,
        tag_category_id: null,
        taggable_type: this.props.taggableType,
        taggable_id: this.props.person.id,
        value: null,
      },
      valid: false,
    }
    this.state = {
      ...this.initState,
      tag: {
        ...this.initState.tag,
        ...this.props.tag,
      },
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        ...this.initState,
        tag: {
          ...this.initState.tag,
          ...this.props.tag,
        },
      })
    }
  }

  onUpdate = event => {
    const { tag } = this.state
    tag[event.target.name] = event.target.value
    this.setState({
      tag,
    })
  }

  validate = tag => {
    if (this.props.tag && tag.value === this.props.tag.value) return false
    if (!tag.value) return false
    return !!tag.tag_category_id
  }

  getTagValueControl = (tag, classes) => {
    const { tagCategories } = this.props
    const category = tagCategories.filter(c => tag.tag_category_id === c.id)[0]
    if (!category) return null
    let type
    if (!!category.options.validate_tag_values.in_list) type = 'list'
    if (!!category.options.validate_tag_values.is_numeric) type = 'numeric'

    switch (type) {
      case 'list':
        const tagValues = category.options.validate_tag_values.in_list
        return (
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="tag-value-list">Tag value</InputLabel>
            <Select
              fullWidth
              value={tag.value || ''}
              placeholder={'select a tag value'}
              onChange={this.onUpdate}
              inputProps={{
                name: 'value',
              }}
            >
              {tagValues.map((tagValue, idx) => (
                <MenuItem value={tagValue} key={idx}>
                  {tagValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      case 'numeric':
        return (
          <TextField
            fullWidth
            label="Tag value"
            value={tag.value || 0}
            onChange={this.onUpdate}
            type="number"
            className={classes.formControl}
            inputProps={{
              name: 'value',
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )
    }
  }

  render() {
    const {
      onClickClose,
      onSubmit,
      onClickDelete,
      classes,
      tagCategories,
      taggableType,
      person,
    } = this.props
    if (!tagCategories) return null
    const { tag } = this.state
    const update = !!this.props.tag
    const valid = this.validate(tag)
    const personTags = person.tags ? Object.keys(person.tags) : []

    return (
      <Dialog
        open={this.props.open}
        onExit={this.reset}
        onClose={this.props.onClickClose}
        aria-labelledby="tagform-dialog-title"
      >
        <DialogTitle id="tagform-dialog-title" aria-label="edit tag form">
          {`${update ? 'Edit' : 'Add'}`} Tag
        </DialogTitle>
        <DialogContent className={classes.root}>
          {!update && (
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink>Tag category</InputLabel>
              <Select
                fullWidth
                value={tag.tag_category_id || ''}
                placeholder={'tag category'}
                onChange={this.onUpdate}
                inputProps={{
                  name: 'tag_category_id',
                }}
              >
                {tagCategories
                  .filter(cat =>
                    cat.options.validate_taggable_types.includes(taggableType)
                  )
                  .map(cat => (
                    <MenuItem
                      value={cat.id}
                      key={cat.id}
                      disabled={personTags.includes(cat.name)}
                    >
                      {cat.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {this.getTagValueControl(tag, classes)}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(tag, update)}
            color="primary"
            disabled={!valid}
          >
            {`${update ? 'Update' : 'Submit'}`}
          </Button>
          {update && (
            <Button
              onClick={() => onClickDelete(tag, true, true)}
              color="secondary"
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

TagForm.propTypes = {
  tag: PropTypes.object,
  taggableType: PropTypes.string,
  tagCategories: PropTypes.array,
  person: PropTypes.object,
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickDelete: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
}

export default withStyles(forms)(TagForm)
