import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import PersonRelationship from './PersonRelationship'
import PersonContactBlocks from './PersonContactBlocks'
import PersonTags from './PersonTags'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import EditRelationshipForm from '../EditRelationshipForm'
import MembershipCard from './MembershipCard'
import ContactBlockForm from '../ContactBlockForm'
import { base } from 'constants/styles'
import RelationshipLogForm from '../RelationshipLogForm'
import SaveButtonInput from 'components/SaveButtonInput/SaveButtonInput'
import TagForm from '../TagForm'
import {
  updatePerson,
  editPersonRelationship,
  addUpdateContactBlock,
  deleteContactBlock,
  addUpdateRelationshipLog,
  deleteRelationshipLog,
  addUpdateTag,
  deleteTag,
} from 'state/actions/person'
import { loadTagCategories } from 'state/actions/search'
import { snackbarNotify } from 'state/actions/app'
import { TAGGABLE_TYPES } from 'constants/index'

function mapStateToProps(state) {
  return {
    tagCategories: state.search.tagCategories,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePerson,
      editPersonRelationship,
      addUpdateContactBlock,
      deleteContactBlock,
      addUpdateRelationshipLog,
      deleteRelationshipLog,
      snackbarNotify,
      addUpdateTag,
      deleteTag,
      loadTagCategories,
    },
    dispatch
  )
}

class PersonInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editContactBlock: null,
      showExpiredBlocks: false,
      forms: {
        addUpdateContactBlock: false,
        editRelationship: false,
        relationshipLog: false,
        addUpdateDeleteTag: false,
      },
    }
  }

  componentDidMount() {
    this.props.loadTagCategories()
  }

  formToggle = (form, action, formData) => e => {
    e.preventDefault()
    const newState = {
      forms: {
        ...this.state.forms,
        [form]: action,
      },
      formData,
    }
    if (form === 'addUpdateContactBlock' && !action)
      newState.editContactBlock = null
    this.setState(newState)
  }

  submitContactBlockForm = (contact_block, update) => e => {
    e.preventDefault()
    this.props
      .addUpdateContactBlock(this.props.person, contact_block, update)
      .then(() => {
        this.setState({
          editContactBlock: null,
          forms: { ...this.state.forms, addUpdateContactBlock: false },
        })
      })
  }

  editContactBlock = ocb => {
    this.setState({
      editContactBlock: ocb,
      forms: {
        ...this.state.forms,
        addUpdateContactBlock: true,
      },
    })
  }

  deleteContactBlock = contact_block => e => {
    e.preventDefault()
    this.props
      .deleteContactBlock(this.props.person, contact_block)
      .then(() => this.forceUpdate())
  }

  submitRelationshipLogForm = (log, update, deleteLog) => {
    const promise = deleteLog
      ? this.props.deleteRelationshipLog(this.props.person, log)
      : this.props.addUpdateRelationshipLog(this.props.person, log, update)
    promise.then(() => {
      const newState = {
        forms: {
          ...this.state.forms,
          relationshipLog: false,
        },
      }
      this.setState(newState)
    })
  }

  submitEditRelationshipForm = (person_relationship, exists) => e => {
    e.preventDefault()
    if (!person_relationship.user_id) person_relationship.user_id = null
    this.props
      .editPersonRelationship(this.props.person, person_relationship, exists)
      .then(() => {
        this.setState({
          forms: {
            ...this.state.forms,
            editRelationship: false,
          },
        })
      })
  }

  onSaveName = name => {
    this.props.updatePerson(this.props.person, { name })
    this.props.snackbarNotify('Success - person name updated')
  }

  submitAddUpdateDeleteTagForm = (tag, exists, deleteTag) => {
    const promise = deleteTag
      ? this.props.deleteTag(this.props.person, tag)
      : this.props.addUpdateTag(this.props.person, tag, exists)
    promise.then(() => {
      const newState = {
        forms: {
          ...this.state.forms,
          addUpdateDeleteTag: false,
        },
      }
      this.setState(newState)
    })
  }

  render() {
    const { classes, person, tagCategories } = this.props
    const { forms, editContactBlock, formData } = this.state

    return (
      <Grid container spacing={3}>
        <ContactBlockForm
          open={forms.addUpdateContactBlock}
          onClickClose={this.formToggle('addUpdateContactBlock', false)}
          onSubmit={this.submitContactBlockForm}
          contactable={{ id: person.id, type: 'person' }}
          contact_block={editContactBlock}
        />
        <EditRelationshipForm
          open={forms.editRelationship}
          personRelationship={person.person_relationship}
          onSubmit={this.submitEditRelationshipForm}
          onClickClose={this.formToggle('editRelationship', false)}
        />
        <RelationshipLogForm
          open={forms.relationshipLog}
          onClickClose={this.formToggle('relationshipLog', false)}
          onSubmit={this.submitRelationshipLogForm}
          log={formData}
          onClickDelete={this.submitRelationshipLogForm}
        />
        <TagForm
          open={forms.addUpdateDeleteTag}
          person={person}
          onClickClose={this.formToggle('addUpdateDeleteTag', false)}
          onClickDelete={this.submitAddUpdateDeleteTagForm}
          onSubmit={this.submitAddUpdateDeleteTagForm}
          tag={formData}
          taggableType={TAGGABLE_TYPES.PERSON}
          tagCategories={tagCategories}
        />
        <Grid item>
          <Typography variant="h5" gutterBottom>
            Personal Info
          </Typography>
          <form className={classes.formContainer} noValidate>
            <Grid container direction="row">
              <SaveButtonInput
                label="Name"
                initial={person.name}
                onSave={this.onSaveName}
                style={{ width: 350 }}
              />
              <TextField
                disabled
                id="person-gender"
                label="Gender"
                className={classes.textField}
                value={person.gender || ''}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <PersonRelationship person={person} formToggle={this.formToggle} />
        </Grid>
        {person.memberships && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Current Roles
            </Typography>
            <Grid container spacing={3}>
              {person.memberships.map(m => (
                <Grid item key={m.identifier}>
                  <MembershipCard membership={m} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <PersonTags tags={person.tags} formToggle={this.formToggle} />
        </Grid>
        <Grid item xs={12}>
          <PersonContactBlocks
            person={person}
            formToggle={this.formToggle}
            deleteContactBlock={this.deleteContactBlock}
            editContactBlock={this.editContactBlock}
          />
        </Grid>
      </Grid>
    )
  }
}

PersonInfo.propTypes = {
  person: PropTypes.object,
  classes: PropTypes.object,
  updatePerson: PropTypes.func,
  editPersonRelationship: PropTypes.func,
  snackbarNotify: PropTypes.func,
  addUpdateContactBlock: PropTypes.func,
  deleteContactBlock: PropTypes.func,
  addUpdateRelationshipLog: PropTypes.func,
  deleteRelationshipLog: PropTypes.func,
  addUpdateTag: PropTypes.func,
  deleteTag: PropTypes.func,
  loadTagCategories: PropTypes.func,
  tagCategories: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(base)(PersonInfo))
