import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loadSearchData, loadTagCategories } from 'state/actions/search'
import { fetchAreasByList } from 'state/actions/geo'

import styles from './styles'
import { combineStyles } from 'helpers'
import { base } from 'constants/styles'
import actions from './index.actions'
import DataTable from 'components/DataTable'
import Loading from 'components/Loading'

function mapStateToProps(state) {
  return {
    settings: state.app.settings,
    tagCategories: state.search.tagCategories,
    searchData: state.search.data,
    searchLoading: state.search.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSearchData, loadTagCategories, fetchAreasByList },
    dispatch
  )
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const {
      match: { params },
      settings,
    } = this.props
    actions.getList(params.list_id).then(list => {
      this.setState({ list, loading: false })

      /** Load basic data into store */
      this.props.loadTagCategories()

      // TODO: Make this more generic, with general fetchObjectsByList endpoint?
      if (['Person', 'Membership', 'ElectionCandidate'].includes(list.type)) {
        this.props.loadSearchData(
          list.group,
          settings.search.group_config[list.group]
        )
      } else if (['Area'].includes(list.type)) {
        this.props.fetchAreasByList(list.id)
      }
    })
  }

  render() {
    const { list, loading } = this.state
    const {
      classes,
      settings,
      tagCategories,
      searchData,
      searchLoading,
    } = this.props

    if (!list || loading || searchLoading || !tagCategories) {
      return <Loading visible />
    }

    console.log(searchData)
    console.log(list.type)
    console.log(this.state.list.list_entry_ids)

    let listType, listEntries
    // TODO: Make this more generic somehow...
    // If we do fetchObjectByList above, do we need to do any in-memory filtering here...?
    switch (list.type) {
      case 'Person':
        //listType = 'Person'
        listEntries = searchData.filter(p =>
          this.state.list.list_entry_ids.includes(p.person.id)
        )
        break
      case 'Membership':
        //listType = 'Person'
        listEntries = searchData.filter(m =>
          this.state.list.list_entry_ids.includes(m.membership.id)
        )
        break
      case 'ElectionCandidate':
        //listType = 'Person'
        listEntries = searchData.filter(ec =>
          this.state.list.list_entry_ids.includes(ec.election_candidate.id)
        )
        break
      case 'Area':
        //listType = 'Area'
        listEntries = searchData
        break
    }

    return (
      <Grid container spacing={2} className={classes.root} direction={'column'}>
        <Grid item>
          <Typography variant={'h4'}>List - {list.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'subtitle1'} gutterBottom>
            Created by {list.user_email}
          </Typography>
        </Grid>
        <DataTable
          data={listEntries}
          dataType={settings.search.group_types[list.type].base_data_type}
          groupType={list.type}
          itemIdPath={settings.search.group_types[list.type].item_id_path}
          controls={['export', 'push', 'merge']}
          settings={settings}
          //search_group={list.group || settings.search.default_group}
          search_group={list.group}
          tagCategories={tagCategories}
        />
      </Grid>
    )
  }
}

List.propTypes = {
  list: PropTypes.string,
  classes: PropTypes.object,
  settings: PropTypes.object,
  tagCategories: PropTypes.array,
  searchData: PropTypes.array,
  searchLoading: PropTypes.bool,
  match: PropTypes.object,
  loadTagCategories: PropTypes.func,
  loadSearchData: PropTypes.func,
  fetchAreasByList: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(base, styles))(List))
