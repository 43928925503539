import { combineReducers } from 'redux'
import app from './app'
import search from './search'
import cache from './cache'
import geo from './geo'

// Combine all the reducers
const rootReducer = combineReducers({
  app,
  search,
  cache,
  geo,
})

export default rootReducer
