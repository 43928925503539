import { get, intersection, isEmpty, keys, uniq } from 'lodash'

export function getItemValues(item, path) {
  if (path.objects_array_path && path.each_value_path) {
    const objectArray = get(item, path.objects_array_path)
    if (!objectArray) {
      return []
    } else {
      return objectArray.map(o => get(o, path.each_value_path)).filter(v => !!v)
    }
  } else if (path.object_keys_path) {
    const object = get(item, path.object_keys_path)
    if (!object) {
      return []
    } else {
      return keys(object)
    }
  } else if (path.tags_path) {
    return get(item, path.tags_path)[path.tag_name].value
  } else {
    return [get(item, path.value_path)]
  }
}

export const getFilterOptions = (items, filter) => {
  const uniques = uniq(
    items.map(item => getItemValues(item, filter.path)).flat()
  ).filter(u => !!u)
  if (!uniques.length) return null
  return uniques.map((unique, idx) => ({
    value: unique,
    id: idx,
    count: 0,
  }))
}

export const getFilterRange = (items, filter) => {
  const values = items
    .map(item => getItemValues(item, filter.path))
    .flat()
    .filter(i => i)
  if (!values.length) return null
  const max = Math.max(...values)
  const min = Math.min(...values)
  return [min, max]
}

export const filterItems = (filters, items) => {
  const activeFilters = filters.filter(
    f => (f.selected && f.selected.length) || f.range
  )
  return items.filter(item => {
    return activeFilters.every(filter => {
      const itemValues = getItemValues(item, filter.path)
      let filterMatched
      switch (filter.type) {
        case 'id':
          filterMatched = !isEmpty(intersection(itemValues, filter.selected))
          break
        case 'percentage':
        case 'number':
          filterMatched = itemValues.some(
            val => val >= filter.selected[0] && val <= filter.selected[1]
          )
          break
        case 'string':
          if (filter.modifiers && filter.modifiers.and) {
            filterMatched = filter.selected.every(selected =>
              itemValues.includes(selected.value)
            )
          } else {
            const filterValues = filter.selected.map(o => o.value)
            filterMatched = !isEmpty(intersection(itemValues, filterValues))
          }
          break
        case 'boolean':
          const filterValues = filter.selected.map(o => o.value)
          filterMatched = !isEmpty(intersection(itemValues, filterValues))
      }
      return filter.modifiers && filter.modifiers.not
        ? !filterMatched
        : filterMatched
    })
  })
}

export const populateFilterOptions = (data, filter, lists) => {
  filter.id = Math.ceil(Math.random() * 10000000)
  if (filter.extended === 'list') {
    filter.selected = []
    filter.options = lists
      .filter(list => list.listable_type === filter.search_type)
      .map(list => list.id)
  } else {
    if (['number', 'percentage'].includes(filter.type)) {
      filter.range = getFilterRange(data, filter)
      filter.selected = filter.range
    } else {
      filter.options = getFilterOptions(data, filter)
      filter.selected = []
    }
  }
  return filter
}

export const updateFilter = (filters, update) => {
  const thisFilterIdx = filters.findIndex(f => f.name === update.filter.name)
  if (update.modifier) {
    if (!filters[thisFilterIdx].modifiers) filters[thisFilterIdx].modifiers = {}
    filters[thisFilterIdx].modifiers[update.modifier.name] =
      update.modifier.value
  } else if (update.remove) {
    let newOptions = filters[thisFilterIdx].selected
    filters[thisFilterIdx].selected = newOptions.filter(
      no => no.value !== update.option.value
    )
  } else {
    if (['number', 'percentage', 'id'].includes(update.filter.type)) {
      filters[thisFilterIdx].selected = update.filter.selected
    } else {
      filters[thisFilterIdx].selected.push(update.option)
    }
  }
  return filters
}
