import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import Loading from 'components/Loading'
import { getData } from './index.actions'
import ListsTable from './ListsTable'

class Lists extends Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    getData().then(lists => {
      this.setState({ lists, loading: false })
    })
  }

  updateLists = lists => {
    this.setState({ lists })
  }

  render() {
    const { classes } = this.props
    const { lists, loading } = this.state
    if (loading) return <Loading visible />
    return (
      <div className={classes.root}>
        <ListsTable
          data={lists}
          onUpdateLists={newLists => this.updateLists(newLists)}
        />
      </div>
    )
  }
}

Lists.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Lists)
