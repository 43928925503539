import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    textAlign: 'right',
    marginTop: -32,
    paddingBottom: 6,
  },
})

const Reset = props => {
  const classes = useStyles()

  return (
    <Grid item className={classes.root}>
      <Button
        style={{ padding: '0px 6px' }}
        color="primary"
        onClick={props.onListReset}
      >
        Reset
        <ClearAllIcon />
      </Button>
    </Grid>
  )
}

Reset.propTypes = {
  onListReset: PropTypes.func,
}

export default Reset
