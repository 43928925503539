import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ShowIcon from '@material-ui/icons/ArrowDropDown'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import moment from 'moment'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import InProgressIcon from '@material-ui/icons/CheckCircle'
import UpcomingIcon from '@material-ui/icons/History'
import ExpiredIcon from '@material-ui/icons/HighlightOff'
import DeleteIcon from '@material-ui/icons/Delete'

const getBlockIcon = contact_block => {
  const now = moment()
  const { start_date: start, end_date: end } = contact_block
  if (moment(now).isBefore(start)) return <UpcomingIcon color="secondary" />
  else if (moment(now).isAfter(end)) return <ExpiredIcon />
  else return <InProgressIcon color="primary" />
}

class PersonContactBlocks extends Component {
  state = {
    showExpiredBlocks: false,
  }

  render() {
    const {
      person,
      editContactBlock,
      deleteContactBlock,
      formToggle,
    } = this.props
    const { showExpiredBlocks } = this.state

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Contact Blocks
          {
            <Tooltip
              title="Show/Hide expired contact blocks"
              aria-label="Edit"
              placement="right"
            >
              <IconButton
                aria-label="Show expired blocks"
                onClick={() =>
                  this.setState({ showExpiredBlocks: !showExpiredBlocks })
                }
              >
                <ShowIcon />
              </IconButton>
            </Tooltip>
          }
        </Typography>
        <Grid container>
          <Grid item xs={12} md={8} lg={6}>
            <List>
              {person.outgoing_contact_blocks &&
                person.outgoing_contact_blocks
                  .sort((a, b) =>
                    moment(a.start_date).isBefore(b.start_date) ? 1 : -1
                  )
                  .map(ocb => {
                    const upcoming = moment(ocb.start_date).isAfter(moment())
                    const active =
                      moment(ocb.end_date).isAfter(moment()) || !ocb.end_date
                    const expired =
                      ocb.end_date && moment(ocb.end_date).isBefore(moment())
                    if (expired && !showExpiredBlocks) return null
                    const info = upcoming
                      ? 'Upcoming'
                      : active
                      ? 'In progress'
                      : 'Expired'
                    return (
                      <Tooltip
                        key={ocb.id}
                        title="Click to edit"
                        aria-label="Click to edit"
                        disableHoverListener={expired}
                        placement="right"
                      >
                        <ListItem
                          style={{ background: '#f4f4f4', marginBottom: 3 }}
                          button={!expired}
                          onClick={() =>
                            active ? editContactBlock(ocb) : null
                          }
                        >
                          <Tooltip
                            key={ocb.id}
                            title={info}
                            aria-label={info}
                            placement="top"
                          >
                            <ListItemIcon>{getBlockIcon(ocb)}</ListItemIcon>
                          </Tooltip>
                          <ListItemText
                            primary={ocb.reason}
                            secondary={
                              upcoming
                                ? `Starts ${moment(ocb.start_date).fromNow()}`
                                : `End${expired ? 'ed' : 's'} ${
                                    !!ocb.end_date
                                      ? moment(ocb.end_date).fromNow()
                                      : 'never'
                                  }`
                            }
                          />
                          <ListItemSecondaryAction>
                            {!expired && (
                              <IconButton
                                aria-label="Delete"
                                onClick={deleteContactBlock(ocb)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Tooltip>
                    )
                  })}
            </List>
          </Grid>
        </Grid>
        <Button
          variant="outlined"
          color="primary"
          aria-label="add contact block"
          onClick={formToggle('addUpdateContactBlock', true)}
        >
          Add a new contact block
        </Button>
      </div>
    )
  }
}

PersonContactBlocks.propTypes = {
  person: PropTypes.object,
  editContactBlock: PropTypes.func,
  deleteContactBlock: PropTypes.func,
  formToggle: PropTypes.func,
}

export default PersonContactBlocks
