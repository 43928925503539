import grey from '@material-ui/core/colors/grey'

export const JSON_SCHEMA_VERSIONS = { search: '3.0.0' }
export const TAG_DELIMITER = ' - '

export const CONTACT_TYPE_COLOURS = {
  twitter: '#55acee',
  facebook: '#3b5999',
  linkedin: '#0077B5',
  phone: grey[500],
  fax: grey[500],
  email: grey[500],
}

export const CONTACT_FORM_LABELS = {
  email: 'Email address',
  phone: 'Phone number',
  twitter: 'Twitter profile URL',
  facebook: 'Facebook profile URL',
  linkedin: 'LinkedIn profile URL',
  fax: 'Fax number',
}

export const FILTER_SELECT_COLOURS = {
  conservative: { color: '#0087DC', text: 'light' },
  labour: { color: '#DC241f', text: 'light' },
  liberalDemocrat: { color: '#FAA61A', text: 'dark' },
  scottishNationalParty: { color: '#FEF987', text: 'dark' },
  plaidCymru: { color: '#008142', text: 'light' },
  green: { color: '#6AB023', text: 'light' },
  sinnFein: { color: '#008800', text: 'light' },
  democraticUnionistParty: { color: '#D46A4C', text: 'light' },
  independent: { color: '#DDDDDD', text: 'dark' },
  speaker: { color: '#ffffff', text: 'dark' },
  theIndependentGroup: { color: '#cccccc', text: 'dark' },
}

export const TAGGABLE_TYPES = {
  PERSON: 'Person',
  AREA: 'Area',
}
