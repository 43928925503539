import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEqual, uniqBy, flatten } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import Fade from '@material-ui/core/Fade'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  stableSort,
  getSorting,
  getTableColumns,
  flattenData,
} from './index.helpers'
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import PushToListForm from './PushToListForm'
import { combineStyles } from 'helpers'
import { base } from 'constants/styles'
import styles from './styles'
import { getExtendedData, fetchLists } from 'state/actions/search'
import http from '../../helpers/http'
import ColumnSelect from './ColumnSelect'

function mapStateToProps(state) {
  return {
    lists: state.cache.lists,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getExtendedData, fetchLists }, dispatch)
}

class DataTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    page: 0,
    rowsPerPage: 10,
    pushToListOpen: false,
    searchText: null,
    show: false,
    columnSelect: false,
    //rowIdentifier: this.props.dataType === 'Area' ? 'area_uid' : 'person_uid',
    merge: null,
  }

  componentDidMount() {
    this.updateColumns()
    if (this.props.controls.includes('merge')) this.props.fetchLists()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      columns: { available },
    } = this.state
    if (!isEqual(prevProps.data, this.props.data)) {
      const items = flattenData(this.props.data, available)
      const selected = prevState.selected.filter(s => {
        return items.filter(d => d[this.props.itemIdPath] === s).length > 0
      })
      this.setState({ selected })
      this.updateColumns()
    }
    if (!isEqual(prevProps.extraColumns, this.props.extraColumns)) {
      this.updateColumns()
    }
  }

  componentWillUnmount() {
    this.setState({ show: false })
  }

  updateColumns = () => {
    const columns = getTableColumns({
      dataType: this.props.dataType,
      settings: this.props.settings,
      search_group: this.props.search_group,
      tagCategories: this.props.tagCategories,
      extras: this.props.extraColumns,
    })

    if (this.props.extraColumns)
      columns.selected = uniqBy(
        columns.selected.concat(this.props.extraColumns),
        'name'
      )
    this.setState({ show: true, columns })
  }

  getSelectedItems = (items, selected) => {
    return items.filter(item => selected.includes(item[this.props.itemIdPath]))
  }

  onRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  onSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({
        selected: flattenData(
          this.props.data,
          this.state.columns.available
        ).map(n => n[this.props.itemIdPath]),
      })
      return
    }
    this.setState({ selected: [] })
  }

  onClickCheckbox = (event, id) => {
    const { selected } = this.state
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    this.setState({ selected: newSelected })
  }

  onClickCell = (url_path, row) => event => {
    event.preventDefault()
    const path = url_path.split(':')
    window.open(path[0] + row[path[1]], '_blank')
  }

  onPageChange = (event, page) => {
    this.setState({ page })
  }

  onChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1

  onClosePushToList = () => {
    this.setState({ pushToListOpen: false })
  }

  onClickPushToList = () => {
    this.setState({ pushToListOpen: true })
  }

  onUpdateSearchText = searchText => {
    this.setState({ searchText })
  }

  filterByTextSearch = (rows, searchText, columns) => {
    const text = searchText.toLowerCase()
    return rows.filter(row => {
      const match = columns
        .filter(c => c.type === 'string')
        .map(
          column =>
            row[column.name] && row[column.name].toLowerCase().includes(text)
        )
      return match.some(el => el)
    })
  }

  toggleColumnsSelect = open => e => {
    e.preventDefault()
    this.setState({ columnSelect: open })
  }

  onSelectAllColumns = async () => {
    const { columns } = this.state
    await columns.available.map(async column => {
      if (column.extended) {
        this.setState({ loading: column.name })
        await this.columnGetExtendedData(column)
        this.setState({ loading: null })
        return true
      }
    })

    this.setState({ columns: { ...columns, selected: columns.available } })
  }

  columnGetExtendedData = column => {
    /** If column is for extended data (e.g. tags) get extended data */
    let { settings, data, getExtendedData } = this.props
    return getExtendedData(
      {
        type: 'tag',
        root: settings.search.filters.tags[column.extended.taggable_type].root,
        path: settings.search.filters.tags[column.extended.taggable_type].path,
        params: {
          name: column.extended.tag_name,
          taggable_type: column.extended.taggable_type,
        },
      },
      data
    )
  }

  toggleColumn = column => async e => {
    e.preventDefault()
    let { columns } = this.state
    const exists =
      columns.selected.findIndex(col => col.name === column.name) > -1

    /** If column is for extended data (e.g. tags) get extended data */
    if (!exists && column.extended) {
      this.setState({ loading: column.name })
      await this.columnGetExtendedData(column)
      this.setState({ loading: null })
    }

    columns = {
      ...columns,
      selected: exists
        ? columns.selected.filter(oc => oc.name !== column.name)
        : columns.selected.concat(
            columns.available.filter(ca => ca.name === column.name)
          ),
    }
    this.setState({ columns, loading: null })
  }

  onUpdateMergeLists = async mergeLists => {
    const mergeIds = !!mergeLists
      ? flatten(
          await Promise.all(
            mergeLists.map(list =>
              http
                .get(`/lists/${list.value}.json`)
                .then(res => res.data.list_entry_ids)
            )
          )
        )
      : []
    this.setState({ merge: mergeIds })
  }

  render() {
    const { classes, dataTitle, controls } = this.props
    const { data, settings, search_group, dataType, lists } = this.props
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      loading,
      //rowIdentifier,
      merge,
      columnSelect,
    } = this.state
    const { page, searchText, show, columns } = this.state
    const passive = !controls || !controls.length

    if (!columns || (controls.includes('merge') && !lists)) return null

    let flattened = flattenData(data, columns.available)
    let people = flattened

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, people.length - page * rowsPerPage)
    const hasData = data && data.length > 0

    if (searchText)
      people = this.filterByTextSearch(flattened, searchText, columns.selected)

    return (
      <Fade in={show} unmountOnExit>
        <Paper className={classes.root}>
          <ColumnSelect
            columns={columns}
            loading={loading}
            onClose={this.toggleColumnsSelect}
            onSelectAllColumns={this.onSelectAllColumns}
            open={columnSelect}
            toggleColumn={this.toggleColumn}
          />

          <PushToListForm
            open={this.state.pushToListOpen}
            items={this.getSelectedItems(flattened, selected)}
            itemType={this.props.groupType}
            itemIdPath={this.props.itemIdPath}
            onClickClose={this.onClosePushToList}
            dataTitle={dataTitle}
            group={this.props.search_group}
            merge={merge}
            goToNewList
          />

          <EnhancedTableToolbar
            numSelected={selected.length}
            onToggleColumnsSelect={this.toggleColumnsSelect}
            onClickPushToList={this.onClickPushToList}
            onUpdateSearchText={this.onUpdateSearchText}
            items={this.getSelectedItems(
              flattenData(
                data,
                columns.selected.concat(
                  columns.available.filter(
                    c => c.name === this.props.itemIdPath
                  )
                )
              ),
              selected
            )}
            controls={controls}
            lists={this.props.lists}
            dataType={dataType}
            onUpdateMergeLists={this.onUpdateMergeLists}
          />

          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.onSelectAllClick}
                onRequestSort={this.onRequestSort}
                rowCount={people.length}
                passive={passive}
                columns={columns.selected}
              />
              <TableBody>
                {stableSort(people, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    const isSelected = this.isSelected(
                      row[this.props.itemIdPath]
                    )
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={row[this.props.itemIdPath]}
                        selected={isSelected}
                      >
                        {!passive && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected}
                              onClick={event =>
                                this.onClickCheckbox(
                                  event,
                                  row[this.props.itemIdPath]
                                )
                              }
                            />
                          </TableCell>
                        )}
                        {columns.selected.map(column => (
                          <TableCell
                            key={column.name}
                            onClick={
                              column.url_path
                                ? this.onClickCell(column.url_path, row)
                                : null
                            }
                            className={
                              column.url_path
                                ? classNames(
                                    classes.clickable,
                                    classes.tableCell
                                  )
                                : ''
                            }
                          >
                            {(row[column.name] || row[column.name] === false) &&
                              row[column.name].toString()}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                {hasData && emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          {/** Columns select drawer END*/}

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={people.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.onPageChange}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
          />
        </Paper>
      </Fade>
    )
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTitle: PropTypes.string,
  data: PropTypes.array,
  dataType: PropTypes.string,
  groupType: PropTypes.string,
  itemIdPath: PropTypes.string,
  controls: PropTypes.array,
  settings: PropTypes.object,
  search_group: PropTypes.string,
  tagCategories: PropTypes.array,
  getExtendedData: PropTypes.func,
  extraColumns: PropTypes.array,
  fetchLists: PropTypes.func,
  lists: PropTypes.array,
  filters: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(combineStyles(base, styles))(DataTable))
