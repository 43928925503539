import React from 'react'
import * as PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'

import styles from './styles'
import FriendlyAttributeDiff from './FriendlyAttributeDiff'
import FriendlyAssociationDiff from './FriendlyAssociationDiff'

class FriendlyDiff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      friendlyDiff: this.props.friendlyDiff,
    }
  }

  noAttributeChanges() {
    const { friendlyDiff } = this.state
    return Object.keys(friendlyDiff.attribute_changes).length === 0
  }

  noAssociationChanges() {
    const { friendlyDiff } = this.state
    return Object.keys(friendlyDiff.association_changes).length === 0
  }

  noChanges() {
    return this.noAttributeChanges() && this.noAssociationChanges()
  }

  badge() {
    const { classes } = this.props
    const changeType = this.state.friendlyDiff.change_type
    if (changeType === 'No Changes') return ''

    // TODO: other icons for other action types
    const icon = <AddCircleOutline />

    return (
      <Chip
        icon={icon}
        label={changeType}
        className={classes[`chip${changeType}`]}
      />
    )
  }

  render() {
    const { classes } = this.props
    const { friendlyDiff } = this.state
    const attributeChanges = friendlyDiff.attribute_changes
    const associationChanges = friendlyDiff.association_changes
    const changeType = friendlyDiff.change_type

    if (this.noChanges()) return <span>{friendlyDiff.description}</span>

    return (
      <Card>
        <CardContent className={classes.friendlyDiff}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography
                className={classes.friendlyDiffTitle}
                variant="subtitle1"
              >
                {this.badge()}
                &nbsp;
                {friendlyDiff.description}
              </Typography>
            </Grid>
            {this.noAttributeChanges() || (
              <Grid item xs={12}>
                {Object.keys(attributeChanges).map(attributeName => (
                  <FriendlyAttributeDiff
                    attributeName={attributeName}
                    attributeOld={attributeChanges[attributeName][0]}
                    attributeNew={attributeChanges[attributeName][1]}
                    changeType={changeType}
                  />
                ))}
              </Grid>
            )}
            {this.noAssociationChanges() || (
              <Grid item xs={12}>
                {Object.keys(associationChanges).map(associationName => (
                  <FriendlyAssociationDiff
                    associationName={associationName}
                    associations={associationChanges[associationName]}
                  />
                ))}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

FriendlyDiff.propTypes = {
  friendlyDiff: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(FriendlyDiff)
