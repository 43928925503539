import React, { PureComponent } from 'react'
import * as PropTypes from 'prop-types'
import { upperFirst } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import styles from './styles'

class MembershipCard extends PureComponent {
  render() {
    const { membership, classes } = this.props
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {`${membership.organization.name}
              (${membership.organization.organization_classification})
            `}
          </Typography>
          <Tooltip title={membership.role.full_name || membership.role.name}>
            <Typography variant="h6" component="h2">
              {`${upperFirst(membership.role.name)}`}
            </Typography>
          </Tooltip>
          <Typography component="p" gutterBottom>
            {!membership.area
              ? ''
              : `${membership.area.name}, ${membership.area.parent_areas &&
                  membership.area.parent_areas.country[0].name}`}
          </Typography>
          <Typography color="textSecondary">
            {`${
              membership.start_date
                ? new Date(membership.start_date).toDateString()
                : 'Unknown'
            } - ${
              membership.end_date
                ? new Date(membership.end_date).toDateString()
                : 'Present'
            }`}
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

MembershipCard.propTypes = {
  membership: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(MembershipCard)
