import React from 'react'
import * as PropTypes from 'prop-types'
import URL from 'url-parse'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography/Typography'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PersonInfo from './PersonInfo'
import PersonContacts from './PersonContacts'
import { base } from 'constants/styles'
import { fetchPerson } from 'state/actions/person'

function mapStateToProps(state) {
  return {
    person: state.cache.person,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPerson }, dispatch)
}

class Person extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      twitter: false,
    }
  }

  componentDidMount() {
    this.props.fetchPerson(this.props.match.params.id)
  }

  loadTwitter = () => {
    this.setState({ twitter: true })
  }

  onTabChange = (event, tab) => {
    this.setState({ tab })
  }

  hasContactBlock = e => {
    if (e) e.preventDefault()
    let hasBlock = false
    const now = Date.now()
    this.props.person.outgoing_contact_blocks.forEach(ocb => {
      if (
        Date.parse(ocb.start_date) < now &&
        (!ocb.end_date || Date.parse(ocb.end_date) > now)
      ) {
        hasBlock = true
      }
    })
    return hasBlock
  }

  render() {
    const { classes, person } = this.props
    const { tab, twitter } = this.state
    const { hasContactBlock } = this

    if (!person || (person && person.id !== Number(this.props.match.params.id)))
      return null

    return (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant={'h3'} gutterBottom>
            {person.name}
          </Typography>
          {hasContactBlock() && (
            <Typography variant={'h5'} color={'secondary'} gutterBottom>
              BLOCKED
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1}>
            <AppBar position="static">
              <Tabs value={tab} onChange={this.onTabChange}>
                <Tab label="Basic Info" />
                <Tab label="Contacts" aria-label="contacts tab button" />
                <Tab label="Twitter" />
              </Tabs>
            </AppBar>
            <div className={classes.tab}>
              {tab === 0 && <PersonInfo {...{ person }} />}
              {tab === 1 && <PersonContacts person={person} />}
              {tab === 2 && (
                <Grid container>
                  <Grid item xs={12} sm={8} lg={6}>
                    {twitter ? (
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName={URL(
                          person.contacts.twitter[0].value
                        ).pathname.replace(/^\/+/g, '')}
                        options={{ height: '80vh' }}
                      />
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={this.loadTwitter}
                      >
                        Load twitter feed...
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

Person.propTypes = {
  classes: PropTypes.object,
  person: PropTypes.any,
  match: PropTypes.object,
  fetchPerson: PropTypes.func,
  loadTagCategories: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(base)(Person))
