import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import FriendlyDiff from './FriendlyDiff'

class FriendlyAssociationDiff extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      associationName: this.props.associationName,
      associations: this.props.associations,
    }
  }

  render() {
    const { classes } = this.props
    const { associationName, associations } = this.state

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={8}
        className={classes.associationContainer}
      >
        <Grid item xs={2} className={classes.attributeName}>
          <span>{associationName}:</span>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" spacing={8}>
          {associations.map(associationChange => (
            <Grid item xs={11}>
              <FriendlyDiff friendlyDiff={associationChange} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }
}

FriendlyAssociationDiff.propTypes = {
  associationName: PropTypes.string,
  associations: PropTypes.array,
  classes: PropTypes.object,
}

export default withStyles(styles)(FriendlyAssociationDiff)
