import React from 'react'
import * as PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import PublicIcon from '@material-ui/icons/Public'
import FormHelperText from '@material-ui/core/FormHelperText'

const AreaTypeSelection = props => {
  return (
    <FormControl>
      <InputLabel htmlFor="age-helper">Area Type</InputLabel>
      <Select
        value={props.selected}
        onChange={props.onChange}
        input={<Input name="age" id="age-helper" />}
      >
        <MenuItem value="" disabled>
          <em>None</em>
        </MenuItem>
        {props.areaTypes
          .filter(ac => props.settings.available_area_types.includes(ac.name))
          .map(ac => (
            <MenuItem key={ac.id} value={ac.id}>
              {ac.name}{' '}
              {props.settings.maps.filter(item => item.name === ac.name)
                .length ? (
                <PublicIcon style={{ marginLeft: 5 }} />
              ) : (
                ''
              )}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>
        Select the area type you would like to search
      </FormHelperText>
    </FormControl>
  )
}

AreaTypeSelection.propTypes = {
  selected: PropTypes.any,
  onChange: PropTypes.func,
  areaTypes: PropTypes.array,
  settings: PropTypes.object,
}

export default AreaTypeSelection
