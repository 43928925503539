import grey from '@material-ui/core/colors/grey'

export const base = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  container: {
    backgroundColor: grey[100],
  },
  tab: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
      backgroundColor: '#dfdfdf',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  buttonBase: {
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#e7eaff',
    },
  },
  boldText: {
    color: 'black',
    fontWeight: '900',
  },
  warningSnack: {
    backgroundColor: '#f50057',
  },
  warningSnackText: {
    color: 'white',
    fontWeight: 'bold',
  },
})

export const forms = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
})

export default base
