import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import http from 'helpers/http'
import axios from 'axios'

import UploadStepBeforeStarting from './UploadStepBeforeStarting'
import UploadStepSelectAreaData from './UploadStepSelectAreaData'
import UploadStepAreaDataSource from './UploadStepAreaDataSource'
import UploadStepSelectCsvHeaders from './UploadStepSelectCsvHeaders'

import CsvFileViewer from 'components/CsvFileViewer'
import CsvUpload from 'components/CsvUpload'

import styles from './styles'

class AreaDataUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      areaDataRecords: JSON.parse(this.props.areaDataRecords),
      activeStep: 0,
      csvFile: null,
      csvData: null,
      selectedAreaData: null,
      areaDataSourceUrl: '',
      areaDataSourceDate: '',
      areaIdentifierCsvColumn: '',
      statsCsvColumns: [{ column: '', qualifier: '' }],
      taskStatus: null,
    }
  }

  resetState = () => {
    this.setState({
      activeStep: 0,
      csvFile: null,
      csvData: null,
      selectedAreaData: null,
      areaDataSourceUrl: '',
      areaDataSourceDate: '',
      areaIdentifierCsvColumn: '',
      statsCsvColumns: [{ column: '', qualifier: '' }],
      taskStatus: null,
    })
  }

  nextStep = () => {
    this.setState(previousState => ({
      activeStep: previousState.activeStep + 1,
    }))
  }

  previousStep = () => {
    this.setState(previousState => ({
      activeStep: previousState.activeStep - 1,
    }))
  }

  onProceedWithCsv = (csvResults, csvFile) => {
    this.setState(previousState => ({
      activeStep: previousState.activeStep + 1,
      csvFile: csvFile,
      csvData: csvResults,
    }))
  }

  onAreaDataSelectionUpdated = selectedAreaData => {
    this.setState({
      selectedAreaData: selectedAreaData,
    })
  }

  onAreaDataSourceUpdated = (areaDataSourceUrl, areaDataSourceDate) => {
    this.setState({
      areaDataSourceUrl: areaDataSourceUrl,
      areaDataSourceDate: areaDataSourceDate,
    })
  }

  onCsvHeaderSelectionsUpdated = (areaIdentifierCsvColumn, statsCsvColumns) => {
    this.setState({
      areaIdentifierCsvColumn: areaIdentifierCsvColumn,
      statsCsvColumns: statsCsvColumns,
    })
  }

  doImport = async () => {
    function putCsvFile(uri, file) {
      return axios.put(uri, file, { headers: { 'Content-Type': 'text/csv' } })
    }

    const upload_res = await http.post('/area_data/new_upload_url', {
      csv_filename: this.state.csvFile.name,
    })
    await putCsvFile(upload_res.data['uploadUrl'], this.state.csvFile)
    // go to 'Uploading' step
    this.nextStep()
    // go to the 'Processing' step
    const payload = {
      s3_objname: upload_res.data['s3ObjName'],
      area_data_id: this.state.selectedAreaData.id,
      area_data_source_url: this.state.areaDataSourceUrl,
      area_data_source_date: this.state.areaDataSourceDate,
      area_identifier_column: this.state.areaIdentifierCsvColumn,
      stats_csv_columns: this.state.statsCsvColumns,
    }
    this.setState({
      taskStatus: (await http.post('/area_data/upload', payload)).data,
    })
    this.nextStep()
    while (this.state.taskStatus['status'] !== 'done') {
      // sleep 5 seconds
      await new Promise(resolve => setTimeout(resolve, 5000))

      this.setState({
        taskStatus: (
          await http.get(
            '/area_data/task_status?task_id=' + this.state.taskStatus['id']
          )
        ).data,
      })
    }

    // go to the 'Done' step
    this.nextStep()
  }

  getSteps = () => {
    return [
      'Before you start',
      'Upload Area Data CSV',
      'Select Area Data type',
      'Input Area Data Source & Date',
      'Select CSV Columns',
      'Confirm',
      'Uploading',
      'Processing',
      'Done',
    ]
  }

  getStepContent = () => {
    const {
      areaDataRecords,
      activeStep,
      csvData,
      selectedAreaData,
      areaDataSourceUrl,
      areaDataSourceDate,
      areaIdentifierCsvColumn,
      statsCsvColumns,
    } = this.state

    const stepLabel = this.getSteps()[activeStep]

    switch (stepLabel) {
      case 'Before you start':
        return <UploadStepBeforeStarting onNext={this.nextStep} />
      case 'Upload Area Data CSV':
        return <CsvUpload onProceedWithCsv={this.onProceedWithCsv} />
      case 'Select Area Data type':
        return (
          <div>
            <UploadStepSelectAreaData
              areaDataRecords={areaDataRecords}
              selectedAreaData={selectedAreaData}
              onAreaDataChange={this.onAreaDataSelectionUpdated}
              onNext={this.nextStep}
              onReset={this.resetState}
            />
            <CsvFileViewer
              csvData={csvData}
              displayRows={Array.from(
                Array(Math.min(10, csvData.data.length)).keys()
              )} // Preview first 10 rows
            />
          </div>
        )
      case 'Input Area Data Source & Date':
        return (
          <div>
            <UploadStepAreaDataSource
              areaDataSourceUrl={areaDataSourceUrl}
              areaDataCollectedDate={areaDataSourceDate}
              onAreaDataSourceUpdated={this.onAreaDataSourceUpdated}
              onNext={this.nextStep}
            />
            <CsvFileViewer
              csvData={csvData}
              displayRows={Array.from(
                Array(Math.min(10, csvData.data.length)).keys()
              )} // Preview first 10 rows
            />
          </div>
        )
      case 'Select CSV Columns':
        return (
          <div>
            <UploadStepSelectCsvHeaders
              csvData={csvData}
              areaIdentifierCsvColumn={areaIdentifierCsvColumn}
              statsCsvColumns={statsCsvColumns}
              onHeaderSelectionsUpdated={this.onCsvHeaderSelectionsUpdated}
              onNext={this.nextStep}
            />
            <CsvFileViewer
              csvData={csvData}
              displayRows={Array.from(
                Array(Math.min(10, csvData.data.length)).keys()
              )} // Preview first 10 rows
            />
          </div>
        )
      case 'Confirm':
        return (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={this.state.csvFile.name}
                      secondary="CSV data source"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={this.state.areaDataSourceDate.toString()}
                      secondary="Source date"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={this.state.areaDataSourceUrl}
                      secondary="Source URL"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={this.state.selectedAreaData.name}
                      secondary="Area data target"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={this.state.areaIdentifierCsvColumn}
                      secondary="Name of area code column in CSV"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={JSON.stringify(this.state.statsCsvColumns)}
                      secondary="Stats columns to import from CSV"
                    />
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.doImport}
                size="large"
                style={{ marginLeft: 14 }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        )
      case 'Uploading':
        return <div>Uploading...</div>
      case 'Processing':
        return (
          <div>
            <Typography>
              {(() => {
                switch (this.state.taskStatus['status']) {
                  case 'queued':
                    return 'Queued'
                  case 'processing':
                    return 'Processing...'
                  case 'failed':
                    return 'Failed: ' + this.state.taskStatus['log']
                }
              })()}
            </Typography>
          </div>
        )
      case 'Done':
        return (
          <div>
            <Typography>Done!</Typography>
          </div>
        )
    }
  }

  render() {
    const { classes } = this.props
    const { activeStep } = this.state

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} style={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h4">Upload Area Statistics</Typography>
          <Button
            variant="outlined"
            color="primary"
            disabled={activeStep < 3}
            onClick={this.previousStep}
            style={{ marginLeft: 20 }}
          >
            Back a step
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={activeStep < 2}
            onClick={this.resetState}
            style={{ marginLeft: 20 }}
          >
            Reset
          </Button>
        </Grid>

        <Grid item xs={12}>
          <div>
            <Stepper
              activeStep={activeStep}
              orientation="horizontal"
              className={classes.stepper}
            >
              {this.getSteps().map((stepLabel, index) => (
                <Step key={index.toString()}>
                  <StepLabel>{stepLabel}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.getStepContent()}
          </div>
        </Grid>
      </Grid>
    )
  }
}

AreaDataUpload.propTypes = {
  classes: PropTypes.object,
  areaDataRecords: PropTypes.string.isRequired,
}

export default withStyles(styles)(AreaDataUpload)
