import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TextWithHelp from 'components/TextWithHelp'

import styles from './styles'

class UploadStepAreaDataSource extends React.Component {
  constructor(props) {
    super(props)
  }

  handleSourceUrlChange = event => {
    const { onAreaDataSourceUpdated, areaDataCollectedDate } = this.props
    onAreaDataSourceUpdated(event.target.value, areaDataCollectedDate)
  }

  handleCollectedDateChange = date => {
    const { onAreaDataSourceUpdated, areaDataSourceUrl } = this.props
    onAreaDataSourceUpdated(areaDataSourceUrl, date)
  }

  onNextButton = () => {
    const { areaDataSourceUrl, areaDataCollectedDate, onNext } = this.props
    if (areaDataSourceUrl && areaDataCollectedDate) {
      onNext()
    }
  }

  render() {
    const { classes, areaDataSourceUrl, areaDataCollectedDate } = this.props

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <TextWithHelp
                id="text-area-data-source-help-text"
                typographyVariant="h6"
                text="Input the URL where the Area Data originally came from"
                helpText="This is required so we have a reference for where our figures came from in our comms to members & politicians"
              />
              <TextField
                id="text-area-data-source"
                label="Area Data Source URL"
                className={classes.textField}
                value={areaDataSourceUrl}
                onChange={this.handleSourceUrlChange}
                margin="normal"
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextWithHelp
                    id="text-area-data-date-help-text"
                    typographyVariant="h6"
                    text="Choose the date the Area Data was collected"
                    helpText="This is date the data refers to, not the time you downloaded the CSV or the time the figures were released. Eg. Unemployment figures may relate to February 2018, but not be released until March and you may have downloaded them in April."
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    id="text-area-data-date-help-text"
                    keyboard
                    clearable
                    label="Area Data Collected Date"
                    value={areaDataCollectedDate}
                    onChange={this.handleCollectedDateChange}
                    animateYearScrolling={true}
                    maxDate={moment().format()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!areaDataSourceUrl || !areaDataCollectedDate}
                    onClick={this.onNextButton}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    )
  }
}

UploadStepAreaDataSource.propTypes = {
  classes: PropTypes.object,
  areaDataSourceUrl: PropTypes.string,
  areaDataCollectedDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onAreaDataSourceUpdated: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default withStyles(styles)(UploadStepAreaDataSource)
