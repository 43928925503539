import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import FormControl from '@material-ui/core/FormControl/FormControl'
import TextField from '@material-ui/core/TextField/TextField'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from '@material-ui/core/Button/Button'
import styles from './styles'

import { approveDraft, rejectDraft } from './index.actions'

class ReviewForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      draftTransactionId: this.props.draftTransactionId,
      reviewReason: '',
      valid: false,
    }
  }

  formTitle() {
    return `${this.props.method} Draft Change #${this.state.draftTransactionId}`
  }

  buttonName() {
    return `Confirm ${this.props.method}`
  }

  buttonColor() {
    if (this.props.method === 'Approve') {
      return 'primary'
    } else if (this.props.method === 'Reject') {
      return 'secondary'
    }
  }

  isValid() {
    return this.validate(this.state.reviewReason)
  }

  onUpdate = event => {
    const reason = event.target.value
    this.setState({
      reviewReason: reason,
      valid: this.validate(reason),
    })
  }

  validate = reason => {
    // Don't require an approval reason, but do require a reject reason
    return (
      this.props.method === 'Approve' ||
      (this.props.method === 'Reject' && reason.length > 1)
    )
  }

  onSubmit = event => {
    event.preventDefault()
    const { draftTransactionId } = this.state
    const method = this.props.method
    const reason = this.state.reviewReason
    if (method === 'Approve') {
      approveDraft(draftTransactionId, reason).then(res => {
        if (res.status === 200) {
          this.props.onSubmitCallback(event)
        } else {
          alert(
            `Something went wrong, call a techie! Unexpected result status ${res.status} when approving draft #${draftTransactionId}`
          )
        }
      })
    } else if (method === 'Reject') {
      rejectDraft(draftTransactionId, reason).then(res => {
        if (res.status === 200) {
          this.props.onSubmitCallback(event)
        } else {
          alert(
            `Something went wrong, call a techie! Unexpected result status ${res.status} when rejecting draft #${draftTransactionId}`
          )
        }
      })
    } else {
      alert(
        `Something went wrong, call a techie! Unrecognized method ${method}`
      )
    }
  }

  render() {
    const { classes } = this.props
    const { reviewReason } = this.state
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClickClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.formTitle()}</DialogTitle>
          <DialogContent className={classes.form}>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                autoFocus
                autoComplete="off"
                value={reviewReason}
                label={`${this.props.method} Reason`}
                type="text"
                onChange={this.onUpdate}
                inputProps={{
                  name: 'reason',
                  id: 'reason',
                }}
                fullWidth
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClickClose}>Cancel</Button>
            <Button
              onClick={this.onSubmit}
              disabled={!this.isValid()}
              color={this.buttonColor()}
            >
              {this.buttonName()}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ReviewForm.propTypes = {
  open: PropTypes.bool,
  method: PropTypes.string,
  onClickClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  draftTransactionId: PropTypes.number,
  classes: PropTypes.object,
}

export default withStyles(styles)(ReviewForm)
