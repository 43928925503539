import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from './SliderComponents' // example render components - source below
import indigo from '@material-ui/core/colors/indigo'
import { Typography } from '@material-ui/core'
import Controls from 'components/Filters/Controls'

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

class FilterNumeric extends React.PureComponent {
  histogram = React.createRef()

  constructor(props) {
    super(props)
    const selected =
      this.props.filter.selected &&
      this.props.filter.selected.length &&
      this.props.filter.selected
    this.state = {
      domain: this.props.filter.range,
      values: selected || this.props.filter.range,
      update:
        (this.props.filter.selected && this.props.filter.selected.length) ||
        this.props.filter.range,
      histogramWidth: 30,
    }
    this.binCount = Math.floor(
      this.props.itemValues.filter(iv => !!iv).length / 10
    )
  }

  componentDidMount() {
    this.setState({ histogramWidth: this.histogram.current.clientWidth })
  }

  onUpdate = update => {
    this.setState({ update })
  }

  onChange = values => {
    const { filter } = this.props
    this.props.onChange({ filter: { ...filter, selected: values } })
    this.setState({ values })
  }

  onModifierUpdate = modifier => {
    this.props.onChange({
      modifier,
      filter: this.props.filter,
    })
  }

  render() {
    const {
      state: { domain, values, histogramWidth },
    } = this
    const { itemValues, filter, compact } = this.props

    return (
      <div
        ref={this.histogram}
        style={{
          width: '100%',
        }}
      >
        <Grid
          container
          direction="row"
          spacing={4}
          style={{
            padding: compact ? 0 : '20px 15px 50px 15px',
          }}
        >
          {compact && (
            <Grid item xs={5}>
              <Typography style={{ overflowWrap: 'break-word' }}>
                {filter.label}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={compact ? 7 : 12}
            style={{ marginTop: 20, paddingRight: 30 }}
          >
            <Slider
              mode={2}
              step={0.01}
              domain={domain}
              rootStyle={sliderStyle}
              onUpdate={this.onUpdate}
              onChange={this.onChange}
              values={values}
            >
              <Rail>
                {({ getRailProps }) => (
                  <SliderRail getRailProps={getRailProps} />
                )}
              </Rail>
              <Handles>
                {({ handles, getHandleProps }) => (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <Handle
                        key={handle.id}
                        handle={handle}
                        domain={domain}
                        getHandleProps={getHandleProps}
                      />
                    ))}
                  </div>
                )}
              </Handles>
              <Tracks left={false} right={false}>
                {({ tracks, getTrackProps }) => (
                  <div className="slider-tracks">
                    {tracks.map(({ id, source, target }) => (
                      <Track
                        key={id}
                        source={source}
                        target={target}
                        getTrackProps={getTrackProps}
                      />
                    ))}
                  </div>
                )}
              </Tracks>
            </Slider>
          </Grid>
        </Grid>
        <Controls filter={filter} onModifierUpdate={this.onModifierUpdate} />
      </div>
    )
  }
}

FilterNumeric.propTypes = {
  filter: PropTypes.object,
  itemValues: PropTypes.array,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
}

export default FilterNumeric
