import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  container: {
    height: '100%',
    paddingTop: 3,
  },
  button: {
    width: '100%',
    height: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  compact: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  unselected: {
    backgroundColor: grey[100],
    transition: '0.3s',
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
  unselectedText: {
    color: theme.palette.primary.main,
  },
  selectedText: {
    color: 'white',
  },
})

export default styles
