import http from 'helpers/http'

export const approveDraft = async (id, reason) => {
  const body = { reason: reason }
  return await http.post(`/drafts/${id}/approve.json`, body).then(res => res)
}

export const rejectDraft = async (id, reason) => {
  const body = { reason: reason }
  return await http.post(`/drafts/${id}/reject.json`, body).then(res => res)
}
