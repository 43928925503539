import { lighten } from '@material-ui/core/styles/colorManipulator'
import { FILTER_SELECT_COLOURS } from 'constants/index'

const listItemColours = {}
Object.entries(FILTER_SELECT_COLOURS).forEach(item => {
  listItemColours[item[0] + 'ListItem'] = {
    height: 24,
    borderBottom: '1px solid #dcdcdc',
    backgroundColor: lighten(item[1].color, 0.85) + ' !important',
  }
  listItemColours[item[0] + 'Avatar'] = {
    backgroundColor: item[1].color + ' !important',
    color: item[1].text === 'dark' ? 'black' : 'white',
    height: 20,
    width: 20,
    fontSize: 12,
  }
})

const styles = theme => ({
  container: {
    height: '100%',
    paddingTop: 3,
  },
  avatar: {
    backgroundColor: theme.palette.default,
    height: 20,
    width: 20,
    fontSize: 12,
  },
  avatarSelected: {
    backgroundColor: theme.palette.primary.main,
    height: 20,
    width: 20,
    fontSize: 12,
  },
  listItem: {
    height: 24,
    borderBottom: '1px solid #dcdcdc',
    borderTop: '1px solid white',
  },
  listItemSelected: {
    backgroundColor: '#dfe2f3 !important',
    height: 24,
    borderBottom: '1px solid #dcdcdc',
  },
  listItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ...listItemColours,
})

export default styles
