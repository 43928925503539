import React from 'react'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class UploadStepBeforeStarting extends React.Component {
  constructor(props) {
    super(props)
  }

  onSelectionChange = event => {
    const { areaDataRecords, onAreaDataChange } = this.props

    var filteredRecords = areaDataRecords.filter(
      r => r.id === event.target.value
    )

    if (filteredRecords && filteredRecords.length > 0) {
      onAreaDataChange(filteredRecords[0])
    } else {
      onAreaDataChange(null)
    }
  }

  onNextButton = () => {
    const { selectedAreaData, onNext } = this.props
    if (selectedAreaData) {
      onNext()
    }
  }

  render() {
    const { classes, areaDataRecords, selectedAreaData } = this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Select the type of Area Data you are uploading.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="select-area-data"
            select
            SelectProps={{ SelectDisplayProps: { role: 'area-type-select' } }}
            label="Area Data type"
            className={classes.textField}
            value={selectedAreaData ? selectedAreaData.id : ''}
            onChange={this.onSelectionChange}
            margin="normal"
          >
            {areaDataRecords.map(areaDataRecord => (
              <MenuItem key={areaDataRecord.id} value={areaDataRecord.id}>
                {areaDataRecord.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            {selectedAreaData &&
              `Value: ${selectedAreaData.name}, Description: ${selectedAreaData.description}, Type: ${selectedAreaData.data_type}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedAreaData}
            onClick={this.onNextButton}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    )
  }
}

UploadStepBeforeStarting.propTypes = {
  classes: PropTypes.object,
  areaDataRecords: PropTypes.array.isRequired,
  selectedAreaData: PropTypes.object,
  onAreaDataChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default withStyles(styles)(UploadStepBeforeStarting)
