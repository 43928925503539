import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class AreaDataViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      areaDataEnhanced: JSON.parse(this.props.areaDataEnhanced),
    }
  }

  render() {
    const { classes } = this.props
    const { areaDataEnhanced } = this.state

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} style={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h4">Area Statistics Viewer</Typography>
        </Grid>

        <Grid container item xs={12}>
          {areaDataEnhanced.map(areaDataObj => (
            <Grid item xs={12} lg={10} xl={8}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="{areaDataObj.name}-content"
                  id="{areaDataObj.name}-header"
                >
                  <Typography variant="subtitle1">
                    {areaDataObj.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <Typography gutterBottom>
                    Description: {areaDataObj.description}
                  </Typography>
                  <Typography gutterBottom>
                    Data Type: {areaDataObj.data_type}
                  </Typography>
                  <Typography gutterBottom>
                    {areaDataObj.area_data_sources.size} Datasets:
                  </Typography>
                  {areaDataObj.area_data_sources.map(areaDataSource => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="{areaDataObj.name}-source{areaDataSource.id}-content"
                        id="{areaDataObj.name}-source{areaDataSource.id}-header"
                      >
                        <Typography variant="subtitle1">
                          Dataset from {areaDataSource.collected_at}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: 'block' }}>
                        <Typography gutterBottom>
                          Data Source URL: {areaDataSource.source_url}
                        </Typography>
                        <Typography gutterBottom>
                          Areas that have {areaDataObj.name} data from
                          {areaDataSource.collected_at}:
                        </Typography>
                        {areaDataSource.area_classifications.map(areaClass => (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="{areaDataObj.name}-source{areaDataSource.id}-{areaClass.area_class_name}-content"
                              id="{areaDataObj.name}-source{areaDataSource.id}-{areaClass.area_class_name}-header"
                            >
                              <Typography variant="subtitle1">
                                {areaClass.area_class_name} (
                                {areaClass.areas_with_data} /{' '}
                                {areaClass.total_areas} areas have data)
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ display: 'block' }}>
                              {areaClass.area_data_qualifiers.map(
                                areaDataQualifier => (
                                  <Typography>
                                    {areaDataQualifier.qualifier_name}:{' '}
                                    {areaDataQualifier.area_count} /{' '}
                                    {areaClass.total_areas} areas have data
                                  </Typography>
                                )
                              )}
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }
}

// Object.keys(this.state.areaDataEnhanced).map(function(areaClassName) {
//
// })

AreaDataViewer.propTypes = {
  classes: PropTypes.object,
  areaDataEnhanced: PropTypes.string.isRequired,
}

export default withStyles(styles)(AreaDataViewer)
