import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ButtonBase from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core'
import { titleCase } from 'helpers'
import AddButton from 'components/AddButton'
import { base } from 'constants/styles'

class PersonTags extends Component {
  render() {
    const { tags, classes } = this.props

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Tags
        </Typography>
        <Grid
          container
          spacing={3}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          {tags &&
            Object.entries(tags).map(tag => (
              <Grid item key={tag[1].id} style={{ marginRight: 25 }}>
                <ButtonBase
                  onClick={this.props.formToggle(
                    'addUpdateDeleteTag',
                    true,
                    tag[1]
                  )}
                  className={classes.buttonBase}
                >
                  <div>
                    <Typography variant="h4" style={{ fontWeight: 900 }}>
                      {titleCase(tag[1].value)}
                    </Typography>
                    <hr />
                    <Typography variant="body1">{tag[0]}</Typography>
                  </div>
                </ButtonBase>
              </Grid>
            ))}
          <Grid item>
            <AddButton
              title={'Add new tag'}
              onClick={this.props.formToggle('addUpdateDeleteTag', true)}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

PersonTags.propTypes = {
  tags: PropTypes.object,
  formToggle: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(base)(PersonTags)
