import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  modifierButtonText: {
    fontSize: 12,
  },
  modifierButton: {
    height: 30,
  },
})

const Controls = ({ filter, onModifierUpdate }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      style={{
        paddingTop: 10,
        paddingBottom: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item>
        <ToggleButtonGroup
          value={filter.modifiers.not}
          exclusive
          onChange={(event, not) =>
            onModifierUpdate({ name: 'not', value: not })
          }
        >
          <ToggleButton value={false} className={classes.modifierButton}>
            <Typography variant="button" className={classes.modifierButtonText}>
              Include
            </Typography>
          </ToggleButton>
          <ToggleButton value={true} className={classes.modifierButton}>
            <Typography variant="button" className={classes.modifierButtonText}>
              Exclude
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {!filter.exclusive && filter.modifiers.hasOwnProperty('and') && (
        <Grid item>
          <ToggleButtonGroup
            value={filter.modifiers.and}
            exclusive
            onChange={(event, and) =>
              onModifierUpdate({ name: 'and', value: and })
            }
          >
            <ToggleButton value={false} className={classes.modifierButton}>
              <Typography
                variant="button"
                className={classes.modifierButtonText}
              >
                Any
              </Typography>
            </ToggleButton>
            <ToggleButton value={true} className={classes.modifierButton}>
              <Typography
                variant="button"
                className={classes.modifierButtonText}
              >
                All
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  )
}

Controls.propTypes = {
  filter: PropTypes.object,
  onModifierUpdate: PropTypes.func,
}

export default Controls
