import React from 'react'
import * as PropTypes from 'prop-types'
import { clone, isEqual } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import styles from './styles'
import AddFilterForm from 'components/Filters/AddFilterForm'
import FilterSelect from 'components/Filters/FilterSelect'
import FilterSwitch from 'components/Filters/FilterSwitch'
import FilterNumeric from 'components/Filters/FilterNumeric'
import FilterGeo from './FilterGeo'
import FilterContainer from './FilterContainer'
import {
  urlParams,
  updateUrlParams,
  getAvailableSearchFilters,
  getExtendedDataRequest,
} from './index.helpers'
import { getExtendedData, fetchLists } from 'state/actions/search'
import { snackbarNotify } from 'state/actions/app'
import Loading from 'components/Loading'
import {
  getFilterOptions,
  getItemValues,
  populateFilterOptions,
  updateFilter,
} from 'components/Filters/index.helpers'
import FilterByList from 'components/Filters/FilterByList'

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getExtendedData, snackbarNotify, fetchLists },
    dispatch
  )
}

class SearchFilters extends React.PureComponent {
  state = {
    addFilterForm: false,
    filters: [],
    loading: true,
    availableFilters: [],
  }

  componentDidMount() {
    const { settings, search_group, tagCategories, areaDataTypes } = this.props
    const parsed = urlParams(settings, search_group)
    const availableFilters = getAvailableSearchFilters({
      settings,
      search_group,
      tagCategories,
      areaDataTypes,
    })
    const newState = { loading: false, availableFilters }

    /** Get filters from URL params if present */
    if (parsed && !!parsed.filters) {
      this.rebuildFilters(parsed, availableFilters).then(filters => {
        this.setState({ ...newState, filters })
        this.props.onFiltersUpdated(filters)
      })
    } else {
      this.setState(newState)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state
    if (!isEqual(prevState.filters, filters)) {
      updateUrlParams(filters)
      this.props.onFiltersUpdated(filters)
    }
  }

  rebuildFilters = async (parsed, availableFilters) => {
    const { data: items } = this.props

    return Promise.all(
      await parsed.filters
        .map(async (filterName, idx) => {
          const filter = await this.buildFilter(filterName, availableFilters)
          if (!filter) return null
          if (['number', 'percentage', 'id'].includes(filter.type)) {
            filter.selected = JSON.parse(parsed.selected[idx])
          } else {
            const options = getFilterOptions(items, filter)
            const selected = parsed.selected && JSON.parse(parsed.selected[idx])
            if (parsed.modifiers)
              filter.modifiers = JSON.parse(parsed.modifiers[idx])
            filter.selected = options
              ? options.filter(o => !!selected && selected.includes(o.value))
              : []
          }
          return filter
        })
        .filter(item => !!item)
    )
  }

  buildFilter = async (filterName, availableFilters) => {
    const { settings } = this.props
    let { data } = this.props
    let newFilter = (availableFilters || this.state.availableFilters).filter(
      t => t.name === filterName
    )[0]
    if (!newFilter) return null
    let extended

    /** If filter is extended (e.g. tags) get extended data */
    if (newFilter.extended) {
      const request = getExtendedDataRequest(newFilter, settings)
      extended = await this.props.getExtendedData(request, data)
      if (extended.status !== 'error') data = extended
      else return extended // If extended data is not available
    }

    newFilter = populateFilterOptions(data, newFilter, this.props.lists)

    newFilter.modifiers = {
      not: false,
    }

    if (
      newFilter.type === 'string' &&
      newFilter.options &&
      newFilter.options.length > 2
    ) {
      newFilter.modifiers = {
        not: false,
        and: false,
      }
    }
    return newFilter
  }

  onSubmitAddFilter = async filterName => {
    const { filters } = this.state
    const newFilter = await this.buildFilter(filterName)
    if (newFilter.status === 'error') {
      this.props.snackbarNotify(
        `${newFilter.status.toUpperCase()}: ${newFilter.message}`
      )
      this.setState({
        addFilterForm: false,
      })
      return false
    }
    this.setState({
      filters: filters.concat([newFilter]),
      addFilterForm: false,
    })
  }

  // TODO: This code seems to be largely duplicated across this class and
  // app/frontend/containers/Areas/AreaFilters.js
  // Pull out code to a common place to reduce duplication
  getFilter = filter => {
    let filterType = filter.type
    if (filter.type === 'tag') filterType = 'string'
    if (filter.options && filter.options.length <= 2 && filterType !== 'geo')
      filterType = 'boolean'
    if (filter.extended === 'list') filterType = 'list'
    switch (filterType) {
      case 'string':
        return <FilterSelect filter={filter} onChange={this.filterUpdated} />
      case 'boolean':
        return <FilterSwitch filter={filter} onChange={this.filterUpdated} />
      case 'geo':
        return <FilterGeo title={filter.label} />
      case 'number':
      case 'percentage':
        const itemValues = this.props.data
          .map(item => getItemValues(item, filter.path))
          .flat()
        return (
          <FilterNumeric
            filter={filter}
            onChange={this.filterUpdated}
            itemValues={itemValues}
          />
        )
      case 'list':
        return (
          <FilterByList
            lists={this.props.lists}
            filter={filter}
            onChange={this.filterUpdated}
          />
        )
    }
  }

  moveFilterUp = filter => {
    const { filters } = this.state
    const idx = filters.findIndex(f => f.id === filter.id)
    if (idx - 1 >= 0) {
      const newFilters = filters
        .map((f, i) => {
          if (i === idx - 1) return filters[idx]
          else if (i === idx) return filters[idx - 1]
          else return f
        })
        .map(f => {
          f.selected = []
          return f
        })
        .flat()
      this.setState({ filters: newFilters })
    }
  }

  deleteFilter = filter => {
    const { filters } = this.state
    const newFilters = filters.filter(f => f.id !== filter.id)
    this.setState({ filters: newFilters })
    this.props.onFiltersUpdated(newFilters)
  }

  filterUpdated = update => {
    const filters = updateFilter(clone(this.state.filters), update)
    this.setState({ filters })
    this.props.onFiltersUpdated(filters)
    updateUrlParams(filters)
  }

  render() {
    const { classes, settings, data, tagCategories } = this.props
    const { addFilterForm, filters, loading, availableFilters } = this.state
    const { getFilter } = this
    if (loading) return <Loading visible />
    if (!data || !tagCategories || !settings || !availableFilters) return null
    // TODO: break out behaviour of above to different error messages or non-display of items (e.g. no tag categories isn't necessarily an error)

    return (
      <div>
        <AddFilterForm
          open={addFilterForm}
          onSubmit={this.onSubmitAddFilter}
          onClickClose={() => this.setState({ addFilterForm: false })}
          filters={availableFilters}
          selectedFilters={filters}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          className={classes.root}
          id={'filters'}
        >
          {filters.map((filter, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
              <FilterContainer
                filter={filter}
                onClickMoveUp={this.moveFilterUp}
                onClickDelete={this.deleteFilter}
                index={idx}
              >
                {getFilter(filter)}
              </FilterContainer>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ButtonBase
              className={classes.addNewFilter}
              onClick={() => this.setState({ addFilterForm: true })}
            >
              <Typography variant="h5" style={{ color: grey[500] }}>
                + Add a new filter
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SearchFilters.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  onFiltersUpdated: PropTypes.func,
  settings: PropTypes.object,
  search_group: PropTypes.string,
  tagCategories: PropTypes.array,
  getExtendedData: PropTypes.func,
  snackbarNotify: PropTypes.func,
  areaDataTypes: PropTypes.array,
  lists: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchFilters))
