import { flatten, flattenDeep, snakeCase } from 'lodash'

export const getAvailableAreaFilters = ({
  settings,
  tagCategories,
  areaDataTypes,
  areaClassification,
}) => {
  let available = []

  /** AREA DATA FILTERS */
  const isDataAvailableForClassification = !!flattenDeep(
    areaDataTypes.map(adt =>
      adt['area_data_sources'].map(ads =>
        ads['area_classifications'].filter(
          ac => ac['area_class_name'] === areaClassification.name
        )
      )
    )
  ).length

  if (isDataAvailableForClassification) {
    const availableAreaData = flattenDeep(
      areaDataTypes.map(adt => {
        return adt['area_data_sources'].map(ads => {
          return ads['area_classifications']
            .filter(ac => ac['area_class_name'] === areaClassification.name)[0]
            ['area_data_qualifiers'].map(adq => {
              const res = {
                ...adt,
                source: ads['source_url'],
                source_id: ads['id'],
                qualifier: adq['qualifier_name'],
                group: {
                  name: adt.name,
                  label: `Area Data | ${adt.description}`,
                },
              }
              delete res['area_data_sources']
              return res
            })
        })
      })
    )

    const areaDataFilters = flatten(
      availableAreaData.map(ad => {
        return {
          name: `area-data_${ad.name}_${snakeCase(ad.qualifier)}`,
          label: `Area Data | ${ad.qualifier} [source: ${ad.source}]`,
          path: {
            value_path: `${settings['area_data'].path}.${ad.name}.${snakeCase(
              ad.qualifier
            )}.value`,
          },
          type: ad.data_type,
          extended: 'area_data',
          area_classification: areaClassification,
          area_data: {
            id: ad.id,
            source_id: ad.source_id,
            qualifier: ad.qualifier,
          },
          area_data_id: ad.id,
          area_data_source_id: ad.source_id,
          area_data_qualifier: ad.qualifier,
          group: ad.group,
        }
      })
    )

    available = available.concat(areaDataFilters)
  }

  /** LIST FILTER */
  const inListFilter = {
    name: 'in_list',
    label: 'In list',
    path: {
      objects_array_path: 'in_list',
      each_value_path: 'id',
    },
    type: 'id',
    search_type: 'Area',
    extended: 'list',
  }
  available = available.concat([inListFilter])

  /** TAG FILTERS */
  const tagFilters = flatten(
    tagCategories
      .filter(tc => tc.options.validate_taggable_types.includes('Area'))
      .map(tagCategory =>
        tagCategory.options.validate_taggable_types.map(taggable => ({
          name: `tag-${taggable.toLowerCase()}_${tagCategory.name}`,
          label: `Tag | ${taggable} - ${tagCategory.name}`,
          path: {
            value_path: `${settings.tags.path}.${tagCategory.name}.value`,
          },
          type: tagCategory.options.validate_tag_values.is_numeric
            ? 'number'
            : 'string',
          tag_name: tagCategory.name,
          taggable_type: taggable,
          extended: 'tag',
        }))
      )
  )
  return available.concat(tagFilters)
}
