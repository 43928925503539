import {
  AREA_CLASSIFICATIONS_FETCHED,
  AREA_DATA_TYPES_FETCHED,
  AREA_INFO_FETCHED,
  AREA_TAGS_FETCHED,
  AREA_DATA_FETCHED,
  AREA_GEO_FETCHED,
  SUBAREAS_FETCHED,
  SUBAREAS_GEO_FETCHED,
} from '../actionTypes'

const geoState = {
  areaClassifications: null,
}

export default (state = geoState, action) => {
  // noinspection JSRedundantSwitchStatement
  switch (action.type) {
    case AREA_CLASSIFICATIONS_FETCHED:
      return {
        ...state,
        areaClassifications: action.areaClassifications,
      }
    case AREA_DATA_TYPES_FETCHED:
      return {
        ...state,
        areaDataTypes: action.areaDataTypes,
      }
    case AREA_INFO_FETCHED:
      return {
        ...state,
        areaInfo: action.areaInfo,
      }
    case AREA_TAGS_FETCHED:
      return {
        ...state,
        areaTags: action.areaTags,
      }
    case AREA_DATA_FETCHED:
      return {
        ...state,
        areaData: action.areaData,
      }
    case AREA_GEO_FETCHED:
      return {
        ...state,
        areaGeo: action.areaGeo,
      }
    case SUBAREAS_FETCHED:
      return {
        ...state,
        subareas: action.subareas,
      }
    case SUBAREAS_GEO_FETCHED:
      return {
        ...state,
        subareasGeo: action.subareasGeo,
      }
    default:
      return state
  }
}
