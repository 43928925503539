import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import FormControl from '@material-ui/core/FormControl/FormControl'
import TextField from '@material-ui/core/TextField/TextField'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from '@material-ui/core/Button/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import pluralize from 'pluralize'
import { uniq } from 'lodash'
import styles from './styles'
import { pushToList } from './index.actions'
import { titleCase } from 'helpers/index'

class PushToListForm extends Component {
  constructor(props) {
    super(props)
    const { data, excluded, blocks } = this.getIds(false)
    this.state = {
      list: {
        name: '',
        group: this.props.group,
        listable_type: this.props.itemType,
        ids: data,
      },
      valid: false,
      blocks,
      includeBlocked: false,
      excluded,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.items.length !== prevProps.items.length ||
      (!!prevProps.merge &&
        !!this.props.merge &&
        this.props.merge.length !== prevProps.merge.length)
    ) {
      const { includeBlocked } = this.state
      const { data, excluded, blocks } = this.getIds(includeBlocked)
      this.setState({
        list: {
          ...this.state.list,
          ids: data,
        },
        excluded,
        blocks,
      })
    }
  }

  getIds = includeBlocked => {
    const blocks = this.props.items.filter(d => d.contact_block).length > 0
    const excluded = this.props.items.filter(d =>
      includeBlocked ? false : d.contact_block
    ).length

    console.log(this.props.items)
    console.log(this.props.itemType)
    console.log(this.props.itemIdPath)

    return {
      data: this.props.items
        .filter(d => (includeBlocked ? true : !d.contact_block))
        .map(d => d[this.props.itemIdPath]),
      excluded,
      blocks,
    }
  }

  onUpdate = event => {
    const { list } = this.state
    list.name = event.target.value
    this.setState({
      list,
      valid: this.validate(list),
    })
  }

  validate = list => {
    return list.name
  }

  toggleIncludeBlocked = () => {
    const { includeBlocked } = this.state
    const { data, excluded, blocks } = this.getIds(!includeBlocked)
    this.setState({
      list: {
        ...this.state.list,
        ids: data,
      },
      includeBlocked: !includeBlocked,
      excluded,
      blocks,
    })
  }

  onSubmit = event => {
    event.preventDefault()
    const { list } = this.state
    const { merge } = this.props
    if (merge) list.ids = uniq(list.ids.concat(merge))
    list.name = `${list.name} ${
      this.props.dataTitle ? `(${this.props.dataTitle})` : ''
    }`
    pushToList(list).then(res => {
      if (res.status !== 201) alert('Something went wrong...')
      if (this.props.goToNewList) {
        window.location = `/lists`
      } else {
        this.props.onSubmit()
      }
    })
  }

  render() {
    const { classes, merge, itemType } = this.props
    const { list, valid, excluded, includeBlocked, blocks } = this.state
    let pushSize = list.ids.length
    if (merge) pushSize = uniq(list.ids.concat(merge)).length

    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClickClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Push search to list</DialogTitle>
          <DialogContent className={classes.form}>
            <Typography
              variant={'body1'}
              className={classes.infoText}
              gutterBottom={!blocks}
            >{`${pushSize} ${itemType} will be added to this new list`}</Typography>
            {blocks && (
              <Typography
                variant={'caption'}
                gutterBottom
                classes={{ root: classes.contactBlockedText }}
              >
                {excluded > 0
                  ? `${excluded} excluded due to contact blocks`
                  : ''}
              </Typography>
            )}
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                autoFocus
                autoComplete="off"
                value={list.name}
                label="Name"
                type="text"
                onChange={this.onUpdate}
                inputProps={{
                  name: 'name',
                  id: 'list-name',
                }}
                fullWidth
              />
            </FormControl>
            {blocks && (
              <FormControl fullWidth className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeBlocked}
                        value={'includeBlocked'}
                        inputProps={{
                          name: 'includeBlocked',
                          id: 'includeBlocked',
                        }}
                        onChange={this.toggleIncludeBlocked}
                      />
                    }
                    label="Include blocked contacts"
                  />

                  <Typography variant={'caption'} gutterBottom>
                    You might want to do this if e.g. the list is being used to
                    contact constituents of this person rather than the person
                    themselves
                  </Typography>
                </FormGroup>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClickClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onSubmit} color="primary" disabled={!valid}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

PushToListForm.propTypes = {
  onClickClose: PropTypes.func,
  open: PropTypes.bool,
  items: PropTypes.array,
  itemType: PropTypes.string,
  itemIdPath: PropTypes.string,
  classes: PropTypes.object,
  dataTitle: PropTypes.string,
  group: PropTypes.string,
  goToNewList: PropTypes.bool,
  onSubmit: PropTypes.func,
  merge: PropTypes.array,
}

export default withStyles(styles)(PushToListForm)
