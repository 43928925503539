import { get, flatten, findIndex } from 'lodash'

export const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

export const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

/**
 * Builds configuration objects for table columns
 * @param args { dataType, settings, search_group, tagCategories, extras }
 * @returns {{available: *, selected: *}}
 */
export const getTableColumns = args => {
  const dataType = args.dataType
  const { settings, search_group, tagCategories, extras } = args

  console.log('DataTable index.helpers')
  console.log(dataType)
  console.log(search_group)

  /** Get available table columns from settings */
  const cAvailable = settings.tables[dataType].columns_available
  const cInitial = settings.tables[dataType].columns_default

  let available = cAvailable.common
  let initial = cInitial.common

  //if (['Person', 'Membership', 'ElectionCandidate'].includes(dataType)) {
  if (!!search_group) {
    let groupType = settings.search.group_config[search_group].group_type
    available = available
      .concat(cAvailable[groupType] || [])
      .concat(cAvailable[search_group] || [])
    initial = initial
      .concat(cInitial[groupType] || [])
      .concat(cInitial[search_group] || [])
  }

  console.log(available)
  console.log(initial)

  /** Set selected initial columns */
  let selected = []
  initial.forEach(i => {
    selected = selected.concat(available.filter(a => a.name === i))
  })

  /** Get available colums from tags */
  const tagColumns = flatten(
    tagCategories.map(tagCategory =>
      tagCategory.options.validate_taggable_types.map(taggable => ({
        name: `tag-${taggable.toLowerCase()}_${tagCategory.name}`,
        label: `Tag | ${taggable} - ${tagCategory.name}`,
        type: tagCategory.options.validate_tag_values.is_numeric
          ? 'number'
          : 'string',
        path: `${
          dataType === 'Area'
            ? settings.areas.tags.path
            : settings.search.filters.tags[taggable].path
        }.${tagCategory.name}.value`,
        extended: {
          taggable_type: taggable,
          tag_name: tagCategory.name,
        },
      }))
    )
  )
  available = available.concat(tagColumns)

  /** Add extra columns */
  if (extras) {
    const extraColumns = extras
      .filter(extra => findIndex(available, { name: extra.name }) < 0)
      .map(({ name, label, path, type }) => ({
        name,
        label,
        path,
        type,
      }))
      .filter(extra => !!extra.path)
    available = available.concat(extraColumns)
  }

  return { available, selected }
}

export const flattenData = (items, columns) => {
  return items.map(item => {
    let flattened = {}
    columns.forEach(column => {
      let value = get(item, column.path)
      if (
        !!value &&
        ['number', 'percentage'].includes(column.type) &&
        typeof value === 'string'
      )
        value = Number(value)
      flattened[column.name] = value
    })
    return flattened
  })
}
