import React from 'react'
import * as PropTypes from 'prop-types'
import { flatten, upperFirst } from 'lodash'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import ContactCard from './ContactCard'

import styles from './styles'
import {
  getContactTypes,
  addNewContact,
  getContactPurposeTypes,
} from './index.actions'
import AddContactForm from '../AddContactForm'

class PersonContacts extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contactFormOpen: false,
      person: this.props.person,
    }
  }

  componentDidMount() {
    getContactPurposeTypes().then(res => {
      this.setState({ contactPurposeTypes: res.data })
    })
    getContactTypes().then(res => {
      this.setState({ contactTypes: res.data })
    })
  }

  openAddContactForm = contactable => e => {
    e.preventDefault()
    this.setState({
      contactFormOpen: true,
      contactable,
    })
  }

  onCloseAddContactForm = () => {
    this.setState({ contactFormOpen: false })
  }

  onSubmitAddContact = contact => e => {
    e.preventDefault()
    addNewContact(contact).then(() => {
      this.setState({
        contactFormOpen: false,
        contactable: null,
      })
      window.location.reload()
    })
  }

  removeCard = (id, type, contactable_type, membership_id) => () => {
    if (contactable_type === 'Membership') {
      const newMemberships = this.state.person.memberships.map(m => {
        if (m.id === membership_id) {
          m.contacts[type] = m.contacts[type].filter(c => c.id !== id)
        }
        return m
      })
      this.setState({
        person: {
          ...this.state.person,
          memberships: newMemberships,
        },
      })
    } else {
      const newContacts = this.state.person.contacts
      newContacts[type] = newContacts[type].filter(c => c.id !== id)
      this.setState({
        person: {
          ...this.state.person,
          contacts: newContacts,
        },
      })
    }
  }

  render() {
    const { classes } = this.props
    const { person, contactable } = this.state

    if (
      !(this.state && this.state.contactTypes && this.state.contactPurposeTypes)
    ) {
      return null
    }
    return (
      <Grid container spacing={5}>
        {contactable && (
          <AddContactForm
            onClickClose={this.onCloseAddContactForm}
            onSubmit={this.onSubmitAddContact}
            open={this.state.contactFormOpen}
            contactable={this.state.contactable}
            contactTypes={this.state.contactTypes || []}
            contactPurposeTypes={this.state.contactPurposeTypes || []}
          />
        )}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Personal Contacts
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={this.openAddContactForm({
                type: 'Person',
                id: person.id,
              })}
            >
              <AddIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              New
            </Button>
          </Typography>
          <Grid container spacing={3}>
            {person.hasOwnProperty('contacts') &&
              flatten(Object.values(person.contacts)).map(c => (
                <Grid item key={c.id} xs={12} sm={6} md={4} lg={3}>
                  <ContactCard
                    contact={c}
                    person={this.props.person}
                    contactable={{ id: person.id, type: 'Person' }}
                    contactTypes={this.state.contactTypes}
                    contactPurposeTypes={this.state.contactPurposeTypes}
                    onDeleted={this.removeCard(c.id, c.contact_type, 'Person')}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {person.memberships.map(m => (
          <Grid item xs={12} key={m.identifier}>
            <Typography variant="h5" gutterBottom>
              {`${upperFirst(m.role.name)} -
                  ${m.organization.name}
                  (${m.organization.organization_classification})
                `}
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={this.openAddContactForm({
                  type: 'Membership',
                  id: m.id,
                })}
              >
                <AddIcon
                  className={classNames(classes.leftIcon, classes.iconSmall)}
                />
                New
              </Button>
            </Typography>
            <Grid container spacing={3}>
              {m.hasOwnProperty('contacts') &&
                flatten(Object.values(m.contacts)).map(c => (
                  <Grid item key={c.id} xs={12} sm={6} md={4} lg={3}>
                    <ContactCard
                      contact={c}
                      person={this.props.person}
                      contactable={{ id: m.id, type: 'Membership' }}
                      contactTypes={this.state.contactTypes}
                      contactPurposeTypes={this.state.contactPurposeTypes}
                      onDeleted={this.removeCard(
                        c.id,
                        c.contact_type,
                        'Membership',
                        m.id
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }
}

PersonContacts.propTypes = {
  classes: PropTypes.object,
  person: PropTypes.object,
}

export default withStyles(styles)(PersonContacts)
