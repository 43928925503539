export const STATUS_UPDATE = 'STATUS_UPDATE'
export const ROUTE_CHANGED = 'ROUTE_CHANGED'
export const SEARCH_DATA_LOADED = 'SEARCH_DATA_LOADED'
export const SEARCH_DATA_LOADING = 'SEARCH_DATA_LOADING'
export const SEARCH_DATA_UPDATED = 'SEARCH_DATA_UPDATED'
export const SETTINGS_UPDATE = 'UPDATE_SETTINGS'
export const TAG_CATEGORIES_LOADED = 'TAG_CATEGORIES_LOADED'
export const SNACKBAR_OPEN = 'SNACKBAR_OPEN'
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE'
export const PERSON_FETCHED = 'PERSON_FETCHED'
export const PERSON_UPDATED = 'PERSON_UPDATED'
export const AREA_CLASSIFICATIONS_FETCHED = 'AREA_CLASSIFICATIONS_FETCHED'
export const AREAS_FETCHED = 'AREAS_FETCHED'
export const AREAS_CLEARED = 'AREAS_CLEARED'
export const AREAS_PUSHED_TO_LIST = 'AREAS_PUSHED_TO_LIST'
export const LISTS_FETCHED = 'LISTS_FETCHED'
export const AREA_DATA_TYPES_FETCHED = 'AREA_DATA_TYPES_FETCHED'
export const AREA_INFO_FETCHED = 'AREA_INFO_FETCHED'
export const AREA_TAGS_FETCHED = 'AREA_TAGS_FETCHED'
export const AREA_DATA_FETCHED = 'AREA_DATA_FETCHED'
export const AREA_GEO_FETCHED = 'AREA_GEO_FETCHED'
export const SUBAREAS_FETCHED = 'SUBAREAS_FETCHED'
export const SUBAREAS_GEO_FETCHED = 'SUBAREAS_GEO_FETCHED'
