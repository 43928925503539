import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Typography from '@material-ui/core/Typography/Typography'
import HowToReg from '@material-ui/icons/HowToReg'
import DownloadIcon from '@material-ui/icons/GetApp'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import grey from '@material-ui/core/colors/grey'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { CSVLink } from 'react-csv'
import json2csv from 'json2csv'
import MultiSelect from 'components/MultiSelect'
import pluralize from 'pluralize'

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  searchBar: {
    backgroundColor: grey[50],
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  selectedIcon: {
    marginRight: 10,
    color: theme.palette.secondary.main,
  },
  button: {
    fontWeight: '600',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
})

class EnhancedTableToolbar extends React.Component {
  state = {
    searchText: '',
    csv: null,
    mergeLists: null,
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!!prevState.csv && !!this.state.csv) this.setState({ csv: null })
  }

  onUpdateTextSearch = event => {
    const searchText = event.target.value
    this.props.onUpdateSearchText(searchText)
    this.setState({ searchText })
  }

  onClear = () => {
    this.props.onUpdateSearchText('')
    this.setState({ searchText: '' })
  }

  onClickGenerateCsv = () => {
    // TODO: get data from merge lists
    const csv = json2csv.parse(this.props.items)
    this.setState({ csv })
  }

  onSelectMergeList = mergeLists => {
    this.setState({ mergeLists })
    this.props.onUpdateMergeLists(mergeLists)
  }

  render() {
    const {
      numSelected,
      classes,
      onClickPushToList,
      items,
      controls,
      lists,
      dataType,
    } = this.props
    const { searchText, csv } = this.state

    return (
      <div>
        <Toolbar
          className={classNames(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          <div className={classes.title}>
            {numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {<HowToReg fontSize="large" className={classes.selectedIcon} />}
                {numSelected} selected
              </Typography>
            ) : (
              <Typography variant="h6" id="tableTitle">
                {dataType}
              </Typography>
            )}
          </div>
          <Grid
            container
            direction={'row'}
            justifyContent={'flex-end'}
            className={classes.actions}
            spacing={1}
          >
            <Grid
              item
              style={{
                borderRight: numSelected > 0 ? '1px solid #3f51b5' : 'none',
                paddingRight: 13,
                marginRight: 17,
              }}
            >
              <Button
                color="primary"
                className={classes.button}
                onClick={this.props.onToggleColumnsSelect(true)}
              >
                Select Columns
              </Button>
            </Grid>
            {numSelected > 0 && controls.includes('merge') && !!lists.length && (
              <Grid item style={{ minWidth: 250 }}>
                <MultiSelect
                  items={lists
                    .filter(
                      list =>
                        list.listable_type === pluralize.singular(dataType) ||
                        (pluralize.singular(dataType) === 'Person' &&
                          list.listable_type === 'Membership')
                    )
                    .map(list => ({
                      value: list.id,
                      label: list.name,
                    }))}
                  onUpdate={this.onSelectMergeList}
                />
              </Grid>
            )}
            {numSelected > 0 && controls.includes('push') && (
              <Grid item>
                <Button
                  color="secondary"
                  className={classes.button}
                  onClick={onClickPushToList}
                >
                  Push to List
                </Button>
              </Grid>
            )}
            {numSelected > 0 && controls.includes('export') && (
              <Grid item>
                {!csv && (
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={this.onClickGenerateCsv}
                  >
                    Generate CSV
                  </Button>
                )}
                {csv && (
                  <CSVLink
                    data={csv}
                    filename={'download.csv'}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button color="secondary" className={classes.button}>
                      <DownloadIcon
                        style={{ marginRight: 5 }}
                        fontSize={'small'}
                      />
                      Download CSV
                    </Button>
                  </CSVLink>
                )}
              </Grid>
            )}
          </Grid>
        </Toolbar>
        {!!items.length && (
          <Toolbar className={classNames(classes.searchBar)}>
            <TextField
              label={null}
              placeholder="Search within table data"
              id="text-search"
              value={searchText}
              disabled={null}
              onChange={this.onUpdateTextSearch}
              className={classes['textField']}
              InputProps={{
                endAdornment: searchText.length > 0 && (
                  <Button onClick={this.onClear}>clear</Button>
                ),
              }}
            />
          </Toolbar>
        )}
      </div>
    )
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  onClickPushToList: PropTypes.func,
  onUpdateSearchText: PropTypes.func,
  onToggleColumnsSelect: PropTypes.func,
  items: PropTypes.array,
  controls: PropTypes.array,
  lists: PropTypes.array,
  dataType: PropTypes.string,
  onUpdateMergeLists: PropTypes.func,
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar)
