import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  statsContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  stats: {
    paddingTop: theme.spacing(2),
  },
  stat: {
    marginRight: theme.spacing(5),
  },
  progress: {
    marginLeft: 10,
  },
  loading: {
    textAlign: 'center',
    marginTop: theme.spacing(10),
  },
  container: {
    color: 'black',
    backgroundColor: grey[100],
  },
  search: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '400px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
})

export default styles
