import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditOutlined'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Divider from '@material-ui/core/Divider'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import ImageIcon from '@material-ui/icons/ThumbsUpDownOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CreateIcon from '@material-ui/icons/AddBoxOutlined'

class PersonRelationship extends Component {
  render() {
    const { person, formToggle } = this.props

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Relationship
          <IconButton
            color="primary"
            aria-label="Edit relationship"
            style={{
              padding: 8,
              marginBottom: 4,
              marginLeft: 3,
            }}
            onClick={formToggle('editRelationship', true)}
          >
            {person.person_relationship ? <EditIcon /> : <CreateIcon />}
          </IconButton>
        </Typography>

        <Grid container>
          <Grid item xs={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <List style={{ padding: 0, marginRight: 30 }}>
                  <ListItem style={{ paddingLeft: 0 }}>
                    <Avatar style={{ marginRight: 20 }}>
                      <ImageIcon color="primary" />
                    </Avatar>
                    <ListItemText
                      primary={
                        (person.person_relationship &&
                          person.person_relationship.relationship_status) ||
                        'Undefined'
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="caption"
                            color="textSecondary"
                          >
                            {(person.person_relationship &&
                              person.person_relationship.user_email) ||
                              'No one'}
                          </Typography>
                          {person.person_relationship && (
                            <Typography
                              component="span"
                              variant="caption"
                              color="textSecondary"
                            >
                              {moment(
                                person.person_relationship.updated_at
                              ).fromNow()}
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </AccordionSummary>
              {person.relationship_logs && (
                <AccordionDetails>
                  <List style={{ padding: 0, marginLeft: -10 }}>
                    {person.relationship_logs
                      .sort((a, b) =>
                        moment(a.occurred_at).isBefore(b.occurred_at) ? 1 : -1
                      )
                      .map(log => (
                        <div key={log.id}>
                          <ListItem
                            onClick={formToggle('relationshipLog', true, log)}
                            button={true}
                            style={{ paddingLeft: 10 }}
                          >
                            <ListItemText
                              primary={log.description}
                              secondary={
                                moment(log.occurred_at).fromNow() +
                                '  •  ' +
                                log.user
                              }
                            />
                          </ListItem>
                          <Divider
                            style={{ marginBottom: 5, marginLeft: 10 }}
                          />
                        </div>
                      ))}
                  </List>
                </AccordionDetails>
              )}
              <AccordionActions>
                <Button
                  size="small"
                  color="secondary"
                  style={{ marginLeft: -8 }}
                  onClick={formToggle('relationshipLog', true)}
                >
                  Add a new log entry
                </Button>
              </AccordionActions>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    )
  }
}

PersonRelationship.propTypes = {
  person: PropTypes.object,
  formToggle: PropTypes.func,
}

export default PersonRelationship
