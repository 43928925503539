export default theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2),
  },
  draftContainer: {
    margin: '20px 0',
  },
  friendlyDiff: {
    paddingTop: '4px',
    paddingBottom: '4px',
    '&:last-child': {
      paddingBottom: '4px',
    },
  },
  friendlyDiffTitle: {
    display: 'inline-block',
    verticalAlign: 'baseline',
    fontWeight: 'bold',
  },
  chipNew: {
    backgroundColor: '#adebad',
  },
  chipUpdated: {
    backgroundColor: '#99ccff',
  },
  chipDeleted: {
    backgroundColor: '#ff9999',
  },
  attributeName: {
    textAlign: 'right',
  },
  attributeOld: {
    '& > span': {
      backgroundColor: '#ffcccc',
      textDecorationLine: 'line-through',
    },
  },
  attributeNew: {
    '& > span': {
      backgroundColor: '#d6f5d6',
    },
  },
  associationContainer: {
    marginBottom: '16px',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 24,
  },
  button: {
    margin: theme.spacing(1),
  },
})
