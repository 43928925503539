import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Badge from '@material-ui/core/Badge'
import { upperFirst, lowerCase } from 'lodash'

import { getData } from './index.actions'
import styles from './styles'
import Loading from 'components/Loading'

class Home extends Component {
  state = {
    memberships: null,
    searchText: '',
    searchMin: 2,
    loading: true,
    stats: null,
  }

  componentDidMount() {
    getData().then(stats => {
      this.setState({ stats, loading: false })
    })
  }

  render() {
    const { classes } = this.props
    const { stats, loading } = this.state

    if (loading) {
      return <Loading visible />
    }

    const counts = Object.entries(stats.counts).map(entry => ({
      label: upperFirst(lowerCase(entry[0])),
      value: entry[1],
    }))

    return (
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} className={classes.statsContainer}>
          <Typography variant="h4" color="inherit" gutterBottom>
            Database Stats
          </Typography>
          <Divider className={classes.divider} />
          {!counts ? (
            <Typography variant="h5" color="inherit" gutterBottom>
              Loading...
              <CircularProgress
                size={27}
                thickness={6}
                className={classes.progress}
              />
            </Typography>
          ) : (
            <div className={classes.stats} id="stats-container">
              {counts.map(count => (
                <Badge
                  key={count.label}
                  color="primary"
                  badgeContent={count.value.toLocaleString()}
                  className={classes.stat}
                  max={100000000}
                  showZero
                >
                  <Typography variant="h6">{count.label}</Typography>
                </Badge>
              ))}
            </div>
          )}
        </Grid>
      </Grid>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Home)
