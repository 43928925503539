import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import Countup from 'react-countup'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DataTable from 'components/DataTable'
import TextSearch from './TextSearch'
import Loading from 'components/Loading'
import SearchFilters from './SearchFilters'
import styles from './styles'
import {
  loadSearchData,
  loadTagCategories,
  fetchLists,
} from 'state/actions/search'
import { fetchAreaDataTypes } from 'state/actions/geo'
import { filterByTextSearch } from './index.helpers'
import { filterItems } from 'components/Filters/index.helpers'

function mapStateToProps(state) {
  return {
    settings: state.app.settings,
    searchData: state.search.data,
    searchLoading: state.search.loading,
    tagCategories: state.search.tagCategories,
    areaDataTypes: state.geo.areaDataTypes,
    lists: state.cache.lists,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSearchData, loadTagCategories, fetchAreaDataTypes, fetchLists },
    dispatch
  )
}

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: { status: false, msg: '' },
      textSearch: '',
      activeFilters: [],
      filteredData: null,
    }
  }

  componentDidMount() {
    const { settings } = this.props
    const search_group = location.pathname.split('/').splice(-1)
    const group_config = settings.search.group_config[search_group]

    /** Load basic data into store */
    this.props.loadTagCategories()
    this.props.fetchAreaDataTypes()
    this.props.fetchLists()
    this.props.loadSearchData(search_group, group_config).then(searchData =>
      this.setState({
        filteredData: searchData,
      })
    )
  }

  onTextSearchUpdate = textSearch => {
    const newState = { textSearch }
    this.setState(newState)
  }

  onFiltersUpdated = filters => {
    const items = this.props.searchData
    const output = filterItems(filters, items)
    this.setState({ filteredData: output, activeFilters: filters })
  }

  render() {
    const search_group = location.pathname.split('/').splice(-1)[0]
    const { classes, settings, searchData, lists } = this.props
    const { tagCategories, searchLoading, areaDataTypes } = this.props
    const { error, textSearch, filteredData, activeFilters } = this.state
    if (
      searchLoading ||
      !settings ||
      !searchData ||
      !filteredData ||
      !tagCategories ||
      !areaDataTypes ||
      !lists
    ) {
      return <Loading visible error={error.status} errorMsg={error.msg} />
    }
    const dataTitle = settings.search.group_config[search_group].title
    const groupType = settings.search.group_config[search_group].group_type
    const dataType = settings.search.group_types[groupType].base_data_type
    const itemIdPath = settings.search.group_types[groupType].item_id_path

    /** Text search */
    let searchResults
    if (textSearch.length > 1) {
      searchResults = filterByTextSearch(filteredData, textSearch)
    } else searchResults = filteredData

    return (
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} className={classes.container}>
          <div className={classes.content}>
            {/** Text search */}
            <Grid container className={classes.search} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextSearch
                  mega
                  placeholder={'start typing...'}
                  onUpdate={this.onTextSearchUpdate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ height: '100%' }}
                >
                  <Grid item className={classes.countup}>
                    <Countup end={searchResults.length} duration={1} />
                  </Grid>
                  <Grid item className={classes.countupText}>
                    <Typography variant="button">Matches Found</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/** Data filters */}
            <SearchFilters
              data={searchData}
              onFiltersUpdated={this.onFiltersUpdated}
              settings={settings.search}
              search_group={search_group}
              tagCategories={tagCategories}
              areaDataTypes={areaDataTypes}
              lists={lists}
            />

            {/** Data table */}
            <Grid container spacing={2} className={classes.root} id={'table'}>
              <Grid item xs={12}>
                <DataTable
                  dataTitle={dataTitle}
                  data={searchResults}
                  dataType={dataType}
                  groupType={groupType}
                  itemIdPath={itemIdPath}
                  controls={['export', 'push', 'merge']}
                  settings={settings}
                  search_group={search_group}
                  tagCategories={tagCategories}
                  extraColumns={activeFilters.map(filter => ({
                    ...(({ name, label, type }) => ({ name, label, type }))(
                      filter
                    ),
                    path: filter.path.value_path,
                  }))}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    )
  }
}

Search.propTypes = {
  classes: PropTypes.object,
  search_group: PropTypes.string,
  settings: PropTypes.object,
  loadSearchData: PropTypes.func,
  loadTagCategories: PropTypes.func,
  searchData: PropTypes.array,
  searchLoading: PropTypes.bool,
  tagCategories: PropTypes.array,
  fetchAreaDataTypes: PropTypes.func,
  areaDataTypes: PropTypes.array,
  lists: PropTypes.array,
  fetchLists: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Search))
