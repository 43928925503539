export const formatAreaData = areaData => {
  // groups the area data by area_data_source_id and area_data_id
  const groupedData = {}

  areaData.forEach(datum => {
    if (!(datum.area_data_id in groupedData)) {
      groupedData[datum.area_data_id] = {
        description: datum.area_data_description,
      }
    }
    if (!(datum.area_data_source_id in groupedData[datum.area_data_id])) {
      groupedData[datum.area_data_id][datum.area_data_source_id] = {
        url: datum.area_data_source_url,
        date: datum.area_data_source_date,
        data: [],
      }
    }
    groupedData[datum.area_data_id][datum.area_data_source_id]['data'].push({
      qualifier: datum.qualifier,
      value: datum.value,
    })
  })
  // jsonify so you can .map later
  const formattedData = []

  for (const [dataKey, dataItem] of Object.entries(groupedData)) {
    const formattedAreaData = {
      id: dataKey,
      description: dataItem.description,
      sources: [],
    }
    let formattedSourceData
    for (const [sourceKey, dataSource] of Object.entries(dataItem)) {
      if (!(sourceKey === 'description')) {
        formattedSourceData = {
          id: sourceKey,
          url: dataSource.url,
          date: dataSource.date,
          data: dataSource.data,
        }
        formattedAreaData.sources.push(formattedSourceData)
      }
    }
    formattedData.push(formattedAreaData)
  }
  return formattedData
}

export const formatGeometry = areaGeo => {
  const formattedData = []
  let geom = {
    type: 'Feature',
    geometry: areaGeo.geography,
    properties: {
      area_id: areaGeo.area_id,
    },
  }
  formattedData.push(geom)
  return formattedData
}
