import React from 'react'
import * as PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import TextWithHelp from 'components/TextWithHelp'

import styles from './styles'

class UploadStepSelectCsvHeaders extends React.Component {
  constructor(props) {
    super(props)
  }

  handleAreaColumnChange = event => {
    const { statsCsvColumns, onHeaderSelectionsUpdated } = this.props

    var areaIdentifierCsvColumn = event.target.value
    onHeaderSelectionsUpdated(areaIdentifierCsvColumn, statsCsvColumns)
  }

  handleStatsColumnChange = (index, event) => {
    const {
      areaIdentifierCsvColumn,
      statsCsvColumns,
      onHeaderSelectionsUpdated,
    } = this.props

    statsCsvColumns[index]['column'] = event.target.value
    onHeaderSelectionsUpdated(areaIdentifierCsvColumn, statsCsvColumns)
  }

  handleStatsQualifierChange = (index, event) => {
    const {
      areaIdentifierCsvColumn,
      statsCsvColumns,
      onHeaderSelectionsUpdated,
    } = this.props

    statsCsvColumns[index]['qualifier'] = event.target.value
    onHeaderSelectionsUpdated(areaIdentifierCsvColumn, statsCsvColumns)
  }

  addStatsColumnField = () => {
    const {
      areaIdentifierCsvColumn,
      statsCsvColumns,
      onHeaderSelectionsUpdated,
    } = this.props
    onHeaderSelectionsUpdated(areaIdentifierCsvColumn, [
      ...statsCsvColumns,
      { column: '', qualifier: '' },
    ])
  }

  deleteStatsColumnField = index => {
    const {
      areaIdentifierCsvColumn,
      statsCsvColumns,
      onHeaderSelectionsUpdated,
    } = this.props
    const newStats = statsCsvColumns.filter((col, idx) => idx !== index)
    onHeaderSelectionsUpdated(areaIdentifierCsvColumn, newStats)
  }

  onNextButton = () => {
    this.props.onNext()
  }

  render() {
    const {
      classes,
      csvData,
      areaIdentifierCsvColumn,
      statsCsvColumns,
    } = this.props

    return (
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <TextWithHelp
              id="select-area-column-help-text"
              typographyVariant="h6"
              text="Choose the CSV column containing the Area Codes"
              helpText="For the UK these are 9 character codes starting with a letter. Eg. 'E14000639' for the 'Cities of London and Westminster' Constituency"
            />
            <TextField
              id="select-area-column"
              select
              label="Area Code Column"
              className={classes.textField}
              value={areaIdentifierCsvColumn}
              onChange={this.handleAreaColumnChange}
              margin="normal"
            >
              {csvData.meta.fields.map(fieldName => (
                <MenuItem key={fieldName} value={fieldName}>
                  {fieldName}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <TextWithHelp
                  id="select-stats-columns-help-text"
                  typographyVariant="h6"
                  text="Choose the columns containing statistics, and name them"
                  helpText="Your CSV may contain multiple related stats - such as Vote Remain % and Vote Leave % - so choose all of them and give them a name to distinguish them later"
                />
                {statsCsvColumns.map((statsColumn, index) => (
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    key={index.toString()}
                  >
                    <Grid item>
                      <TextField
                        id={`select-stats-column-${index}`}
                        select
                        label="Statistic Column"
                        className={classes.textField}
                        value={statsColumn.column}
                        onChange={this.handleStatsColumnChange.bind(
                          this,
                          index
                        )}
                        margin="normal"
                      >
                        {csvData.meta.fields.map(fieldName => (
                          <MenuItem key={fieldName} value={fieldName}>
                            {fieldName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        id={`text-stats-qualifier-${index}`}
                        label="Statistic Qualifier"
                        className={classes.textField}
                        value={statsColumn.qualifier}
                        onChange={this.handleStatsQualifierChange.bind(
                          this,
                          index
                        )}
                        margin="normal"
                      />
                    </Grid>
                    {index > 0 && (
                      <Grid
                        item
                        style={{ alignSelf: 'flex-end', marginBottom: 8 }}
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => this.deleteStatsColumnField(index)}
                          className={classes.button}
                        >
                          Delete
                          <DeleteIcon className={classes.rightIcon} />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addStatsColumnField}
                  className={classes.button}
                >
                  Add Column
                  <AddIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onNextButton}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

UploadStepSelectCsvHeaders.propTypes = {
  classes: PropTypes.object,
  csvData: PropTypes.object.isRequired,
  areaIdentifierCsvColumn: PropTypes.string,
  statsCsvColumns: PropTypes.array,
  onHeaderSelectionsUpdated: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default withStyles(styles)(UploadStepSelectCsvHeaders)
