const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    maxWidth: '350px',
    padding: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
})

export default styles
