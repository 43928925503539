import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Controls from '../Controls'

const FilterByList = ({ compact, filter, lists, onChange }) => {
  const handleChange = evt => {
    onChange({ filter: { ...filter, selected: [evt.target.value] } })
  }

  const onModifierChange = modifier => {
    onChange({ modifier, filter })
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={compact && 1}
      >
        {compact && (
          <Grid item xs={1}>
            <Typography>{filter.label}</Typography>
          </Grid>
        )}
        <Grid
          item
          xs={compact ? 5 : 12}
          style={!compact ? { padding: '5px 0 15px 0', minWidth: '100%' } : {}}
        >
          <FormControl
            style={{ minWidth: compact ? 200 : '100%', maxWidth: '100%' }}
          >
            <InputLabel htmlFor="list-name-helper">List name</InputLabel>
            <Select
              value={filter.selected[0] || ''}
              onChange={handleChange}
              input={<Input name="list-name" id="list-name-helper" />}
            >
              {lists
                .filter(list => list.listable_type === filter.search_type)
                .map(list => (
                  <MenuItem key={list.id} value={list.id}>
                    {list.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {filter.selected && !!filter.selected.length
                ? `List owner: ${
                    lists.filter(list => list.id === filter.selected[0])[0][
                      'user_email'
                    ]
                  }`
                : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        {compact && (
          <Grid item xs={3}>
            <Controls filter={filter} onModifierUpdate={onModifierChange} />
          </Grid>
        )}
      </Grid>
      {!compact && (
        <Controls filter={filter} onModifierUpdate={onModifierChange} />
      )}
    </div>
  )
}

FilterByList.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.object,
  lists: PropTypes.array,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
}

export default FilterByList
