import {
  STATUS_UPDATE,
  ROUTE_CHANGED,
  SETTINGS_UPDATE,
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
} from '../actionTypes'

const appState = {
  status: '',
  settings: null,
  snackbar: false,
  snackbarMsg: '',
}

export default (state = appState, action) => {
  switch (action.type) {
    case STATUS_UPDATE:
      return {
        ...state,
        status: action.status,
      }
    case SETTINGS_UPDATE:
      return {
        ...state,
        settings: action.settings,
      }
    case ROUTE_CHANGED:
      return state
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbar: true,
        snackbarMsg: action.snackbarMsg,
      }
    case SNACKBAR_CLOSE:
      return {
        ...state,
        snackbar: false,
      }
    default:
      return state
  }
}
