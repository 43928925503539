import http from '../../helpers/http'
import {
  AREA_CLASSIFICATIONS_FETCHED,
  AREAS_FETCHED,
  AREAS_CLEARED,
  AREA_DATA_TYPES_FETCHED,
  AREA_INFO_FETCHED,
  AREA_TAGS_FETCHED,
  AREA_DATA_FETCHED,
  SUBAREAS_FETCHED,
  AREA_GEO_FETCHED,
  SUBAREAS_GEO_FETCHED,
} from '../actionTypes'

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaClassifications = () => async dispatch => {
  const areaClassifications = (await http.get(`/area_classifications.json`))
    .data
  dispatch({
    type: AREA_CLASSIFICATIONS_FETCHED,
    areaClassifications,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreasByClassification = area_classification_id => async dispatch => {
  dispatch({ type: AREAS_CLEARED })
  const areas = (
    await http.get(`/areas_by_classification/${area_classification_id}.json`)
  ).data
  dispatch({
    type: AREAS_FETCHED,
    areas,
  })
  return areas
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreasByList = list_id => async dispatch => {
  dispatch({ type: AREAS_CLEARED })
  const areas = (await http.get(`/areas_by_list/${list_id}.json`)).data
  dispatch({
    type: AREAS_FETCHED,
    areas,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const clearAreas = () => async dispatch =>
  dispatch({ type: AREAS_CLEARED })

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaDataTypes = () => async dispatch => {
  const areaDataTypes = (await http.get(`/area_data.json`)).data
  dispatch({
    type: AREA_DATA_TYPES_FETCHED,
    areaDataTypes,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaInfo = area_id => async dispatch => {
  const areaInfo = (await http.get(`/areas/${area_id}.json`)).data
  dispatch({
    type: AREA_INFO_FETCHED,
    areaInfo,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaTags = area_id => async dispatch => {
  const areaTags = (await http.get(`/tags_by_area/${area_id}.json`)).data
  dispatch({
    type: AREA_TAGS_FETCHED,
    areaTags,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaData = area_id => async dispatch => {
  const areaData = (await http.get(`/area_data_by_area/${area_id}.json`)).data
  dispatch({
    type: AREA_DATA_FETCHED,
    areaData,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchAreaGeo = area_id => async dispatch => {
  const areaGeo = (await http.get(`/area_geography/${area_id}.json`)).data
  dispatch({
    type: AREA_GEO_FETCHED,
    areaGeo,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchSubareas = area_id => async dispatch => {
  const subareas = (await http.get(`/areas_by_parent/${area_id}.json`)).data
  dispatch({
    type: SUBAREAS_FETCHED,
    subareas,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchSubareasGeo = area_id => async dispatch => {
  const subareasGeo = (
    await http.get(`/area_geographies_by_parent/${area_id}.json`)
  ).data
  dispatch({
    type: SUBAREAS_GEO_FETCHED,
    subareasGeo,
  })
}
