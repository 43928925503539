const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '100% !important',
      margin: '0 !important',
    },
  },
  loading: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(10),
  },
  container: {
    color: 'black',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  search: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  countup: {
    fontSize: 60,
    fontFamily: 'roboto',
    paddingLeft: 10,
    paddingTop: 10,
    minWidth: '110px',
    color: theme.palette.primary.main,
  },
  countupText: {
    paddingTop: 38,
    paddingLeft: 5,
  },
})

export default styles
