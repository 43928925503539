import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import Draft from './Draft'

class Drafts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      drafts: this.props.drafts,
    }
  }
  render() {
    const { classes } = this.props
    const { drafts } = this.state

    return (
      <Grid container className={classes.root}>
        <Grid item xs={10}>
          <Typography variant="h4">Drafts</Typography>
        </Grid>

        <Grid item md={12} lg={10} xl={8}>
          {drafts.length == 0 && <p>No pending changes...</p>}

          {drafts.map((draft, index) => (
            <Draft draft={draft} key={index} />
          ))}
        </Grid>
      </Grid>
    )
  }
}

Drafts.propTypes = {
  drafts: PropTypes.array,
  classes: PropTypes.object,
}

export default withStyles(styles)(Drafts)
