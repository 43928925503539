const styles = theme => ({
  card: {
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    height: '100%',
  },
})

export default styles
