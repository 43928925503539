import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: '100% !important',
      margin: '0 !important',
    },
  },
  addNewFilter: {
    width: '100%',
    minHeight: 200,
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: 10,
    borderColor: grey[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.5,
    },
  },
})

export default styles
