import React from 'react'
import Papa from 'papaparse'
import * as PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import CsvFileViewer from 'components/CsvFileViewer'

import styles from './styles'

class CsvUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: 'FILE_SELECTION',
      csvFile: null,
      csvError: null,
      csvResults: null,
    }
  }

  hasError = csvResults => {
    return csvResults && csvResults.errors && csvResults.errors.length > 0
  }

  parseCsv = ({ target }) => {
    this.setState({
      state: 'CSV_PROCESSING',
      csvFile: null,
      csvError: null,
      csvResults: null,
    })

    var papaParseOptions = {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      error: this.onCsvParseError,
      complete: this.onCsvParsed,
    }
    Papa.parse(target.files[0], papaParseOptions)
  }

  onCsvParseError = (error, file) => {
    console.error('Parsing error:', error, file)

    this.setState({
      state: 'CSV_ERROR',
      csvFile: file,
      csvError: error,
    })
  }

  onCsvParsed = (results, file) => {
    if (!['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
      this.onCsvParseError('Incorrect file type', file)
      return
    }

    if (results.errors && results.errors.length > 0) {
      // CSV processed, but with errors
      this.setState({
        state: 'CSV_PROCESSED_WITH_ERRORS',
        csvFile: file,
        csvResults: results,
      })
    } else {
      // CSV processed with no errors
      this.setState({
        state: 'CSV_PROCESSED_SUCCESSFULLY',
        csvFile: file,
        csvResults: results,
      })
    }
  }

  onResetButton = () => {
    this.setState({
      state: 'FILE_SELECTION',
      csvFile: null,
      csvError: null,
      csvResults: null,
    })
  }

  onConfirmButton = () => {
    const { csvFile, csvResults } = this.state
    this.props.onProceedWithCsv(csvResults, csvFile)
  }

  render() {
    const { classes } = this.props
    const { state, csvFile, csvError, csvResults } = this.state

    return (
      <div>
        {state === 'FILE_SELECTION' && (
          <div>
            <Typography variant="h6" gutterBottom>
              Choose your CSV File
            </Typography>
            <Button variant="contained" component="label">
              Upload CSV
              <input
                type="file"
                accept=".csv"
                multiple={false}
                style={{ display: 'none' }}
                onChange={this.parseCsv}
              />
            </Button>
          </div>
        )}

        {state === 'CSV_PROCESSING' && (
          <CircularProgress className={classes.progress} />
        )}

        {state === 'CSV_ERROR' && (
          <div>
            <Typography variant="h6">
              {`Error processing ${csvFile.name}`}
            </Typography>
            <Typography gutterBottom>{JSON.stringify(csvError)}</Typography>
            <Typography gutterBottom>
              Please fix the error and try again
            </Typography>
            <Button variant="contained" onClick={this.onResetButton}>
              Try Again
            </Button>
          </div>
        )}

        {state === 'CSV_PROCESSED_WITH_ERRORS' && (
          <div>
            <Typography variant="h6">
              {`Processed ${csvFile.name} with errors`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              (any blank lines are skipped)
            </Typography>
            <List>
              {csvResults.errors.map((e, index) => (
                <ListItem key={index.toString()}>
                  <ListItemIcon>
                    <WarningIcon />
                  </ListItemIcon>
                  {/* Use row+2 because array is 0-based and we also have a header row */}
                  <ListItemText
                    primary={`Row #${e.row + 2} had error message "${
                      e.message
                    }".
                       Row data was ${JSON.stringify(csvResults.data[e.row])}`}
                  />
                </ListItem>
              ))}
            </List>
            <Typography gutterBottom>
              Please fix the errors and try again
            </Typography>
            <Button variant="contained" onClick={this.onResetButton}>
              Try Again
            </Button>
          </div>
        )}

        {state === 'CSV_PROCESSED_SUCCESSFULLY' && (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {`Processed ${csvFile.name} and found ${csvResults.data.length} rows`}
              </Typography>
              <Typography variant="body2" gutterBottom>
                (any blank lines are skipped)
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <Button variant="contained" onClick={this.onResetButton}>
                    Upload a different CSV
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onConfirmButton}
                  >
                    Proceed with this CSV
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                CSV File Preview:
              </Typography>
              <CsvFileViewer
                csvData={csvResults}
                displayRows={Array.from(
                  Array(Math.min(10, csvResults.data.length)).keys()
                )} // Show first 10 rows as a preview
              />
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
}

CsvUpload.propTypes = {
  classes: PropTypes.object,
  onProceedWithCsv: PropTypes.func.isRequired,
}

export default withStyles(styles)(CsvUpload)
