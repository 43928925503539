import http from 'helpers/http'

export const updateContact = async (person, contact) => {
  const payload = Object.assign({}, contact)
  delete payload.contact_type
  delete payload.contact_purposes
  return http.put(`/people/${person.id}/contacts/${contact.id}`, {
    contact: payload,
  })
}

export const deleteContact = async (person, contact) => {
  return http.delete(`/people/${person.id}/contacts/${contact.id}`)
}

export default updateContact
