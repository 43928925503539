import http from 'helpers/http'

export const getData = async () => {
  return await http.get('/lists.json').then(res => res.data)
}

export const deleteList = async id => {
  return await http.delete(`/lists/${id}.json`).then(res => res.data)
}

export default getData
