import React from 'react'
import * as PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import CodeIcon from '@material-ui/icons/Code'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import PersonIcon from '@material-ui/icons/Person'
import SaveIcon from '@material-ui/icons/Save'

import styles from './styles'
import FriendlyDiff from './FriendlyDiff'
import ReviewForm from './ReviewForm'

class Draft extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      draft: this.props.draft,
      tab: 0,
      reviewFormOpen: false,
      reviewFormMethod: 'Reject',
      hidden: false,
    }
  }

  titleText() {
    const { draft } = this.state
    return `Draft Change #${draft.draft_transaction_id}`
  }

  noteText() {
    const { draft } = this.state
    return `Created by ${draft.created_by} at ${draft.created_at}`
  }

  rawDiff() {
    const { draft } = this.state
    return (
      <Card>
        <CardContent>
          <pre>{JSON.stringify(draft, null, 2)}</pre>
        </CardContent>
      </Card>
    )
  }

  onTabChange = (event, tab) => {
    this.setState({ tab })
  }

  onClickReviewForm = method => () => {
    this.setState({
      reviewFormOpen: true,
      reviewFormMethod: method,
    })
  }

  onCloseReviewForm = () => {
    this.setState({ reviewFormOpen: false })
  }

  onSubmitReviewFormCallback = () => {
    this.setState({
      reviewFormOpen: false,
      hidden: true,
    })
  }

  render() {
    const { classes } = this.props
    const { hidden, draft, tab } = this.state

    return (
      <Collapse in={!hidden}>
        <Paper className={classes.draftContainer}>
          <AppBar position="static">
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item container sm={12} md={4} justifyContent="flex-start">
                  <Tabs value={tab} onChange={this.onTabChange}>
                    <Tab label="Friendly View" icon={<PersonIcon />} />
                    <Tab label="Raw View" icon={<CodeIcon />} />
                  </Tabs>
                </Grid>
                <Grid item sm={12} md={4} align="center">
                  <Typography variant="h6" color="inherit">
                    {this.titleText()}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    {this.noteText()}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  sm={12}
                  md={4}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.onClickReviewForm('Reject')}
                    className={classes.button}
                  >
                    Reject &nbsp; <DeleteSweepIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onClickReviewForm('Approve')}
                    className={classes.button}
                  >
                    Approve &nbsp; <SaveIcon />
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Paper>
            {tab === 0 && <FriendlyDiff friendlyDiff={draft.friendly_diff} />}
            {tab === 1 && this.rawDiff()}
          </Paper>
        </Paper>
        <ReviewForm
          open={this.state.reviewFormOpen}
          draftTransactionId={this.state.draft.draft_transaction_id}
          method={this.state.reviewFormMethod}
          onClickClose={this.onCloseReviewForm}
          onSubmitCallback={this.onSubmitReviewFormCallback}
        />
      </Collapse>
    )
  }
}

Draft.propTypes = {
  draft: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(Draft)
