import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  fetchAreaClassifications,
  fetchAreasByClassification,
  clearAreas,
  fetchAreaDataTypes,
} from 'state/actions/geo'
import { snackbarNotify } from 'state/actions/app'
import { loadTagCategories, fetchLists } from 'state/actions/search'
import PushToListForm from 'components/DataTable/PushToListForm'
import Loading from 'components/Loading'
import { base } from 'constants/styles'
import TransferList from 'components/TransferList'
import AreaTypeSelection from './AreaTypeSelection'
import MapView from './MapView'
import AreaFilters from './AreaFilters'
import { Typography } from '@material-ui/core'
import { filterItems } from 'components/Filters/index.helpers'

function mapStateToProps(state) {
  return {
    geo: state.geo,
    areas: state.search.data,
    settings: state.app.settings,
    tagCategories: state.search.tagCategories,
    lists: state.cache.lists,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchAreaClassifications,
      fetchAreasByClassification,
      snackbarNotify,
      loadTagCategories,
      clearAreas,
      fetchAreaDataTypes,
      fetchLists,
    },
    dispatch
  )
}

class Areas extends React.PureComponent {
  state = {
    areaClassificationId: 0,
    areaClassificationSelectedOnce: false,
    mapZoom: 1,
    selected: [],
    pushForm: false,
    activeFilters: [],
    filteredAreas: [],
    highlighted: [],
  }

  transferList = React.createRef()

  componentDidMount() {
    this.props.fetchAreaClassifications().then(() =>
      // TODO: remove this
      this.selectAreaType({ target: { value: 2 } })
    )
    this.props.loadTagCategories()
    this.props.fetchAreaDataTypes()
    this.props.fetchLists()
  }

  selectAreaType = event => {
    this.setState({
      areaClassificationId: event.target.value,
      areaClassificationSelectedOnce: true,
    })
    this.props.fetchAreasByClassification(event.target.value).then(areas =>
      this.setState({
        filteredAreas: filterItems(this.state.activeFilters, areas),
      })
    )
  }

  onTransferListUpdate = selected => {
    this.setState({
      selected: selected.map(s => ({ ...s, area_uid: s.identifier })),
    })
  }

  onPushToList = () => {
    this.props.snackbarNotify('Areas pushed to list')
    this.props.clearAreas()
    this.setState({
      areaClassificationId: '',
      pushForm: false,
      areaClassificationSelectedOnce: false,
      activeFilters: [],
      filteredAreas: [],
    })
  }

  onFiltersUpdated = filters => {
    const output = filterItems(filters, this.props.areas)
    this.setState({ filteredAreas: output, activeFilters: filters })
  }

  onHighlightedUpdate = highlighted => {
    this.setState({
      highlighted: highlighted
        .map(h => h && h.identifier)
        .filter(item => !!item),
    })
  }

  onAreaClick = identifier => {
    this.transferList.current.selectItem(identifier)
  }

  render() {
    const { classes, areas, geo, settings, tagCategories, lists } = this.props
    const {
      areaClassificationId,
      areaClassificationSelectedOnce,
      selected,
      pushForm,
      filteredAreas,
      highlighted,
    } = this.state

    if (
      !geo ||
      (geo && !geo.areaClassifications) ||
      !tagCategories ||
      !geo.areaDataTypes ||
      !lists
    )
      return null
    let areaClassification
    if (!!geo.areaClassifications && !!areaClassificationId) {
      areaClassification = geo.areaClassifications.filter(
        ac => ac.id === areaClassificationId
      )[0]
    }

    return (
      <Grid container direction="row" style={{ padding: 30 }} spacing={2}>
        <PushToListForm
          open={pushForm}
          items={selected}
          itemType={'Area'}
          itemIdPath={'id'}
          onClickClose={() => this.setState({ pushForm: false })}
          dataTitle={areaClassification && areaClassification.name}
          onSubmit={this.onPushToList}
        />
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <AreaTypeSelection
                    areaTypes={geo.areaClassifications}
                    onChange={this.selectAreaType}
                    selected={areaClassificationId}
                    settings={settings.areas}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: 900 }}>Info</span>
                    <br />
                    Most tag data only exists for the 'Westminster Parlimentary
                    Constituency' area type.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {areas && !!areas.length && !!areaClassification && (
              <AreaFilters
                settings={settings}
                tagCategories={tagCategories}
                areas={areas}
                onFiltersUpdated={this.onFiltersUpdated}
                areaDataTypes={geo.areaDataTypes}
                areaClassification={areaClassification}
                lists={lists}
              />
            )}
            <Grid item>
              {areas && !!areas.length ? (
                <TransferList
                  listItems={filteredAreas}
                  updateSelected={this.onTransferListUpdate}
                  updateHighlighted={this.onHighlightedUpdate}
                  ref={this.transferList}
                  lazy={!this.props.override}
                />
              ) : (
                <Loading visible={areaClassificationSelectedOnce} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.container}>
          {areas && !!areas.length && (
            <MapView
              settings={settings.areas}
              areaTypeName={areaClassification && areaClassification.name}
              selected={selected}
              highlighted={highlighted}
              onAreaClick={this.onAreaClick}
            />
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            disabled={!selected.length}
            onClick={() => this.setState({ pushForm: true })}
          >
            Push to List
          </Button>
        </Grid>
      </Grid>
    )
  }
}

Areas.propTypes = {
  classes: PropTypes.object,
  fetchAreaClassifications: PropTypes.func,
  fetchAreasByClassification: PropTypes.func,
  geo: PropTypes.object,
  areas: PropTypes.array,
  snackbarNotify: PropTypes.func,
  clearAreas: PropTypes.func,
  settings: PropTypes.object,
  loadTagCategories: PropTypes.func,
  tagCategories: PropTypes.array,
  fetchAreaDataTypes: PropTypes.func,
  fetchLists: PropTypes.func,
  lists: PropTypes.array,
  override: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(base)(Areas))
