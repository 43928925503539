import Ajv from 'ajv'
import { get, set, snakeCase } from 'lodash'
import http from 'helpers/http'
import { JSON_SCHEMA_VERSIONS } from 'constants/index'
import defsSchema from 'constants/schemas/defs.json'
import membershipSearchSchema from 'constants/schemas/membership_search_schema.json'
import electionCandidateSearchSchema from 'constants/schemas/election_candidate_search_schema.json'
import {
  SEARCH_DATA_LOADING,
  SEARCH_DATA_LOADED,
  TAG_CATEGORIES_LOADED,
  SEARCH_DATA_UPDATED,
  LISTS_FETCHED,
} from '../actionTypes'

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const loadTagCategories = () => async dispatch => {
  const tagCategories = (await http.get(`/tag_categories.json`)).data

  dispatch({
    type: TAG_CATEGORIES_LOADED,
    tagCategories,
  })

  return tagCategories
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const loadSearchData = (
  search_group,
  group_config
) => async dispatch => {
  dispatch({ type: SEARCH_DATA_LOADING })

  /** Filter for requested role */
  const data = await http
    .getDataWithRetry(`/search/data/${search_group}.json`)
    .catch(err => ({
      status: 'error',
      error: err,
    }))

  /** If backend is broken */
  if (data.status && data.status === 'error')
    return {
      status: 'error',
      error: 'Something is wrong with the server - contact your tech team',
    }

  /** Check JSON against schema */
  const schema = (search_group_type => {
    switch (search_group_type) {
      case 'Membership':
        return membershipSearchSchema
      case 'ElectionCandidate':
        return electionCandidateSearchSchema
    }
  })(group_config.group_type)

  const ajv = new Ajv({ allErrors: true })
  const validator = ajv.addSchema(defsSchema).compile(schema)
  const validations = {
    schema: validator(data),
    schema_version: JSON_SCHEMA_VERSIONS.search === data.schema_version,
  }
  if (!Object.values(validations).every(v => v)) {
    if (!!validator.errors) console.error(validator.errors)
    Object.entries(validations)
      .filter(v => !v[1])
      .forEach(entry => console.error(errorMsg[entry[0]]))

    return {
      status: 'error',
      error: `Something is wrong - contact your tech team`,
      errors: validator.errors,
    }
  }

  /** Filter data by search group */
  // const searchData = data.items.filter(
  //   item =>
  //     group_config.filter_value === '' ||
  //     get(item, group_config.filter_path).toLowerCase() ===
  //       group_config.filter_value.toLowerCase()
  // )

  const searchData = data.items

  dispatch({
    type: SEARCH_DATA_LOADED,
    data: searchData,
  })

  return searchData
}

/**
 * REDUX ACTION
 *
 * @param request e.g. {
 *   type: 'tag',
 *   params: { name: 'votes-in-parliament', taggable_type: 'Person' },
 * }
 * @param searchData - original dataset to modify
 * @returns {Function}
 */
export const getExtendedData = (request, searchData) => async dispatch => {
  if (request.type === 'tag') {
    const params = Object.entries(request.params)
      .map(p => `${p[0]}=${p[1]}`)
      .join('&')
    const tags = (await http.get(`/tags.json?${params}`)).data
    if (tags && tags.length) {
      let error = 0
      tags.forEach(tag => {
        const idx = searchData.findIndex(item => {
          const tagItem = !!request.root ? get(item, request.root) : item
          if (tagItem && !tagItem.id) error += 1
          return tagItem && tagItem.id === tag.taggable_id
        })
        if (idx > -1) {
          let itemTags = get(searchData[idx], request.path)
          if (!itemTags) set(searchData[idx], request.path, {})
          set(searchData[idx], `${request.path}.${tag.name}`, {
            value: !!Number(tag.value) ? Number(tag.value) : tag.value,
          })
        }
      })
      if (error > 0) {
        return {
          status: 'error',
          message: 'something is wrong with the data from the server',
        }
      } else {
        dispatch({
          type: SEARCH_DATA_UPDATED,
          data: searchData,
        })
        return searchData
      }
    } else
      return {
        status: 'error',
        message: 'no tag data was found in the database',
      }
  }

  if (request.type === 'area_data') {
    const area_data = (
      await http.get(`/area_data/${request.params.area_data_id}.json`)
    ).data
    if (area_data) {
      const items = area_data['area_data_sources'].filter(
        ads => ads.id === request.params.area_data_source_id
      )[0]['items']

      items.forEach(ad_item => {
        const idx = searchData.findIndex(datum => {
          const area = request.root ? get(datum, request.root) : datum
          return area && area.id === ad_item.area_id
        })
        set(
          searchData[idx],
          `${request.path}.${ad_item.name}.${snakeCase(ad_item.qualifier)}`,
          ad_item
        )
      })

      dispatch({
        type: SEARCH_DATA_UPDATED,
        data: searchData,
      })
      return searchData
    } else {
      return {
        status: 'error',
        message: 'no area data was found in the database',
      }
    }
  }

  if (request.type === 'list') {
    const lists = (await http.get('/lists.json')).data
    if (lists) {
      const searchType = request.params.searchType.toLowerCase()
      lists
        .filter(list => list.listable_type === request.params.searchType)
        .forEach(list => {
          const subset = searchData.filter(item =>
            list.list_entries.includes(
              (request.root ? item[request.root] : item).id
            )
          )
          subset.forEach(item => {
            if (searchType === 'area') {
              item.in_list = (item.in_list || []).concat({
                id: list.id,
              })
            } else {
              item[searchType].in_list = (
                item[searchType].in_list || []
              ).concat({
                id: list.id,
              })
            }
          })
        })
      dispatch({
        type: SEARCH_DATA_UPDATED,
        data: searchData,
      })
      return searchData
    } else {
      return {
        status: 'error',
        message: 'no lists were found in the database',
      }
    }
  }
}

const errorMsg = {
  schema: 'The server data does not match the frontend JSON schema',
  schema_version:
    'The server JSON schema version does not match the expected version on the frontend',
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchLists = () => async dispatch => {
  const lists = (await http.get('/lists.json')).data

  dispatch({
    type: LISTS_FETCHED,
    lists,
  })

  return lists
}
