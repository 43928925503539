import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'

import { base } from 'constants/styles'

class SaveButtonInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      displayValue: this.props.initial || '',
    }
  }

  onChange = evt => {
    evt.preventDefault()
    this.setState({ displayValue: evt.target.value })
  }

  render() {
    const { classes, label, style } = this.props
    const { displayValue } = this.state
    const unsaved = (this.props.initial || '') !== displayValue
    return (
      <TextField
        style={style}
        className={classes.textField}
        variant="outlined"
        type="text"
        label={label}
        margin="normal"
        value={displayValue || ''}
        onChange={this.onChange}
        InputProps={{
          endAdornment: unsaved ? (
            <InputAdornment position="end">
              <Button
                aria-label="Save"
                onClick={() => this.props.onSave(displayValue)}
              >
                SAVE
              </Button>
            </InputAdornment>
          ) : null,
        }}
        onKeyPress={e =>
          unsaved && e.key === 'Enter' ? this.props.onSave(displayValue) : null
        }
      />
    )
  }
}

SaveButtonInput.propTypes = {
  classes: PropTypes.object.isRequired,
  initial: PropTypes.string,
  label: PropTypes.string,
  onSave: PropTypes.func,
  style: PropTypes.object,
}

export default withStyles(base)(SaveButtonInput)
