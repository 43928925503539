import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps'
import Typography from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { getOnsGeoName } from 'helpers/index'

class MapView extends Component {
  state = {
    mapZoom: 1,
    tooltip: null,
    optimiseMap: false,
  }

  debounce = false

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tooltip !== this.state.tooltip) this.debounce = false
  }

  toggleMapZoomable = zoomable => {
    document.body.style.overflowY = zoomable ? 'hidden' : 'auto'
    this.setState({ optimiseMap: zoomable })
  }

  onWheelZoom = e => {
    this.setState({
      mapZoom: this.state.mapZoom * (1 + 0.2 * (e.deltaY > 0 ? -1 : 1)),
    })
  }

  onAreaClick = area => {
    this.setState({ optimiseMap: false })
    this.props.onAreaClick(area.id)
    setTimeout(() => this.setState({ optimiseMap: true }), 200)
  }

  onAreaHover = (area, direction) => () => {
    this.setState({ tooltip: null })
    if (direction === 'enter' && !this.debounce) {
      this.debounce = true
      setTimeout(() => {
        this.setState({ tooltip: getOnsGeoName(area) })
      }, 1)
    }
  }

  render() {
    const { settings, areaTypeName, highlighted, theme } = this.props
    const selected = this.props.selected.map(p => p.identifier)
    const mapSetting = settings.maps.filter(item => item.name === areaTypeName)
    const topoJsonFile = mapSetting.length ? mapSetting[0].topojson : null

    return (
      <Paper
        onMouseEnter={() => this.toggleMapZoomable(true)}
        onMouseLeave={() => this.toggleMapZoomable(false)}
        onWheel={this.onWheelZoom}
      >
        {!!topoJsonFile ? (
          <ComposableMap
            projectionConfig={{
              scale: 4000,
              xOffset: 70,
              yOffset: 1800,
              rotation: [0, 0, 0],
              precision: 10,
            }}
            style={{
              width: '100%',
              height: '80vh',
            }}
          >
            <ZoomableGroup zoom={this.state.mapZoom}>
              <Geographies
                geography={`/topojson/${topoJsonFile}`}
                disableOptimization={!this.state.optimiseMap}
              >
                {(geographies, projection) =>
                  geographies.map(geography => {
                    let fillColor = '#ECEFF1'
                    const isHighlighted = highlighted.includes(geography.id)
                    if (isHighlighted) fillColor = theme.palette.primary.light
                    if (selected.includes(geography.id))
                      fillColor = theme.palette.secondary.main
                    return (
                      <Geography
                        key={geography.id}
                        role="geography"
                        title={`${getOnsGeoName(geography)}${
                          isHighlighted ? '-highlighted' : ''
                        }`}
                        geography={geography}
                        projection={projection}
                        onClick={this.onAreaClick}
                        onMouseEnter={this.onAreaHover(geography, 'enter')}
                        onMouseLeave={this.onAreaHover(geography, 'leave')}
                        style={{
                          default: {
                            fill: fillColor,
                            stroke: '#607D8B',
                            strokeWidth: 0.25,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#607D8B',
                            stroke: '#607D8B',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          pressed: {
                            fill: '#FF5722',
                            stroke: '#607D8B',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                        }}
                      />
                    )
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
        ) : (
          <div>
            <Typography
              variant="h4"
              style={{ padding: 40, textAlign: 'center' }}
            >
              Sorry, no map data available
            </Typography>
            <Typography
              variant="h2"
              style={{ padding: 60, textAlign: 'center' }}
            >
              ¯\_(ツ)_/¯
            </Typography>
          </div>
        )}
        {this.state.tooltip && (
          <Container
            style={{
              bottom: 0,
              width: '100%',
              height: 70,
              background: 'white',
              color: '#464646',
              marginTop: -70,
              position: 'relative',
              zIndex: 1000,
            }}
          >
            <Grid container direction="row">
              <Grid item>
                <Typography variant="h4">{this.state.tooltip}</Typography>
              </Grid>
            </Grid>
          </Container>
        )}
      </Paper>
    )
  }
}

MapView.propTypes = {
  settings: PropTypes.object,
  areaTypeName: PropTypes.string,
  highlighted: PropTypes.array,
  selected: PropTypes.array,
  theme: PropTypes.object,
  onAreaClick: PropTypes.func,
}

export default withTheme(MapView)
