const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: 2 * theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
})

export default styles
