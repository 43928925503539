import React from 'react'
import * as PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import Main from 'containers/Main'
import store from 'state/store'

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Provider store={store}>
        <Main settings={this.props.settings} />
      </Provider>
    )
  }
}

App.propTypes = {
  settings: PropTypes.object,
}

export default App
