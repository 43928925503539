export function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map(arg => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme)
      }
      // Objects need no change.
      return arg
    })

    return outStyles.reduce((acc, val) => Object.assign(acc, val))
  }
}

export function titleCase(str) {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(function(word) {
        return word.replace(word[0], word[0].toUpperCase())
      })
      .join(' ')
  } else {
    return ''
  }
}

export function getOnsGeoName(area) {
  // If you don't know what type of area it is and want the name
  return (
    area.properties['EER13NM'] ||
    area.properties['PCON13NM'] ||
    area.properties['LAD13NM']
  )
}
