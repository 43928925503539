import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import styles from './styles'

class TextWithHelp extends React.Component {
  state = {
    anchorEl: null,
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, id, typographyVariant, text, helpText } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <Typography variant={typographyVariant}>
          {text}
          <HelpIcon
            className={classes.rightIcon}
            color="primary"
            fontSize="small"
            aria-owns={open ? id : undefined}
            aria-haspopup="true"
            onMouseEnter={this.handlePopoverOpen}
            onMouseLeave={this.handlePopoverClose}
          />
        </Typography>
        <Popover
          id={id}
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{helpText}</Typography>
        </Popover>
      </div>
    )
  }
}

TextWithHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  typographyVariant: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
}

export default withStyles(styles)(TextWithHelp)
