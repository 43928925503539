const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldMega: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  inputMega: {
    paddingTop: '0.5rem',
    fontSize: '2rem',
  },
  inputLabelMega: {
    fontSize: '2rem',
  },
})

export default styles
