import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Phone from '@material-ui/icons/Phone'
import SettingsPhone from '@material-ui/icons/SettingsPhone'
import Email from '@material-ui/icons/Email'
import MoreVert from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import Chip from '@material-ui/core/Chip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Snackbar from '@material-ui/core/Snackbar'

import styles from './styles'
import { updateContact, deleteContact } from './index.actions'
import SocialLogo from '../SocialLogo'
import { CONTACT_TYPE_COLOURS } from 'constants/index'
import AddContactForm from 'containers/Person/AddContactForm'
import Typography from '@material-ui/core/Typography'

const getIcon = contact_type => {
  let logo
  switch (contact_type) {
    case 'facebook':
    case 'twitter':
    case 'linkedin':
      logo = <SocialLogo name={contact_type} />
      break
    case 'email':
      logo = <Email />
      break
    case 'phone':
      logo = <Phone />
      break
    case 'fax':
      logo = <SettingsPhone />
      break
  }
  return (
    <Avatar style={{ backgroundColor: CONTACT_TYPE_COLOURS[contact_type] }}>
      {logo}
    </Avatar>
  )
}

class ContactCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      contact: this.props.contact,
      menuButtonEl: null,
      snackbar: false,
    }
  }

  onChangeActive = event => {
    const { person, contact } = this.props
    this.setState({
      active: event.target.checked,
    })
    contact.active = event.target.checked
    updateContact(person, contact)
  }

  showContactPurposeForm = e => {
    e.preventDefault()
    this.setState({
      contactFormOpen: true,
      contactable: this.props.contactable,
    })
  }

  onCloseEditContactPurposes = () => {
    this.setState({ contactFormOpen: false })
  }

  onSubmitEditContactPurposes = contact => e => {
    e.preventDefault()
    const { person } = this.props
    updateContact(person, contact).then(res => {
      this.setState({
        contact: {
          ...contact,
          contact_purposes: res.data.contact_purposes,
        },
        contactFormOpen: false,
        contactable: null,
      })
    })
  }

  getChips = (contact_purposes, contactPurposeTypes) => {
    return (
      <div>
        {contact_purposes.map(c => {
          const cpt = contactPurposeTypes.filter(
            t => t.id === c.contact_purpose_type_id
          )[0]
          return (
            <Chip
              key={cpt.name}
              label={cpt.name}
              title={cpt.description}
              color={'primary'}
              className={this.props.classes.chip}
            />
          )
        })}
        <Chip
          label={'Edit purposes'}
          className={this.props.classes.chip}
          variant="outlined"
          onClick={this.showContactPurposeForm}
        />
      </div>
    )
  }

  onClickDelete = () => {
    deleteContact(this.props.person, this.state.contact).then(() => {
      this.props.onDeleted()
    })
  }

  onOpenMenu = event => {
    this.setState({ menuButtonEl: event.currentTarget })
  }

  onCloseMenu = () => {
    this.setState({ menuButtonEl: null })
  }

  copyToClipboard = () => {
    this.setState({ snackbar: true })
  }

  onCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ snackbar: false })
  }

  render() {
    const {
      active,
      contact_purposes,
      label,
      value,
      contact_type,
    } = this.state.contact
    const { contactable } = this.state
    const { getChips } = this
    const { classes, contactPurposeTypes } = this.props
    // noinspection HtmlDeprecatedAttribute
    return (
      <div className={classes.container}>
        {contactable && (
          <AddContactForm
            onClickClose={this.onCloseEditContactPurposes}
            onSubmit={this.onSubmitEditContactPurposes}
            open={this.state.contactFormOpen}
            contactable={this.state.contactable}
            contactTypes={this.props.contactTypes || []}
            contactPurposeTypes={this.props.contactPurposeTypes || []}
            contact={this.state.contact}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.snackbar}
          autoHideDuration={2000}
          onClose={this.onCloseSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Contact copied to clipboard...</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.onCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        <Card className={[classes.card, !active && classes.inactive].join(' ')}>
          <CardHeader
            avatar={getIcon(contact_type)}
            action={
              <Icon>
                <MoreVert
                  onClick={this.onOpenMenu}
                  aria-owns={this.state.menuButtonEl ? 'card-menu' : undefined}
                  aria-haspopup="true"
                />
                <Menu
                  id="card-menu"
                  anchorEl={this.state.menuButtonEl}
                  open={Boolean(this.state.menuButtonEl)}
                  onClose={this.onCloseMenu}
                >
                  <MenuItem disabled onClick={this.onCloseMenu}>
                    Add note
                  </MenuItem>
                  {process.env.NODE_ENV !== 'production' && (
                    <MenuItem onClick={this.onClickDelete}>Delete</MenuItem>
                  )}
                </Menu>
              </Icon>
            }
            title={label || contact_type}
            classes={{ content: classes.header }}
            subheader={''}
            subheaderTypographyProps={{ noWrap: true }}
          />
          <CardContent style={{ minHeight: 110, paddingTop: 0 }}>
            <Tooltip title={'Click to copy to clipboard'}>
              <CopyToClipboard
                text={value}
                onCopy={() => this.copyToClipboard(value)}
              >
                <Typography
                  variant={'body2'}
                  align="left"
                  style={{
                    cursor: 'pointer',
                    marginBottom: 20,
                    overflowWrap: 'break-word',
                  }}
                >
                  {value}
                </Typography>
              </CopyToClipboard>
            </Tooltip>
            {!contact_purposes || contact_purposes.length === 0 ? (
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                onClick={this.showContactPurposeForm}
              >
                Add a contact purpose
              </Button>
            ) : (
              getChips(contact_purposes, contactPurposeTypes)
            )}
          </CardContent>
          <CardActions classes={{ root: classes.actions }}>
            <FormControlLabel
              className={classes.noMargin}
              control={
                <Switch
                  checked={active}
                  onChange={this.onChangeActive}
                  value="active"
                  color="primary"
                />
              }
              label={`${!active ? 'Ina' : 'A'}ctive`}
            />
          </CardActions>
        </Card>
      </div>
    )
  }
}

ContactCard.propTypes = {
  contact: PropTypes.object,
  person: PropTypes.object,
  classes: PropTypes.object,
  contactable: PropTypes.object,
  contactPurposeTypes: PropTypes.array,
  contactTypes: PropTypes.array,
  onDeleted: PropTypes.func,
}

export default withStyles(styles)(ContactCard)
