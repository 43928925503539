import React from 'react'
import * as PropTypes from 'prop-types'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { connect } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

import Home from 'containers/Home'
import Search from 'containers/Search'
import List from 'containers/List'
import Person from 'containers/Person'
import Areas from 'containers/Areas'
import AreaDetail from 'containers/AreaDetail'
import NavBar from 'components/NavBar'
import { base } from 'constants/styles'
import muiTheme from 'constants/mui-theme'
import store from 'state/store'
import { SETTINGS_UPDATE } from 'state/actionTypes'

function mapStateToProps(state) {
  return {
    snackbar: state.app.snackbar,
    snackbarMsg: state.app.snackbarMsg,
    app: state.app,
  }
}

class Main extends React.Component {
  componentDidMount() {
    store.dispatch({
      type: SETTINGS_UPDATE,
      settings: this.props.settings,
    })
  }

  render() {
    const { classes, snackbar, snackbarMsg, app } = this.props
    if (!app.settings) return null

    return (
      <ThemeProvider theme={muiTheme}>
        <div className={classes.container} style={{ minHeight: '80vh' }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbar}
            onClose={this.handleSnackbarClose}
          >
            <SnackbarContent
              classes={{ root: classes.warningSnack }}
              message={
                <Typography
                  variant={'body1'}
                  classes={{ root: classes.warningSnackText }}
                >
                  {snackbarMsg}
                </Typography>
              }
            />
          </Snackbar>
          <BrowserRouter>
            <NavBar />
            <Switch>
              <Route exact path="/app" component={Home} />
              <Route path="/app/search" component={Search} />
              <Route path="/app/areas" component={Areas} />
              <Route path="/app/people/:id" component={Person} />
              <Route path="/app/lists/:list_id" component={List} />
              <Route path="/app/area/:area_id" component={AreaDetail} />
            </Switch>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    )
  }
}

Main.propTypes = {
  settings: PropTypes.object,
  app: PropTypes.object,
  classes: PropTypes.object,
  snackbar: PropTypes.bool,
  snackbarMsg: PropTypes.string,
}

export default connect(mapStateToProps)(withStyles(base)(Main))
