import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { camelCase } from 'lodash'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

import { FILTER_SELECT_COLOURS } from 'constants/index'
import styles from './styles'
import Reset from 'components/Filters/Reset'
import Controls from 'components/Filters/Controls'

const Rect = props => {
  return (
    <div
      style={{
        width: `${90 * props.ratio}%`,
        lineHeight: '5px',
        marginBottom: '6px',
        marginTop: '-3px',
      }}
    >
      <svg width="100%" height="5px">
        <rect width="100%" height="100%" fill={props.fill} />
      </svg>
    </div>
  )
}
Rect.propTypes = {
  ratio: PropTypes.number,
  fill: PropTypes.string,
}

class FilterSelect extends React.Component {
  onListItemClick = (event, option) => {
    const exists =
      this.props.filter.selected.filter(so => so.id === option.id).length > 0
    this.props.onChange({ remove: exists, option, filter: this.props.filter })
  }

  onListReset = () => {
    this.props.filter.selected.forEach(so => {
      this.props.onChange({
        remove: true,
        option: so,
        filter: this.props.filter,
      })
    })
  }

  onModifierClick = modifier => {
    this.props.onChange({
      modifier,
      filter: this.props.filter,
    })
    this.setState({ ...modifier })
  }

  render() {
    const { classes, filter } = this.props

    return (
      <Grid
        className={classes.container}
        container
        direction="column"
        justifyContent="flex-start"
      >
        {filter.selected.length > 0 && <Reset onListReset={this.onListReset} />}
        <Grid item>
          <List dense>
            {!filter.options
              ? 'No options found'
              : filter.options.map(option => {
                  const selected = filter.selected
                    .map(o => o.id)
                    .includes(option.id)
                  const nonZero = option.count > 0
                  const colored = !!FILTER_SELECT_COLOURS[
                    camelCase(option.value)
                  ]

                  return (
                    <div key={option.id}>
                      <ListItem
                        button
                        className={
                          nonZero && colored
                            ? classes[camelCase(option.value) + 'ListItem']
                            : classes.listItem
                        }
                        classes={{
                          selected: colored
                            ? classes[camelCase(option.value) + 'ListItem']
                            : classes.listItemSelected,
                        }}
                        selected={selected}
                        onClick={event => this.onListItemClick(event, option)}
                      >
                        <ListItemIcon>
                          <Avatar
                            className={
                              selected
                                ? colored
                                  ? classes[camelCase(option.value) + 'Avatar']
                                  : classes.avatarSelected
                                : nonZero && colored
                                ? classes[camelCase(option.value) + 'Avatar']
                                : classes.avatar
                            }
                          >
                            {!!option.value ? option.value[0] : 'ND'}
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItemText}
                          primary={!!option.value ? option.value : 'No Data'}
                        />
                      </ListItem>
                    </div>
                  )
                })}
          </List>
          <Controls filter={filter} onModifierUpdate={this.onModifierClick} />
        </Grid>
      </Grid>
    )
  }
}

FilterSelect.propTypes = {
  filter: PropTypes.object,
  classes: PropTypes.object,
  onChange: PropTypes.func,
}

export default withStyles(styles)(FilterSelect)
