import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { intersectionBy, snakeCase } from 'lodash'
import LazyLoad, { forceCheck } from 'react-lazyload'
import { Typography } from '@material-ui/core'
import Countup from 'react-countup'

const styles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    height: '40vh',
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
})

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return intersectionBy(a, b, 'id')
}

const CustomList = props => {
  const { classes, items, checked, handleToggle, title, lazy } = props

  React.useEffect(() => {
    forceCheck()
  }, [items])

  return (
    <div>
      <Typography variant="h6" style={{ padding: 5, minHeight: 42 }}>
        {!!items.length && <Countup end={items.length} duration={1} />}
        {!!items.length ? ` ${props.label}` : ''}
      </Typography>
      <Paper className={classes.paper}>
        <List dense component="div" role="list" title={title}>
          {items
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item, idx) => {
              const isChecked = !!checked.filter(check => check.id === item.id)
                .length
              const labelId = `transfer-list-item-${idx}-label`
              const rendered = () => (
                <ListItem
                  key={item.id}
                  role="listitem"
                  title={`${title}-listitem-${snakeCase(item.name)}`}
                  button
                  onClick={handleToggle(item)}
                  style={{ paddingTop: 0, paddingBottom: 0, height: 35 }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={isChecked}
                      aria-label={`${title}-listitem-checkbox-${snakeCase(
                        item.name
                      )}${isChecked ? '-checked' : ''}`}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={item.name}
                    style={{ lineHeight: '1rem' }}
                  />
                </ListItem>
              )

              return lazy ? (
                <LazyLoad height={20} key={item.id} overflow debounce={200}>
                  {rendered()}
                </LazyLoad>
              ) : (
                rendered()
              )
            })}
          <ListItem />
        </List>
      </Paper>
    </div>
  )
}

CustomList.propTypes = {
  items: PropTypes.array,
  checked: PropTypes.array,
  handleToggle: PropTypes.func,
  classes: PropTypes.object,
  title: PropTypes.string,
  lazy: PropTypes.bool,
  label: PropTypes.string,
}

class TransferList extends React.Component {
  state = {
    checked: [],
    left: this.props.listItems,
    right: [],
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.listItems !== this.props.listItems) {
      this.handleAllLeft()
      this.setState({ left: this.props.listItems })
    }
  }

  leftChecked = () => intersection(this.state.checked, this.state.left)
  rightChecked = () => intersection(this.state.checked, this.state.right)

  updateChecked = item => {
    const { checked } = this.state
    const itemIsChecked = !!checked.filter(check => check.id === item.id).length
    let newChecked = [...checked]

    if (itemIsChecked) {
      newChecked = checked.filter(check => check.id !== item.id)
    } else {
      newChecked = checked.concat([item])
    }
    this.setState({ checked: newChecked })
    this.props.updateHighlighted(newChecked)
  }

  handleToggle = item => () => {
    this.updateChecked(item)
  }

  handleAllRight = () => {
    const { left, right } = this.state
    const newRight = right.concat(left)
    this.setState({ right: newRight, left: [] })
    this.props.updateSelected(newRight)
  }

  handleCheckedRight = () => {
    const { left, right, checked } = this.state
    const newRight = right.concat(this.leftChecked())
    this.setState({
      right: newRight,
      left: not(left, this.leftChecked()),
      checked: not(checked, this.leftChecked()),
    })
    this.props.updateSelected(newRight)
    this.props.updateHighlighted(not(checked, this.leftChecked()))
  }

  handleCheckedLeft = () => {
    const { left, right, checked } = this.state
    const newRight = not(right, this.rightChecked())
    this.setState({
      left: left.concat(this.rightChecked()),
      right: newRight,
      checked: not(checked, this.rightChecked()),
    })
    this.props.updateSelected(newRight)
    this.props.updateHighlighted(not(checked, this.rightChecked()))
  }

  handleAllLeft = () => {
    const { left, right } = this.state
    this.setState({
      left: left.concat(right),
      right: [],
    })
    this.props.updateSelected([])
  }

  selectItem = identifier => {
    const clicked = this.props.listItems.filter(
      item => item.identifier === identifier
    )[0]
    if (clicked) this.updateChecked(clicked)
  }

  render() {
    const { classes, lazy } = this.props
    const {
      rightChecked,
      leftChecked,
      handleAllLeft,
      handleAllRight,
      handleCheckedLeft,
      handleCheckedRight,
      handleToggle,
    } = this
    const { left, right, checked } = this.state

    return (
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Grid item style={{ flex: 1 }}>
          <CustomList
            items={left}
            classes={classes}
            checked={checked}
            handleToggle={handleToggle}
            title="transfer-list-left"
            lazy={lazy}
            label="available"
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant={left.length === 0 ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant={leftChecked().length === 0 ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked().length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant={rightChecked().length === 0 ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked().length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant={right.length === 0 ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <CustomList
            items={right}
            classes={classes}
            checked={checked}
            handleToggle={handleToggle}
            title="transfer-list-right"
            lazy={lazy}
            label="selected"
          />
        </Grid>
      </Grid>
    )
  }
}

TransferList.propTypes = {
  listItems: PropTypes.array,
  updateSelected: PropTypes.func,
  updateHighlighted: PropTypes.func,
  classes: PropTypes.object,
  lazy: PropTypes.bool,
}

export default withStyles(styles)(TransferList)
