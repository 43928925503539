const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {},
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeadIcon: {
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  tableHeadButtons: {
    textAlign: 'right',
  },
  contactBlockedCell: {
    backgroundColor: '#ff550024',
  },
  contactBlockedText: {
    color: '#ff5500',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '400px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  infoText: {
    width: '100%',
    marginTop: 10,
    marginBottom: 25,
    color: theme.palette.secondary.main,
  },
  columnsSelect: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 60,
    paddingTop: 40,
  },
  columnsSelectClose: {
    marginTop: -10,
  },
  circularProgress: {
    padding: 12,
    marginRight: 2,
  },
})

export default styles
