export default theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(2),
  },
  textField: {
    width: 250,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  leftButton: {
    marginLeft: theme.spacing(1),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
  stepper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: '#f8f9fa',
  },
  intro: {
    marginBottom: theme.spacing(3),
  },
})
