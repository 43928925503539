import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Map, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet'

// Mapbox base map URL

const accessToken = process.env.MAPBOX_TOKEN
const tileUrl =
  'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}'
const attribution =
  'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'

// Map settings
const maxZoom = 19

class AreaMap extends Component {
  constructor() {
    super()
    this.state = {
      zoom: 6,
      mapCenter: [54.9584192, -4.5019969],
    }
    this.map = React.createRef()
  }

  geoJSONStyle() {
    return {
      color: '#607D8B',
      weight: 2,
      fillOpacity: 0.3,
      fillColor: '#607D8B',
    }
  }

  geoJSONSubAreaStyle() {
    return {
      color: '#607D8B',
      weight: 0.5,
      fillOpacity: 0.5,
      fillColor: '#607D8B',
    }
  }

  onFeatureGroupAdd = e => {
    this.map.current.leafletElement.fitBounds(e.target.getBounds())
  }

  render() {
    const { areaGeo, subareasGeo, theme } = this.props
    return (
      <div>
        {!!areaGeo ? (
          <Map
            ref={this.map}
            id="map"
            center={this.state.mapCenter}
            zoom={this.state.zoom}
            maxZoom={maxZoom}
            style={{ height: '600px' }}
          >
            <TileLayer
              attribution={attribution}
              url={tileUrl}
              id={'mapbox.light'}
              accessToken={accessToken}
            />
            <FeatureGroup onAdd={this.onFeatureGroupAdd} key={1}>
              <GeoJSON data={areaGeo} style={this.geoJSONStyle} />
            </FeatureGroup>
            <FeatureGroup key={2}>
              {subareasGeo.map(subareaGeo => (
                <GeoJSON
                  data={subareaGeo}
                  style={this.geoJSONSubAreaStyle}
                  key={subareaGeo[0].properties.area_id}
                />
              ))}
            </FeatureGroup>
          </Map>
        ) : (
          <div>
            <Typography
              variant="h4"
              style={{ padding: 40, textAlign: 'center' }}
            >
              Sorry, no map data available
            </Typography>
            <Typography
              variant="h2"
              style={{ padding: 60, textAlign: 'center' }}
            >
              ¯\_(ツ)_/¯
            </Typography>
          </div>
        )}
      </div>
    )
  }
}

AreaMap.propTypes = {
  areaGeo: PropTypes.array,
  subareasGeo: PropTypes.array,
  theme: PropTypes.object,
}

export default withTheme(AreaMap)
