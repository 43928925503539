import {
  PERSON_UPDATED,
  PERSON_FETCHED,
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
} from '../actionTypes'
import http from '../../helpers/http'

const snackbarNotify = (snackbarMsg, dispatch) => {
  dispatch({
    type: SNACKBAR_OPEN,
    snackbarMsg,
  })
  setTimeout(() => dispatch({ type: SNACKBAR_CLOSE }), 3000)
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const fetchPerson = id => async dispatch => {
  const person = (await http.get(`/people/${id}.json`)).data
  dispatch({
    type: PERSON_FETCHED,
    person,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const updatePerson = (original, update) => async dispatch => {
  const person = (await http.put(`/people/${original.id}.json`, update)).data
  dispatch({
    type: PERSON_UPDATED,
    person: { ...original, ...person },
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const editPersonRelationship = (
  person,
  person_relationship,
  exists
) => async dispatch => {
  let updated, res
  if (exists) {
    res = await http.put(
      `/people/${person.id}/person_relationships/${person.person_relationship.id}.json`,
      {
        person_relationship,
      }
    )
  } else {
    res = await http.post(`/people/${person.id}/person_relationships.json`, {
      person_relationship,
    })
  }
  if (res.status === 200) {
    updated = person
    updated.person_relationship = JSON.parse(res.data.jsonString)
    dispatch({
      type: PERSON_UPDATED,
      person: updated,
    })
    snackbarNotify('Success - relationship updated', dispatch)
  } else {
    snackbarNotify('ERROR: not updated', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const addUpdateContactBlock = (
  person,
  contact_block,
  update
) => async dispatch => {
  const res = await http[update ? 'put' : 'post'](
    `/people/${person.id}/outgoing_contact_blocks${
      update ? '/' + contact_block.id.toString() : ''
    }.json`,
    {
      ...contact_block,
    }
  )

  if (res.status === 200) {
    if (update)
      person.outgoing_contact_blocks = person.outgoing_contact_blocks.filter(
        ocb => ocb.id !== contact_block.id
      )
    person.outgoing_contact_blocks = person.outgoing_contact_blocks.concat([
      res.data,
    ])
    dispatch({
      type: PERSON_UPDATED,
      person,
    })
    snackbarNotify('Success - contact block updated', dispatch)
  } else {
    snackbarNotify('ERROR: not updated', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const deleteContactBlock = (person, contact_block) => async dispatch => {
  const res = await http.delete(
    `/people/${person.id}/outgoing_contact_blocks/${contact_block.id}.json`
  )
  if (res.status === 200) {
    person.outgoing_contact_blocks = person.outgoing_contact_blocks.filter(
      ocb => ocb.id !== contact_block.id
    )
    dispatch({
      type: PERSON_UPDATED,
      person,
    })
    snackbarNotify('Success - contact block deleted', dispatch)
  } else {
    snackbarNotify('ERROR: not deleted', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const addUpdateRelationshipLog = (
  person,
  log,
  update
) => async dispatch => {
  const res = await http[update ? 'put' : 'post'](
    `/people/${person.id}/person_relationship_logs${
      update ? '/' + log.id.toString() : ''
    }.json`,
    {
      ...log,
    }
  )

  if (res.status === 200) {
    const updated = (await http.get(`/people/${person.id}`)).data
    dispatch({
      type: PERSON_UPDATED,
      person: updated,
    })
    snackbarNotify('Success - log updated', dispatch)
  } else {
    snackbarNotify('ERROR: something went wrong', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const deleteRelationshipLog = (person, log) => async dispatch => {
  const res = await http.delete(
    `/people/${person.id}/person_relationship_logs/${log.id}.json`
  )

  if (res.status === 200) {
    person.relationship_logs = person.relationship_logs.filter(
      rl => rl.id !== log.id
    )
    dispatch({
      type: PERSON_UPDATED,
      person,
    })
    snackbarNotify('Success - log deleted', dispatch)
  } else {
    snackbarNotify('ERROR: something went wrong', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const addUpdateTag = (person, tag, update) => async dispatch => {
  const res = await http[update ? 'put' : 'post'](
    `/tags${update ? '/' + tag.id.toString() : ''}.json`,
    {
      ...tag,
    }
  )

  if (res.status === 200) {
    const updated = (await http.get(`/people/${person.id.toString()}.json`))
      .data
    dispatch({
      type: PERSON_UPDATED,
      person: updated,
    })
    snackbarNotify('Success - tag updated', dispatch)
  } else {
    snackbarNotify('ERROR: something went wrong', dispatch)
  }
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const deleteTag = (person, tag) => async dispatch => {
  const res = await http.delete(`/tags/${tag.id.toString()}.json`)

  if (res.status === 200) {
    const updated = (await http.get(`/people/${person.id}.json`)).data
    dispatch({
      type: PERSON_UPDATED,
      person: updated,
    })
    snackbarNotify('Success - tag deleted', dispatch)
  } else {
    snackbarNotify('ERROR: something went wrong', dispatch)
  }
}
