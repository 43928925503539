import React from 'react'
import * as PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'

class CsvFileViewer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, csvData, displayRows } = this.props

    return (
      <Paper className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell key="0">Row Number</TableCell>
              {csvData.meta.fields.map((fieldName, index) => (
                <TableCell key={(index + 1).toString()}>{fieldName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayRows.map((rowNumber, index) => (
              <TableRow key={index.toString()}>
                {/* Use row+2 because array is 0-based and we also have a header row */}
                <TableCell key="0">{rowNumber + 2}</TableCell>
                {csvData.meta.fields.map((fieldName, index) => (
                  <TableCell
                    key={(index + 1).toString()}
                    component="th"
                    scope="row"
                  >
                    {csvData.data[rowNumber][fieldName]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

CsvFileViewer.propTypes = {
  classes: PropTypes.object,
  csvData: PropTypes.object.isRequired,
  displayRows: PropTypes.array,
}

export default withStyles(styles)(CsvFileViewer)
