import React from 'react'
import * as PropTypes from 'prop-types'
import { groupBy, flatten, snakeCase, uniqueId } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'

import styles from '../../containers/Search/SearchFilters/styles'
class AddFilterForm extends React.PureComponent {
  state = {
    selected: {},
    groupSelected: false,
    listItems: [],
  }

  componentDidMount() {
    this.processFilters(this.props.filters)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filters !== this.props.filters ||
      (!prevProps.open && this.props.open)
    ) {
      this.processFilters(this.props.filters)
    }
  }

  processFilters = filters => {
    const listItems = flatten(
      Object.entries(groupBy(filters, 'group.label')).map(entry => {
        if (['null', 'undefined'].includes(entry[0]))
          return (
            !!entry[1] && entry[1].map(f => f && { label: f.label, filter: f })
          )
        else
          return {
            label: entry[0],
            group: {
              name: `__group__${snakeCase(entry[0])}_${uniqueId('id_')}`,
              items: entry[1],
            },
          }
      })
    ).filter(li => !!li)
    this.setState({ listItems })
  }

  onUpdate = event => {
    if (event.target.value.includes('__group__')) {
      const selected = this.state.listItems.filter(
        item => item.group && item.group.name === event.target.value
      )[0]
      this.setState({
        groupSelected: true,
        selected: selected.group,
        search_filter: '',
      })
    } else {
      const selected = this.props.filters.filter(
        f => f.name === event.target.value
      )[0]
      this.setState({ search_filter: event.target.value, selected })
    }
  }

  onUpdateGroupSelect = event => {
    this.setState({ search_filter: event.target.value })
  }

  validate = () => {
    return !!this.state.search_filter
  }

  reset = () => {
    this.setState({
      search_filter: null,
      selected: {},
      groupSelected: false,
      listItems: [],
    })
  }

  render() {
    const { classes, onSubmit, selectedFilters } = this.props
    const { search_filter, selected, listItems, groupSelected } = this.state
    const valid = this.validate()

    return (
      <Dialog
        open={this.props.open}
        onExit={this.reset}
        onClose={this.props.onClickClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add a new filter</DialogTitle>
        <DialogContent className={classes.root} style={{ minWidth: 600 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="contact-type">
                  What would you like to filter by?
                </InputLabel>
                <Select
                  fullWidth
                  value={selected.name || ''}
                  placeholder={'placeholder'}
                  onChange={this.onUpdate}
                  inputProps={{
                    name: 'search_filter',
                  }}
                >
                  {!!listItems.length &&
                    listItems
                      .filter(item => !!item)
                      .sort((a, b) => (a.label > b.label ? 1 : -1))
                      .map((listItem, idx) => {
                        const exists = !!selectedFilters.filter(
                          s =>
                            !!listItem.filter && s.name === listItem.filter.name
                        ).length
                        return (
                          <MenuItem
                            value={
                              (listItem.filter && listItem.filter.name) ||
                              (listItem.group && listItem.group.name)
                            }
                            key={idx}
                            disabled={exists}
                          >
                            {listItem.label}
                          </MenuItem>
                        )
                      })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              {groupSelected && (
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="contact-type">
                    Select a stat from the group
                  </InputLabel>
                  <Select
                    fullWidth
                    value={search_filter || ''}
                    placeholder={'placeholder'}
                    onChange={this.onUpdateGroupSelect}
                    inputProps={{
                      name: 'search_filter',
                    }}
                  >
                    {!!selected.items.length &&
                      selected.items
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map(listItem => {
                          return (
                            <MenuItem value={listItem.name} key={listItem.name}>
                              {listItem.label}
                            </MenuItem>
                          )
                        })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClickClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(search_filter)}
            color="primary"
            disabled={!valid}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

AddFilterForm.propTypes = {
  onClickClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  classes: PropTypes.object,
  filters: PropTypes.array,
  selectedFilters: PropTypes.array,
}

export default withStyles(styles)(AddFilterForm)
