const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  leftButton: {
    marginLeft: theme.spacing(1),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
  },
})

export default styles
