import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import Delayed from 'components/Delayed'
import styles from './styles'

class Loading extends Component {
  render() {
    const {
      visible,
      delayedMessage,
      classes,
      error,
      errorMsg,
      small,
    } = this.props
    if (!visible) return null
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.loading}>
          <Typography
            variant={small ? 'h6' : 'h4'}
            color="inherit"
            gutterBottom
          >
            {error ? errorMsg : 'Loading...'}
          </Typography>
          {!error && (
            <Delayed waitBeforeShow={4000}>
              <Typography
                variant={small ? 'h6' : 'h4'}
                color="inherit"
                gutterBottom
              >
                {delayedMessage || 'This might take a while...'}
              </Typography>
            </Delayed>
          )}
          <LinearProgress
            color={error ? 'secondary' : 'primary'}
            variant={error ? 'determinate' : 'indeterminate'}
            value={error ? 100 : 0}
          />
        </Grid>
      </Grid>
    )
  }
}

Loading.propTypes = {
  classes: PropTypes.object,
  visible: PropTypes.bool,
  delayedMessage: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  small: PropTypes.bool,
}

export default withStyles(styles)(Loading)
