import {
  STATUS_UPDATE,
  SETTINGS_UPDATE,
  SNACKBAR_OPEN,
  SNACKBAR_CLOSE,
} from '../actionTypes'

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const updateStatus = status => dispatch => {
  dispatch({
    type: STATUS_UPDATE,
    status,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const updateSettings = settings => dispatch => {
  dispatch({
    type: SETTINGS_UPDATE,
    settings,
  })
}

/**
 * REDUX ACTION
 *
 * @returns {Function}
 */
export const snackbarNotify = snackbarMsg => dispatch => {
  dispatch({
    type: SNACKBAR_OPEN,
    snackbarMsg,
  })
  setTimeout(() => dispatch({ type: SNACKBAR_CLOSE }), 3000)
}
