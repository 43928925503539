import http from 'helpers/http'

export const getContactTypes = async () => {
  return http.get('/contact_types')
}

export const getContactPurposeTypes = async () => {
  return http.get('/contact_purpose_types')
}

export const addNewContact = async contact => {
  let uri
  switch (contact.contactable_type) {
    case 'Person':
      uri = `/people/${contact.contactable_id}/contacts`
      break
    case 'Membership':
      uri = `/memberships/${contact.contactable_id}/contacts`
      break
    default:
      break
  }
  return http.post(uri, { contact })
}
